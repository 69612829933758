import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  static checkMax(limit: number){
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const val = control.value;
      if (val) {
        if ((val as string).length > limit) {
          return {limitExhausted: true};
        } else {
          return null;
        }
      }
      return null;
    };
  }

  static isWorkEmailValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const email = control.value;
    if (email === null || email === undefined) {
      return null;
    }
    const inValidEmailsDomains = [
      'gmail',
      'yahoo',
      'hotmail',
      'live',
      'outlook',
      'msn',
    ];
    const emailParts = email.toString().split('@');
    if (emailParts.length > 1) {
      const emailFormat = emailParts[1].split('.');
      if (emailFormat.length > 1) {
        if (inValidEmailsDomains.includes(emailFormat[0])) {
          return { workEmail: true };
        }
      }
    }
    return null;
  }

  static isNotEmpty(control: AbstractControl): { [key: string]: boolean } | null {
    const val = control.value;
    if (val === null || val === undefined) {
      return { required: true };
    } else {
      const sanitisedVal = (val as string).trim();
      if (sanitisedVal === '') {
        return { required: true };
      } else {
        return null;
      }
    }
  }

  static loggedUser(email: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const val = control.value;
      if (val) {
        if (val.toLowerCase() === email.toLowerCase()) {
          return { loggedUser: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static notZeroValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const val = control.value;
    if (val === null || val === undefined || val === 0) {
      return { required: true };
    } else {
      return null
    }
  }

}
