import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { HttpRequest } from '@angular/common/http';

import { miscellaneousConst } from '../consts/miscellaneous.const';
import { appsColorsConst } from '../consts/apps-color.const';
import { Renderer2 } from '@angular/core';

/**
 * To unsubscribe from all the subscriptions
 * Generally to call when components gets destroyed
 */
export function unsubscribeCollection(subscriptions: Subscription[]): void {
  if (subscriptions) {
    _.each(subscriptions, (sub) => {
      if (!_.isUndefined(sub)) {
        sub.unsubscribe();
      }
    });
  }
}

/**
 * @description To check if App flow is external flow
 */
export function isExternalFlowRegUniqueName(params: any): boolean {
  // eslint-disable-next-line no-prototype-builtins
  return params.hasOwnProperty('regUniqueName') && params.regUniqueName;
}

export function isExternalFlow(params: any): boolean {
  if (params !== undefined && params !== '') {
    localStorage.setItem('context', 'PracticeTest');
    return true;
  }
  return false;
}
/**
 *  @description To check if bearer token needs to skipped to attach for HTTP requests
 */
export function shouldBearerTokenBeSkipped(request: HttpRequest<any>): boolean {
  return request.url.endsWith('.md') || request.url.includes('blob.core');
}
export function formatter(seconds: any): any {
  const d = Number(seconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00';
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
  const sDisplay =
    s > 0 ? `${s.toString().length > 1 ? `${s}` : `${0}${s}`}` : '00';
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
}

export function getProgressStatus(questionList: any, question: any): any {
  const selectedIndex = questionList.findIndex(
    (i) => i.questionUniqueName === question.questionUniqueName
  );
  const singleProgressCount = 100 / questionList.length;
  return (selectedIndex + 1) * singleProgressCount;
}

/**
 * @description Get interval time for silent renew token
 */
export function getSilentRenewIntervalTime(token: string): number {
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  const currentTime = Math.floor(new Date().getTime() / 1000);

  return Math.floor((expiry - currentTime) / 60 - 3);
}

/**
 *
 * @description: Change Timer Minutes
 */
export function changeMinute(value: any): any {
  // 1- Convert to seconds:
  let seconds = value / 1000;
  // 2- Extract hours:
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0'); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0'); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  const saveTime = (
    hours +
    ':' +
    minutes +
    ':' +
    seconds.toFixed().toString().padStart(2, '0')
  ).toString();
  return saveTime;
}

/**
 * @description : Change Timer Seconds Display
 */
export function changeSecond(value: any): number {
  let totalMinutes = 0;
  if (value !== undefined) {
    const elapsedTime = value.split(':');
    const minutes = elapsedTime[0] * 60;
    const secondsSums = minutes + parseInt(elapsedTime[1], 10);
    totalMinutes = (secondsSums * 60 + parseInt(elapsedTime[2], 10)) / 60;
    return totalMinutes;
  } else {
    return 0;
  }
}

/**
 * @description check if regUniqueName available for outside flow
 */
export function apiUrl(regUniqueName: any, apiLink: any): any {
  if (regUniqueName !== undefined && regUniqueName !== '') {
    return `${apiLink}?regUniqueName=${regUniqueName}`;
  }
  return apiLink;
}

/**
 * @description Check if App is in iFrame or not
 */
export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * @description: Check Operating System
 */
export function checkOperatingSystem(): boolean {
  if (navigator.appVersion.indexOf('Win') !== -1) {
    return true;
  } else {
    return false;
  }
}
/**
 * @description: handle language, matching them with lang const
 */

export function handleLang(lang: string, langConst): string {
  const choosendLang = langConst.filter((element) => {
    const el = element.split('-');
    return el[1].toLowerCase() === lang.toLowerCase();
  });
  if (choosendLang.length > 0 && choosendLang[0] !== undefined) {
    if (choosendLang[0].includes('-')) {
      return choosendLang[0].split('-')[0];
    } else {
      return 'en';
    }
  } else {
    return 'en';
  }
}

/**
 * @description handle API langugae response "Spanish"
 */

export function apiLanguage(lang: string): string {
  if (lang === miscellaneousConst.language.Spanish) {
    lang = miscellaneousConst.language.Español;
  }
  return lang;
}

/**
 * @description: Copy Content Element Value
 */

export function copyContent(copyElement): any {
  copyElement.select();
  copyElement.setSelectionRange(0, 99999);
  document.execCommand('copy');
}

/**
 * @description To download PDF using HTML2Canvas and JSPDF
 * @param data
 * @param title
 * @param jspdf
 * @param html2canvas
 */
export function downloadPDF(data, title, jspdf, html2canvas) {
  html2canvas(data).then((canvas) => {
    // TODO - We need to move this code to common utility - Aakash
    // Few necessary setting options
    const imgWidth = 200;
    const pageHeight = 150;
    const imgHeight = (canvas.height * imgWidth) / canvas.width + 15;
    let heightLeft = imgHeight;

    const contentDataURL = canvas.toDataURL('image/png');
    const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    let position = 0;
    pdf.addImage(
      contentDataURL,
      'PNG',
      5,
      position,
      imgWidth,
      imgHeight,
      undefined,
      'FAST'
    );
    heightLeft -= pageHeight;
    if (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'JPEG', 5, -295, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    pdf.save(title + ' - Report'); // Generated PDF
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLinkTitle(url: any): string {
  if (url.label === null || url.label === undefined || url.label === '') {
    const urlArray = url.link.split('/');

    const title = urlArray[urlArray.length - 1]
      ? urlArray[urlArray.length - 1]
      : urlArray[urlArray.length - 2];
    return title.includes('.')
      ? decodeURI(title)
      : capitalize(decodeURI(title));
  } else {
    return url.label;
  }
}

export function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function checkNegativeValue(value: number): number {
  return value < 0 ? 0 : value;
}

export const stringToHtml = (value: string, tag: string = 'inject') => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  if (doc.body.getElementsByTagName(tag).length > 0) {
    return doc.body.getElementsByTagName(tag)[0];
  }
  return null;
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const emptySelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if ((document as any).selection) {
    // IE?
    (document as any).selection.empty();
  }
};

export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard != undefined) {
    //Chrome
    await navigator.clipboard.writeText(text);
  } else if ((window as any).clipboardData) {
    // Internet Explorer
    (window as any).clipboardData.setData('Text', text);
  }
};

export const manageServiceVariables = (key, labGuide) => {
  const serviceKeyMapping = {
    azureaduseremail: 'AADEmail',
    azureaduserpassword: 'TempPassword',
    applicationid: 'AADSPAppId',
    accesskey: 'AADSPAppId',
    displayname: 'AADSPDisplayName',
    secretkey: 'AADSPAppKey',
    subscriptionid: 'SubscriptionGuid',
    tenantid: 'TenantId',
    tenantdomainname: 'TenantDomainName',
  };
  if (Object.keys(serviceKeyMapping).includes(key)) {
    return labGuide[serviceKeyMapping[key]];
  } else {
    return '';
  }
};

export function setLockButton(val, accountEmailDomain) {
  if (val != null) {
    const domain = val.split(',');
    if (domain.includes(accountEmailDomain)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
export function getTimeZone() {
  const timezone = new Date()
    .toLocaleDateString(undefined, {
      day: '2-digit',
      timeZoneName: 'long',
    })
    .slice(4);
  const finaltimezone = timezone.match(/\b(\w)/g);
  return finaltimezone.join('');
}

export function getEntity() {
  const url = window.location.pathname;
  let entity;
  url.includes('my-learning') ? (entity = 'my-learning') : (entity = 'catalog');
  return entity;
}

export function getEntityData() {
  const url = window.location.pathname;
  let entity;
  url.includes('learning-paths')
    ? (entity = 'learning-paths')
    : url.includes('courses')
    ? (entity = 'courses')
    : (entity = 'labs'); // TO DO SHEIKH
  return entity;
}

/**
 * @description: To convert video url to embed urls
 * Logic for YT and vimeo links
 * YT link format 'https://www.youtube.com/watch?v=YLt73w6criQ' and 'https://youtu.be/IiuWlqabx9M'
 * Vimeo link format 'https://vimeo.com/799196187/3b767b8933' and 'https://vimeo.com/799196187'
 * This function can be improve TODO Sheikh
 */

export function videoUrlCheck(
  videoUrl: string,
  isAutoPlay: string,
  mute?: string
): string {
  
  //As per browser policy, video should be muted when auto play so added below code
  const muted = isAutoPlay === 'false' ? '0' : '1';
  const autoPlay = isAutoPlay === 'true' ? '1' : '0';
  if (
    videoUrl?.includes(miscellaneousConst.lectures.video.youtube) ||
    videoUrl?.includes(miscellaneousConst.lectures.video.youtu)
  ) {
    if (videoUrl?.includes(miscellaneousConst.lectures.video.embed)) {
      return videoUrl;
    } else {
      let videoId: string | string[];
      if (videoUrl.includes(miscellaneousConst.lectures.video.youtu)) {
        videoId = videoUrl?.split('/');
        videoId = videoId[3];
      } else {
        videoId = videoUrl?.split('watch?v=')[1];
        videoId = videoId?.split('/')[0];
      }
      return (
        miscellaneousConst.lectures.video.youtubeLink +
        videoId +
        '?' +
        miscellaneousConst.lectures.video.autoplay +
        autoPlay +
        '&mute=' +
        muted
      );
    }
  } else if (videoUrl?.includes(miscellaneousConst.lectures.video.vimeo)) {
    // Handling listed and unlisted video for vimeo | Sheikh
    const splitVideourl = videoUrl?.split('vimeo.com/');
    const urlIds = splitVideourl[1]?.split('/');
    let videoLink: string;
    if (urlIds.length === 2) {
      videoLink =
        miscellaneousConst.lectures.video.vimeoLink +
        urlIds[0] +
        '?h=' +
        urlIds[1] +
        miscellaneousConst.lectures.video.autoplay +
        autoPlay +
        '&muted=' +
        muted;
    } else {
      videoLink =
        miscellaneousConst.lectures.video.vimeoLink +
        urlIds[0] +
        '?' +
        miscellaneousConst.lectures.video.autoplay +
        autoPlay +
        '&muted=' +
        muted;
    }
    return videoLink;
  }
  return videoUrl;
}


/**
 * @description: to set variables according to the config passed
 */
export function setStyleColours(themeId) {
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-color',
    appsColorsConst[themeId].primaryColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-link-color',
    appsColorsConst[themeId].linkColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-hover-color',
    appsColorsConst[themeId].primaryHoverColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-light-color',
    appsColorsConst[themeId].primaryLightColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-focus-color',
    appsColorsConst[themeId].primaryFocusColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-light-hover-color',
    appsColorsConst[themeId].primaryLightHoverColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-primary-light-focus-color',
    appsColorsConst[themeId].primaryLightFocusColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-body-text-light-color',
    appsColorsConst[themeId].bodyTextLightColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-white-color',
    appsColorsConst[themeId].whiteColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-light-disable-color',
    appsColorsConst[themeId].primaryLightDisableColor
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-background-image',
    'url(' + appsColorsConst[themeId].backgroundImage
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-bottom-border-size',
    appsColorsConst[themeId].bottomBorder
  );
  document.documentElement.style.setProperty(
    '--cloudlabs-hsl',
    appsColorsConst[themeId].hslValue
  );
}

/**
 * @description: to handle errors which comes as object to as string
 */

export function handleErrLogs(details: any, code?: string): any {
  if (typeof details === 'object') {
    return handleObj(details, code);
  } else {
    const str = details;
    let strJson;
    try {
      strJson = JSON.parse(str);
    } catch (error) {
      strJson = str;
    }
    const result = strJson;
    if (typeof result === 'object') {
      return handleObj(result, code);
    }
    return code + ', ' + details;
  }
}

function handleObj(details: any, code?: string): any {
  let errorMessage = '';
  if (details?.message) {
    errorMessage = details.message;
  } else if (details?.ErrorMessage) {
    errorMessage = details.ErrorMessage;
  }
  const errorObj = {
    errorCode: code,
    correlationId: details?.correlationId ? details?.correlationId : '',
    message: errorMessage,
  };
  return errorObj;
}

export function checkUserRank(loggedUser: any, users: any): any {
  let userRankData = undefined;
  users.forEach((data) => {
    if (loggedUser.idTokenClaims.email === data.Email) {
      userRankData = data;
    }
  });
  return userRankData;
}

// TODO need to fix issue where it is creating circular dependency due to moment import
/**
//  * Takes input as UTC format
//  * Creates current date time in UTC format
//  * Calculates remaining time in Days, Hours, Minutes
//  * @param utcDate
//  * @returns
//  */
// export function getRemainingTimeInDaysMinutesHours(utcDate) {
//     const endDateTime = moment.utc(utcDate); // takes date in UTC format
//     const now = moment.utc(); // takes date in UTC format
//     const duration = moment.duration(endDateTime.diff(now));
//     const remainingTime = {
//       days: Math.floor(duration.asDays()),
//       hours: duration.hours(),
//       minutes: duration.minutes()
//     }
//     return remainingTime;
// }

/**
 * @description: to convert minutes Days, Hours and Minutes and only display non-zero eliments
 */

export function timesInDaysHoursMinutes(minutes: number) {
  const oneDay = 1440; // Number of minutes in a day
  const oneHour = 60; // Number of minutes in an hour

  const days = Math.floor(minutes / oneDay);
  const hours = Math.floor((minutes % oneDay) / oneHour);
  const remainingMinutes = minutes % oneHour;

  // let result = "";
  let day = '';
  let hour = '';
  let minute = '';

  if (days > 0) {
    day += `${days}`;
    // result += `${days} ${dayKey}`
  }

  if (hours > 0) {
    hour += ` ${hours}`;
    // result += ` ${hours} ${hourKey}`
  }

  if (remainingMinutes > 0) {
    minute += ` ${remainingMinutes}`;
    // result += ` ${remainingMinutes} ${minuteKey}`
  }

  // Remove trailing comma and space if they exist
  // result = result.replace(/,\s*$/, "");
  return { day, hour, minute };

  // return result;
}

export function processInnerHTMLForAnchorTags(
  container: HTMLElement,
  renderer: Renderer2
) {
  const anchorTags = container.querySelectorAll('a');
  anchorTags.forEach((anchor: HTMLAnchorElement) => {
    // Add appropriate accessibility attributes
    renderer.setAttribute(anchor, 'tabindex', '0');
  });
  return renderer;
}

export function getPopupWidthHeightBasedOnImage(imageElement: HTMLElement): {width: number, height: number} {
  const image = imageElement as HTMLImageElement;
  if (image?.naturalWidth && image?.naturalHeight) {
    // Calculate the aspect ratio of the image
    const aspectRatio = image.naturalWidth / image.naturalHeight;

    // Calculate the minimum width and height based on the aspect ratio
    const minWidth = 400; // Minimum width you want to set
    const minHeight = 400; // Minimum height you want to set

    let width = image.naturalWidth;
    let height = image.naturalHeight;

    // Enforce the minimum width and height constraints
    if (width < minWidth) {
      width = minWidth;
      height = width / aspectRatio;
    }

    if (height < minHeight) {
      height = minHeight;
      width = height * aspectRatio;
    }

    return { width: width, height: height }
  } else {
    return { width: 400, height: 400 };
  }
}
/**
 * @description: to add 's' if value is greater than 1
 */

export function checkSingularPlural(value: number): string {
  return value > 1 ? 's' : '';
}
