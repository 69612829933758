<p-dialog [maskStyleClass]="headerClass" header="{{ headerValue | translate }}" [draggable]="isDraggable"
    [(visible)]="openDialog" [baseZIndex]="baseZIndex" [modal]="true" (onHide)="closePopup()" [closable]="closable"
    appendTo="body" [style]="{width: popupWidth, height: popupHeight}">

    <ng-template pTemplate="header">
        <ng-content select="[header]"></ng-content>
    </ng-template>

    <ng-template pTemplate="content">
        <ng-content select="[content]">
        </ng-content>
    </ng-template>

    <ng-template pTemplate="footer">
        <ng-content select="[footer]"></ng-content>
    </ng-template>

</p-dialog>