import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LabCluster, LabClusterEntity } from '../../modals/lab-cluster.modal';
import { Country, CountryList } from '../../modals/common.model';
import { Lab } from '../../modals/lab.model';
import { setting } from '../../modals/setting.model';
import {
  AprovalField,
  FormField,
  FormFieldIds,
} from '../../modals/form-field.model';

import { AutoCompleteComponent } from '../shared/auto-complete/auto-complete.component';

import { EventEmitterService } from '@teams-auth';
import { LabClusterService } from '@libs/common-lab/src/services/lab-cluster.service';
import { NotificationService } from '@libs/common-lab/src/services/notification.service';
import { EventService } from '@libs/common-lab/src/services/event.services';
import { LabService } from '@libs/common-lab/src/services/lab.service';
import { RegistrationService } from '@libs/common-lab/src/services/registration.service';

import { unsubscribeCollection, CustomValidators } from '@utility';

@Component({
  selector: 'cloudlabs-registration',
  template: '',
})
export class BaseRegistrationComponent implements OnInit, OnDestroy {
  private static readonly ORG_CHAR_LIMIT = 60;
  private static readonly NAME_CHAR_LIMIT = 50;
  private static readonly STATE_CHAR_LIMIT = 50;
  private static readonly PHONE_PATTERN = '^(\\+\\d{1,3}( )?)?\\d{6,15}$';

  public disabled = false;
  public limitSelection = false;
  public cloudProvider = [];
  public dropdownSettings: any = {};
  public registerForm: FormGroup;
  public formFields: any;
  public isFirstname = false;
  public isLastname = false;
  public isEmail = false;
  public isOrganization = false;
  public isCountry = false;
  public isJobtitle = false;
  public isPhone = false;
  public isState = false;
  public ischeckPrivacy = false;
  public isInformationMessage = false;
  public isLabPromotion = false;
  public isActivation = false;
  public VoucherCode = false;
  public isCaptcha = false;
  public isInvite = false;
  public loading: boolean;
  public lab: Lab;
  public countries: Country[] = [];
  public onCompleteSignup = false;
  public btnLoading = false;
  public showDefaultPrivacyStatement = false;
  public informationMessage = '';
  public privacyStatement = '';
  public countryList: CountryList[];
  public organizationList = [];
  public appSetting: setting;
  @ViewChild('orgField') orgFieldSuggestion: AutoCompleteComponent;
  public isOrgFieldEmptyAfterTouched = false;
  public isOrgSuggestionsEnabled = false;
  public isOrgListLoading = false;
  public isOrgOther = false;
  public otherOrgValue = '';
  public production;
  public labClustersLabData: LabCluster;
  public isCluster = false;
  public hearAbout = [];
  public hearAboutType = '';

  labClusterEntity: LabClusterEntity;
  public subscriptions: Subscription[] = [];
  environment;

  constructor(
    public formBuilder: FormBuilder,
    public route: Router,
    public aroute: ActivatedRoute,
    public registrationSrv: RegistrationService,
    public translateSrv: TranslateService,
    public labSrv: LabService,
    public eventSrv: EventService,
    public notificationService: NotificationService,
    public labClusterService: LabClusterService,
    public eventEmitter: EventEmitterService,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
    this.production = environment.production;
  }

  async ngOnInit() {
    this.loading = true;
    setTimeout(() => {
      this.getSetting();
    }, 500);
    if (this.isLabClusterRegistration()) {
      this.lab = this.aroute.snapshot.data.lab;
      this.lab.Title = this.lab.DisplayName
        ? this.lab.DisplayName
        : this.lab.Title;
      this.getCountry('Course');
      this.labClustersLabData = this.aroute.snapshot.data.lab;
      this.labClustersLabData.Title = this.labClustersLabData.DisplayName
        ? this.labClustersLabData.DisplayName
        : this.labClustersLabData.Title;

      this.createFormForLabCluster(this.lab);
      this.makeFieldsVisibleForLabLusterForm();
      const res: FormField[] = await this.registrationSrv
        .getLabClusterSignUpField(this.lab.UniqueName)
        .toPromise();
      this.getSignupFields(res);

      // only for trial
      if (this.environment.appType === 'trial') {
        // TODO - Need to remove this hardcoded code later
        if (
          this.lab.PartnerName === this.environment.currentAppName &&
          this.lab.ExtendedUserAttribute.length > 0 &&
          this.lab.ExtendedUserAttribute[0].ShowInRegistrationPage &&
          this.lab.ExtendedUserAttribute[0].Name.includes('hear about us') // hard coded, need to remove this code later
        ) {
          this.addHearAboutUs();
        }

        this.registerForm
          .get('Email')
          .setValidators([
            Validators.required,
            Validators.pattern(this.environment.EMAIL_PATTERN),
            CustomValidators.isWorkEmailValidator,
          ]);
      }
      this.countries = await this.registrationSrv.getCountries().toPromise();
    } else {
      try {
        this.lab = this.aroute.snapshot.data.lab;
        this.getCountry('Lab');
        this.createForm(this.lab);
        const res: FormField[] = await this.registrationSrv
          .getSignUpField(this.lab.InternalId)
          .toPromise();
        this.getSignupFields(res);
        this.activationField(this.lab);
        this.countries = await this.registrationSrv.getCountries().toPromise();
      } catch (err) {
        this.loading = true;
        const code = 'Error - 70037';
        this.eventEmitter.debugAlert(code, err);
        return;
      }
    }
    if (this.lab.Country) {
      this.registerForm.get('Country')?.setValue(this.lab.Country);
      this.handlePrivacyStatements(this.lab.Country);
    } else {
      this.checkPrivacy();
    }
    if (this.lab.EnableAutoOrganization) {
      this.isOrgSuggestionsEnabled = this.lab.EnableAutoOrganization;
    }
    if (
      this.lab.CustomPrivacyStatement === null ||
      this.lab.CustomPrivacyStatement === ''
    ) {
      this.showDefaultPrivacyStatement = true;
    }
    this.subscriptions[this.subscriptions.length] =
      this.labSrv.labDetailsInES.subscribe((labDetails) => {
        if (labDetails) {
          this.lab = labDetails;
        }
      });
    if (this.lab.ThemeId) {
      this.eventSrv.setThemeId(this.lab.ThemeId);
    }
    this.labSrv.loading.next(false);
    this.loading = false;

    this.cloudProvider = this.labClustersLabData?.AssociatedEvents;
    this.cloudProvider?.map((data) => {
      if (data.IsMaxUserCountReached === true) {
        data.isDisabled = true;
      }
      return data;
    });

    this.cloudProvider?.map((data) => {
      data.item_text = data.CustomTitle ? data.CustomTitle : data.Title;
      return data;
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'EventUniqueName',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: this.labClustersLabData?.MaxLabsAllowed,
    };
    if (this.route.url.includes('baselineevent')) {
      this.isCluster = true;
    }
  }

  private addHearAboutUs() {

    this.registerForm.addControl(
      'hearAbout',
      new FormControl('', Validators.required)
    );

    this.hearAboutType = this.lab.ExtendedUserAttribute[0].DataTypeName;

    if (this.hearAboutType === 'Text Field') {
      this.hearAbout = [];
    } else {
      this.hearAbout = [
        {
          value: 'Online Search',
          label: 'Online Search',
        },
        {
          value: 'Advertising',
          label: 'Advertising',
        },
        {
          value: 'Social Media',
          label: 'Social Media',
        },
        {
          value: 'Analyst/Partner Referral',
          label: 'Analyst/Partner Referral',
        },
        {
          value: 'Existing Customer Referral',
          label: 'Existing Customer Referral',
        },
        {
          value: 'Trade Show / Event',
          label: 'Trade Show / Event',
        },
        {
          value: 'Other',
          label: 'Other',
        },
      ];
    }

    
  }

  handlePrivacyStatements(country: string): void {
    const data = this.countryList?.find(
      (e) =>
        e.PrivacyPolicy !== null &&
        e.PrivacyPolicy !== '' &&
        e.CountryName === country
    );
    if (data) {
      this.showDefaultPrivacyStatement = false;
      this.privacyStatement = data.PrivacyPolicy;
    } else {
      this.checkPrivacy();
    }
  }

  checkPrivacy() {
    if (
      this.lab.CustomPrivacyStatement &&
      this.lab.CustomPrivacyStatement != ''
    ) {
      this.showDefaultPrivacyStatement = false;
      this.privacyStatement = this.lab.CustomPrivacyStatement;
    } else {
      this.showDefaultPrivacyStatement = true;
    }

  if (this.lab.InformationMessage && this.lab.InformationMessage !== '') {
    this.informationMessage = this.lab.InformationMessage;
  }
  }

  // get form controls
  get f() {
    return this.registerForm.controls;
  }

  get policyLink(): any {
    if (this.appSetting) {
      return this.appSetting.Data.PrivacyPolicyLink;
    } else {
      return '';
    }
  }

  getSetting() {
    const haveSetting = localStorage.getItem('setting');
    if (haveSetting) {
      this.appSetting = JSON.parse(haveSetting);
    }
  }

  public showTAndCErrorNotification() {
    this.notificationService.error(
      this.translateSrv.instant('TERMS_AND_CONDITIONS_ERROR_MESSAGE'),
      null
    );
  }

  isLabClusterRegistration(): boolean {
    const urlParts = window.location.href.split('/');
    return urlParts.includes('baselineevent') || urlParts.includes('trials');
  }

  /**
   * BUild Signup form
   */
  createForm(labs): void {
    let group;
    if (labs.ApprovalTypeId === AprovalField.ActivationCode) {
      group = {
        ActivationCode: [{ value: '', disabled: true }],
        VoucherCode: [{ value: '', disabled: true }],
      };
    } else {
      if (this.lab.AllowPersonalEmailAddressesForRegistration) {
        group = {
          FirstName: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z]+(?: [a-zA-Z]+)?$'),
              CustomValidators.isNotEmpty,
              CustomValidators.checkMax(
                BaseRegistrationComponent.NAME_CHAR_LIMIT
              ),
            ],
          ],
          LastName: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.isNotEmpty,
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.checkMax(
                BaseRegistrationComponent.NAME_CHAR_LIMIT
              ),
            ],
          ],
          Email: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern(this.environment.EMAIL_PATTERN),
            ],
          ],
          Phone: [
            { value: '', disabled: true },
            [Validators.pattern(BaseRegistrationComponent.PHONE_PATTERN)],
          ],
          ActivationCode: [
            { value: '', disabled: true },
            [CustomValidators.isNotEmpty],
          ],
          VoucherCode: [
            { value: '', disabled: true },
            [CustomValidators.isNotEmpty],
          ],
          Organization: [
            { value: '', disabled: false },
            [
              Validators.required,
              CustomValidators.checkMax(
                BaseRegistrationComponent.ORG_CHAR_LIMIT
              ),
            ],
          ],
          State: [
            { value: '', disabled: true },
            [
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.checkMax(
                BaseRegistrationComponent.STATE_CHAR_LIMIT
              ),
            ],
          ],
          Country: [{ value: '', disabled: false }, Validators.required],
          Jobtitle: [{ value: '', disabled: true }],
          checkInformationMessage: [
            { value: '', disabled: true },
            Validators.required,
          ],
          checkPrivacyPolicy: [
            { value: '', disabled: false },
            Validators.required,
          ],
          EnableLabPromotion: [{ value: '', disabled: true }],
        };
      } else {
        group = {
          FirstName: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z]+(?: [a-zA-Z]+)?$'),
              CustomValidators.isNotEmpty,
              CustomValidators.checkMax(
                BaseRegistrationComponent.NAME_CHAR_LIMIT
              ),
            ],
          ],
          LastName: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.isNotEmpty,
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.checkMax(
                BaseRegistrationComponent.NAME_CHAR_LIMIT
              ),
            ],
          ],
          Email: [
            { value: '', disabled: false },
            [
              Validators.required,
              Validators.pattern(this.environment.EMAIL_PATTERN),
              CustomValidators.isWorkEmailValidator,
            ],
          ],
          Phone: [
            { value: '', disabled: true },
            [Validators.pattern(BaseRegistrationComponent.PHONE_PATTERN)],
          ],
          ActivationCode: [
            { value: '', disabled: true },
            [CustomValidators.isNotEmpty],
          ],
          VoucherCode: [
            { value: '', disabled: true },
            [CustomValidators.isNotEmpty],
          ],
          Organization: [
            { value: '', disabled: false },
            [
              Validators.required,
              CustomValidators.checkMax(
                BaseRegistrationComponent.ORG_CHAR_LIMIT
              ),
            ],
          ],
          State: [
            { value: '', disabled: true },
            [
              Validators.pattern('^[a-zA-Z]+$'),
              CustomValidators.checkMax(
                BaseRegistrationComponent.STATE_CHAR_LIMIT
              ),
            ],
          ],
          Country: [{ value: '', disabled: false }, Validators.required],
          Jobtitle: [{ value: '', disabled: true }],
          checkInformationMessage: [
            { value: '', disabled: true },
            Validators.required,
          ],
          checkPrivacyPolicy: [
            { value: '', disabled: false },
            Validators.required,
          ],
          EnableLabPromotion: [{ value: '', disabled: true }],
        };
      }
    }
    this.registerForm = this.formBuilder.group(group);
  }

  createFormForLabCluster(labCluster) {
    let group;
    if (labCluster.ApprovalTypeId === AprovalField.ActivationCode) {
      group = {
        ActivationCode: [{ value: '', disabled: true }],
        VoucherCode: [{ value: '', disabled: true }],
      };
      if (
        this.labClustersLabData?.AssociatedEvents?.length > 0 &&
        this.labClustersLabData?.MaxLabsAllowed === 1 &&
        this.environment.appType !== 'trial'
      ) {
        group = {
          ...group,
          SigleSelectEventsUniqueName: [
            { value: [], disabled: false },
            [Validators.required],
          ],
        };
      }
      if (
        this.labClustersLabData?.AssociatedEvents?.length > 0 &&
        this.labClustersLabData?.MaxLabsAllowed > 1 &&
        this.environment.appType !== 'trial'
      ) {
        group = {
          ...group,
          MultiSelectedEventsUniqueName: [
            { value: [], disabled: false },
            [Validators.required],
          ],
        };
      }
    } else {
      group = {
        FirstName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z]+(?: [a-zA-Z]+)?$'),
            CustomValidators.isNotEmpty,
            CustomValidators.checkMax(
              BaseRegistrationComponent.NAME_CHAR_LIMIT
            ),
          ],
        ],
        LastName: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.pattern('^[a-zA-Z]+$'),
            CustomValidators.isNotEmpty,
            CustomValidators.checkMax(
              BaseRegistrationComponent.NAME_CHAR_LIMIT
            ),
          ],
        ],
        Email: [
          { value: '', disabled: false },
          [
            Validators.required,
            Validators.pattern(this.environment.EMAIL_PATTERN),
          ],
        ],
        Phone: [
          { value: '', disabled: true },
          [Validators.pattern(BaseRegistrationComponent.PHONE_PATTERN)],
        ],
        ActivationCode: [
          { value: '', disabled: true },
          [CustomValidators.isNotEmpty],
        ],
        VoucherCode: [
          { value: '', disabled: true },
          [CustomValidators.isNotEmpty],
        ],
        Organization: [
          { value: '', disabled: false },
          [
            Validators.required,
            CustomValidators.checkMax(BaseRegistrationComponent.ORG_CHAR_LIMIT),
          ],
        ],
        State: [
          { value: '', disabled: true },
          [
            Validators.pattern('^[a-zA-Z]+$'),
            CustomValidators.checkMax(
              BaseRegistrationComponent.STATE_CHAR_LIMIT
            ),
          ],
        ],
        Country: [{ value: '', disabled: false }, Validators.required],
        Jobtitle: [{ value: '', disabled: true }],
        checkInformationMessage: [
          { value: '', disabled: true },
          Validators.required,
        ],
        checkPrivacyPolicy: [
          { value: '', disabled: false },
          Validators.required,
        ],
      };
      if (
        this.labClustersLabData?.AssociatedEvents?.length > 0 &&
        this.labClustersLabData?.MaxLabsAllowed === 1 &&
        this.environment.appType !== 'trial'
      ) {
        group = {
          ...group,
          SigleSelectEventsUniqueName: [
            { value: [], disabled: false },
            [Validators.required],
          ],
        };
      }
      if (
        this.labClustersLabData?.AssociatedEvents?.length > 0 &&
        this.labClustersLabData?.MaxLabsAllowed > 1 &&
        this.environment.appType !== 'trial'
      ) {
        group = {
          ...group,
          MultiSelectedEventsUniqueName: [
            { value: [], disabled: false },
            [Validators.required],
          ],
        };
      }
    }
    this.registerForm = this.formBuilder.group(group);
  }

  /**
   * Check signup
   * Fields
   */
  async getSignupFields(res: FormField[]) {
    if (res.length > 0 && this.lab.ApprovalTypeId !== 11) {
      this.setupField('checkPrivacyPolicy', true);
      this.ischeckPrivacy = true;
      if (this.lab.InformationMessage) {
        this.setupField('checkInformationMessage', true);
        this.isInformationMessage = true;
      }
      if (
        !this.isLabClusterRegistration() &&
        this.lab.LabPromotionMessage !== null
      ) {
        this.setupField('EnableLabPromotion', true);
        this.isLabPromotion = true;
      }
      res.forEach((field) => {
        switch (field.FieldId) {
          case FormFieldIds.FIRSTNAME: {
            this.setupField('FirstName', field.IsVisible);
            this.isFirstname = field.IsVisible;
            break;
          }
          case FormFieldIds.LASTNAME: {
            this.setupField('LastName', field.IsVisible);
            this.isLastname = field.IsVisible;
            break;
          }
          case FormFieldIds.EMAIL: {
            this.setupField('Email', field.IsVisible);
            this.isEmail = field.IsVisible;
            break;
          }
          case FormFieldIds.ORG: {
            this.setupField('Organization', field.IsVisible);
            this.isOrganization = field.IsVisible;
            break;
          }
          case FormFieldIds.COUNTRY: {
            this.setupField('Country', field.IsVisible);
            this.isCountry = field.IsVisible;
            break;
          }
          case FormFieldIds.JOBTITLE: {
            this.setupField('Jobtitle', field.IsVisible);
            this.isJobtitle = field.IsVisible;
            break;
          }
          case FormFieldIds.PHONE: {
            this.setupField('Phone', field.IsVisible);
            this.isPhone = field.IsVisible;
            break;
          }
          case FormFieldIds.STATE: {
            this.setupField('State', field.IsVisible);
            this.isState = field.IsVisible;
            break;
          }
          case FormFieldIds.CAPTCHA: {
            this.isCaptcha = field.IsVisible;
            break;
          }
        }
      });
      if (this.lab.EnableVoucher) {
        this.setupField('VoucherCode', true);
        this.isInvite = true;
      }
    } else if (res.length <= 0 && this.lab.ApprovalTypeId !== 11) {
      this.isFirstname = true;
      this.isLastname = true;
      this.isEmail = true;
      this.isOrganization = true;
      this.isCountry = true;
      this.ischeckPrivacy = true;
      if (
        this.lab.LabPromotionMessage !== null &&
        this.lab.LabPromotionMessage !== undefined
      ) {
        this.setupField('EnableLabPromotion', true);
        this.isLabPromotion = true;
      }
      if (this.lab.EnableVoucher) {
        this.setupField('VoucherCode', true);
        this.isInvite = true;
      }
    }
  }

  /**
   * Check activation code
   * Field enabled
   * @param lab
   */
  activationField(lab: Lab): void {
    if (
      lab.ApprovalTypeId === 10 ||
      lab.ApprovalTypeId === AprovalField.ActivationCode ||
      lab.ApprovalTypeId === AprovalField.Checkvalidation || 
      lab.ApprovalTypeId === AprovalField.ODLRegisterationActivationCodeRequiredAutoLaunch
    ) {
      this.setupField('ActivationCode', true);
      this.isActivation = true;
      if (this.lab.EnableVoucher) {
        this.setupField('VoucherCode', true);
        this.isInvite = true;
      }
    } else if (
      lab.ApprovalTypeId == AprovalField.AnonymousUsers &&
      lab.StatusId === 5
    ) {
      this.route.navigate(['odl', lab.UniqueName, 'anonymous'], {
        skipLocationChange: true,
      });
      this.labSrv.loading.next(true);
      throw new Error('redirection');
    } else if (
      lab.ApprovalTypeId == AprovalField.AutoLaunch &&
      lab.StatusId === 5
    ) {
      this.route.navigate([`/odl/${lab.UniqueName}/anonymous`], {
        skipLocationChange: true,
      });
      this.labSrv.loading.next(true);
      throw new Error('redirection');
    } else if (lab.ApprovalTypeId == AprovalField.QueueSupport) {
      // Some logic comes here later
    } else if (
      (lab.EnableVoucher == true ||
        lab.ApprovalTypeId === AprovalField.InviteCode) &&
      lab.ApprovalTypeId !== AprovalField.ActivationCode
    ) {
      this.setupField('VoucherCode', true);
      this.isInvite = true;
    }
  }

  /**
   * Disable unwanted field for the registration
   * @param field
   * @param enable
   */
  setupField(field: string, enable: boolean): void {
    if (field === 'EnableLabPromotion') {
      if (!enable) {
        this.registerForm.get(field).disable();
        this.registerForm.get(field).clearValidators();
      } else {
        this.registerForm.get(field).enable();
      }
      this.registerForm.get(field).updateValueAndValidity();
      return;
    }
    if (!enable) {
      this.registerForm.get(field).disable();
      this.registerForm.get(field).clearValidators();
    } else {
      this.registerForm.get(field).enable();
      if (field === 'Email') {
        if (this.lab.AllowPersonalEmailAddressesForRegistration) {
          this.registerForm
            .get(field)
            .setValidators([
              Validators.required,
              Validators.pattern(this.environment.EMAIL_PATTERN),
            ]);
        } else {
          this.registerForm
            .get(field)
            .setValidators([
              Validators.required,
              Validators.pattern(this.environment.EMAIL_PATTERN),
              CustomValidators.isWorkEmailValidator,
            ]);
        }
      } else if (field === 'FirstName' || field === 'LastName') {
        this.registerForm
          .get(field)
          .setValidators([
            Validators.required,
            Validators.pattern('^[a-zA-Z]+(?: [a-zA-Z]+)?$'),
            CustomValidators.isNotEmpty,
            CustomValidators.checkMax(
              BaseRegistrationComponent.NAME_CHAR_LIMIT
            ),
          ]);
      } else if (field === 'ActivationCode' || field === 'VoucherCode') {
        this.registerForm
          .get(field)
          .setValidators([Validators.required, CustomValidators.isNotEmpty]);
      } else if (field === 'Phone') {
        this.registerForm
          .get(field)
          .setValidators([
            Validators.required,
            CustomValidators.isNotEmpty,
            Validators.pattern(BaseRegistrationComponent.PHONE_PATTERN),
          ]);
      } else if (field === 'Organization') {
        this.registerForm
          .get(field)
          .setValidators([
            Validators.required,
            CustomValidators.checkMax(BaseRegistrationComponent.ORG_CHAR_LIMIT),
          ]);
      } else if (field === 'State') {
        this.registerForm
          .get(field)
          .setValidators([
            Validators.pattern('^[a-zA-Z ]+$'),
            CustomValidators.checkMax(
              BaseRegistrationComponent.STATE_CHAR_LIMIT
            ),
          ]);
      } else {
        this.registerForm.get(field).setValidators([Validators.required]);
      }
    }
    this.registerForm.get(field).updateValueAndValidity();
  }
  async makeFieldsVisibleForLabLusterForm(): Promise<any> {
    this.lab.AllowPersonalEmailAddressesForRegistration = true;
    if (this.lab.StatusId === 6) {
      this.loading = false;
      return;
    }
    if (this.lab.ApprovalTypeId === 1) {
      this.loading = true;
      const voutcherCode = await this.registerAnonymous();
      return this.route.navigate([
        `/baselineevent/${this.lab.UniqueName}/${voutcherCode}`,
      ]);
    }
    if ([2, 5, 10, 12, 14].includes(this.lab.ApprovalTypeId)) {
      this.setupField('checkPrivacyPolicy', true);
      this.ischeckPrivacy = true;
      if (this.lab.InformationMessage) {
        this.setupField('checkInformationMessage', true);
        this.isInformationMessage = true;
      }
      this.setupField('FirstName', true);
      this.isFirstname = true;
      this.setupField('LastName', true);
      this.isLastname = true;
      this.setupField('Email', true);
      this.isEmail = true;
      this.setupField('Country', true);
      this.isCountry = true;
    }
    if ([10, 11, 12, AprovalField.ODLRegisterationActivationCodeRequiredAutoLaunch].includes(this.lab.ApprovalTypeId)) {
      this.setupField('ActivationCode', true);
      this.isActivation = true;
    }
    this.loading = false;
  }

  async registerAnonymous() {
    const res = await this.registrationSrv
      .signUpForLabCluster(this.lab.UniqueName, {
        checkPrivacyPolicy: true,
      })
      .toPromise();
    return res.LabClusterUserVoucher;
  }

  checkForCustomPrivacyUrl(): string {
    if (
      this.lab.CustomPrivacyUrl !== null &&
      this.lab.CustomPrivacyUrl !== ''
    ) {
      if (this.lab.CustomPrivacyUrl.startsWith('http://')) {
        return this.lab.CustomPrivacyUrl;
      } else if (this.lab.CustomPrivacyUrl.startsWith('https://')) {
        return this.lab.CustomPrivacyUrl;
      }
      return 'http://' + this.lab.CustomPrivacyUrl;
    } else {
      return this.policyLink;
    }
  }

  changeSearchKey(event: string) {
    if (event.toLowerCase() === 'other') {
      if (!this.isOrgOther) {
        this.otherOrgValue = '';
      }
      this.orgFieldSuggestion.close();
      this.isOrgOther = true;
      return;
    }
    const org = this.registerForm.get('Organization').value;
    if (event !== org) {
      this.registerForm.get('Organization').setValue('');
    }
    this.otherOrgValue = event;
    this.isOrgOther = false;
    if (event && event.length > 0) {
      this.isOrgFieldEmptyAfterTouched = false;
    }
    // this.registerForm.get('Organization').setValue(event);
    if (this.registerForm.get('Organization').touched) {
      if (event === null || event === '') {
        this.isOrgFieldEmptyAfterTouched = true;
      }
    }
    if (event === null || event.length < 1) {
      // this.orgFieldSuggestion.close();
    } else {
      this.isOrgListLoading = true;
      const country = this.registerForm.get('Country').value
        ? this.registerForm.get('Country').value
        : 'United States';
      this.subscriptions[this.subscriptions.length] = this.registrationSrv
        .getOrgSuggestionList(country, event)
        .subscribe((data) => {
          if (!this.isOrgOther) {
            if (this.otherOrgValue === event) {
              this.organizationList = data;
              this.orgFieldSuggestion.open();
              this.orgFieldSuggestion.isOpen = true;
            }
          }
          this.isOrgListLoading = false;
        });
    }
  }

  focusedInOrgField(event: any) {
    this.orgFieldSuggestion.close();
  }

  focusOutFromOrg(event: any) {
    this.registerForm.get('Organization').markAsTouched();
    setTimeout(() => {
      if (
        this.registerForm.get('Organization').value === null ||
        this.registerForm.get('Organization').value === ''
      ) {
        if (!this.isOrgOther) {
          this.isOrgFieldEmptyAfterTouched = true;
        }
      } else {
        this.isOrgFieldEmptyAfterTouched = false;
      }
      this.orgFieldSuggestion.close();
    }, 200);
  }

  optionSelectedFromOrgSuggestion(event: string) {
    this.isOrgFieldEmptyAfterTouched = false;
    this.isOrgOther = event === 'Other' || event === 'other';
    if (!this.isOrgOther) {
      this.registerForm.get('Organization').setValue(event);
    } else {
      this.registerForm.get('Organization').setValue(this.otherOrgValue);
      this.isOrgFieldEmptyAfterTouched = false;
      this.registerForm.get('Organization').markAsTouched();
    }
  }

  inputIsClearedInOrgField() {
    this.registerForm.get('Organization').setValue('');
    this.orgFieldSuggestion.close();
    this.otherOrgValue = '';
    this.isOrgOther = false;
    this.registerForm.get('Organization').markAsTouched();
    this.isOrgFieldEmptyAfterTouched = true;
  }

  orgOtherIsChanged(event: Event) {
    this.registerForm.get('Organization').markAsTouched();
    const val = (event.target as HTMLInputElement).value.trim();
    this.otherOrgValue = val;
    if (!val) {
      // this.isOrgFieldEmptyAfterTouched = true;
      this.registerForm.get('Organization').setValue(null);
    } else {
      this.isOrgFieldEmptyAfterTouched = false;
      this.registerForm.get('Organization').setValue(val);
    }
  }

  orgOtherFocusedOut() {
    this.registerForm.get('Organization').markAsTouched();
  }

  countryChanged() {
    const country = this.registerForm.get('Country').value;
    const orgValue = this.registerForm.get('Organization').value;
    if (
      this.isOrgSuggestionsEnabled === true &&
      orgValue !== '' &&
      orgValue !== null
    ) {
      if (!this.isOrgOther) {
        const org = this.orgFieldSuggestion?.query;
        this.subscriptions[this.subscriptions.length] = this.registrationSrv
          .getOrgSuggestionList(country, org)
          .subscribe((data) => {
            if (!data.includes(org)) {
              this.orgFieldSuggestion.clear();
            }
          });
      } else {
        const org = this.otherOrgValue;
        this.subscriptions[this.subscriptions.length] = this.registrationSrv
          .getOrgSuggestionList(country, org)
          .subscribe((data) => {
            if (data.includes(org)) {
              this.orgFieldSuggestion.query = org;
              this.registerForm.get('Organization').setValue(org);
              this.otherOrgValue = '';
              this.isOrgOther = false;
            }
          });
      }
    }

    // handle country private policy
    this.handlePrivacyStatements(country);
  }

  getCountry(labType) {
    this.subscriptions[this.subscriptions.length] = this.labSrv
      .getCountry(this.lab.UniqueName, labType)
      .subscribe((res) => {
        this.countryList = res;
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.languageChanged.subscribe((res) =>
        this.labSrv
          .getCountry(this.lab.UniqueName, labType)
          .pipe(take(1))
          .subscribe((res) => {
            this.countryList = res;
            const country = this.registerForm.get('Country').value;
            this.handlePrivacyStatements(country);
          })
      );
  }

  ngOnDestroy() {
    unsubscribeCollection(this.subscriptions);
  }
}
