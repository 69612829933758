import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { setting } from '../shared/models/setting.model';

@Injectable()
export class AppConfigService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.APIURL
  }

  /**
   * Load app settings
   */
  load(): Promise<any> {
    const promise = this.http.get<setting[]>(`${this.apiUrl}ClientSettings`)
      .toPromise()
      .then(data => {
        Object.assign(this, data);
        return data;
      });

    return promise;
  }
}
