import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Language} from './modals/language.modal';
import {TranslateService} from '@ngx-translate/core';
// import {TranslateCacheService} from 'ngx-translate-cache';
import {EventService} from './event.services';
import {Lab} from '../shared/models/lab.model';
import {LabCluster} from './modals/lab-cluster.modal';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  apiUrl: any;
  headers;


  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    // public translateCacheService: TranslateCacheService,
    public eventServ: EventService,
  ) {
    this.apiUrl = environment.APIURL;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  }

  getLanguages() {
    return this.httpClient.get<Language[]>(`${this.apiUrl}Option/Languages`);
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    // this.translateCacheService.init();
    localStorage.setItem('language', language);
    this.eventServ.languageIsChanged(language);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize-tabs'));
    }, 1000);

  }

  switchLanguageToDefault(lab: Lab | LabCluster) {
    const key = `lab_language_${lab?.UniqueName}`;
    const lang = localStorage.getItem(key);
    if (!lang) {
      if (lab?.LanguageCode) {
        this.switchLanguage(lab.LanguageCode);
        localStorage.setItem(key, lab.LanguageCode);
      }
    }
  }
}
