<div role="dialog"  [attr.aria-label]="title | dialogRole">
    <div class="modal-content" appTrapFocus appRestartDialog appRefocusPreviousEl >
    <div class="modal-header" >
        <h4 class="modal-title" id="vm-dialog-head"> {{ title }} </h4>
  <!--          <button aria-label="Close" type="button" class="close modal-close-button" (click)="modulemodelShow = false">&times;</button>-->
      </div>
      <div class="modal-body">
          {{data.description}}
      </div>
      <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary modal-close-button" (click)="modalRef.hide(); onCancel()">{{data.failureButtonText}}</button>
        <button appRemoveFocusOkButton type="button" aria-hidden="true" class="btn ts-btn-fluent-primary modal-ok-button" (click)="onConfirm()">{{data.successButtonText}}</button>
      </div>
    </div>
  </div>
