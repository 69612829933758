import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { WebSocketTunnel } from '@illgrenoble/guacamole-common-js';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import {Subject, Subscription } from 'rxjs';

import { UserSessionService, EventEmitterService } from '@teams-auth';
import { RemoteDesktopManager } from '../remote';
import { copyToClipboard, emptySelection, unsubscribeCollection } from '@utility';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification.service';
import { LabService } from '../../services/lab.service';
import { RdpDialogComponent } from '../labenvironment/components/environment/rdp-dialog/rdp-dialog.component';
import { Lab } from '../../modals/lab.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventService, RDPDialogStatus } from '../../services/event.services';
import { Router } from '@angular/router';
import { detect } from 'detect-browser';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-vm',
  templateUrl: './vm.component.html',
  styleUrls: ['./vm.component.scss'],
})
export class VmComponent implements OnDestroy{
  @Input() lab: Lab;
  @Input() isInsideEnv = false;
  @Input() isMylearning = true;
  private notifier = new Subject();
  resizeSubsciption: Subscription;
  private managerSubscriptions: Subscription[] = [];
  reloadVMs = new Subject();
  vmFocus: boolean;
  @Input() loader: boolean;
  public manager: RemoteDesktopManager;
  webSocketTunnels;
  // static values need to change accordingly
  guacamoleServerUrl = '';
  rdpUri = '';
  token;
  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  modelShow = false;
  public hidingRdpAriaLabel =
    'Press enter to focus in to Remote Desktop. You can focus out of VM by pressing Escape key.';
  public narratorAlertMessage = false;
  environment: any;
  public modalRef: BsModalRef;
  public remoteConnectProcessStatus = '';
  showRefreshButton: boolean;
  vmServiceSubscription: Subscription;
  tokenSubscription: Subscription;
  vmConnectionService: Subscription;
  public remoteDesktopStates = RemoteDesktopManager.STATE;
  @Input() credGenerated: boolean;
  private browser = detect();
  @Output() private checkVmStatus = new EventEmitter();
    /**
   * Copy element on document
   * @param $event
   */
  @HostListener('window:click', ['$event'])
  async handleClick($event) {
    if($event.target.type === 'text' && this.vmFocus && this.manager) {
      this.manager.setFocused(false);
      return;
    }
    if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      emptySelection();
      const brRegex = /<br\s*[\/]?>/gi;
      let copyData = $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n').trim();
      if (this.manager) {
        this.manager.sendRemoteClipboardData(txtdata);
      }
      await copyToClipboard(txtdata);
      this.notificationService.success(
        this.translateSrv.instant('MESSAGE_COPIED'),
        null
      );
      (document.activeElement as any).blur();
    } else {
      await this.sendClipboardToClient();
    }
    if(this.vmFocus && document.getElementById('vm-display')) {
      document.getElementById('vm-display').focus();
    }
  }

  /**
   * Hotlistner on the copy command
   * @param e
   */
  @HostListener('copy', ['$event'])
  async onCopy(e: ClipboardEvent) {
    const successful = document.execCommand('copy');
    if (successful) {
      const texts: any = await navigator.clipboard.readText();
    }
  }

  /**
   * Hotlistner no keydown
   * @param $event
   */
  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event: any) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      const successful = document.execCommand('copy');
      if (successful && navigator.clipboard !== undefined) {
        const text = await navigator.clipboard.readText();
      }
    }
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target?.id === 'copyelement' ||
        ($event.target as HTMLElement)?.classList.contains('copyelement')
      ) {
        emptySelection();
        const brRegex = /<br\s*[\/]?>/gi;
        let copyData = $event.target.innerText || $event.target.firstChild.innerText
        const data = copyData.replace(brRegex, '\r\n').trim();
        await copyToClipboard(data);
      }
      if ($event.target?.classList[0] === 'copyelmnt') {
        const text = await navigator.clipboard.readText();
      }
    }
  }

  /**
   * On resize window
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.resizeVM();
  }
  /**
   * On press keyboard events
   * @param $event
   */
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  async onkeyDown($event: KeyboardEvent) {
    // This solution is for, If focus on vm and when we change the focus using tab key then it affects inside the vm and outside the vm 
    if (($event as KeyboardEvent).key === 'Tab' && this.vmFocus && this.manager) {
      $event.preventDefault();
      const skipToMainContent = document.querySelector('.display-div');
      if(skipToMainContent) {
        skipToMainContent.classList.remove('display-div');
        skipToMainContent.classList.add('hide-div');
      }
    }

    // this.idleCounter = 0;
    if (($event as KeyboardEvent).key === 'Escape') {
      this.manager.setFocused(false);
      this.narratorAlertMessage = false;
      setTimeout(() => {
        this.hidingRdpAriaLabel =
          'Press enter to focus in to Remote Desktop. You can focus out of VM by pressing Escape key.';
      });
    }

    const key = $event.which || $event.keyCode; // keyCode detection
    let ctrl = $event.ctrlKey ? $event.ctrlKey : key === 17 ? true : false; // ctrl detection
    if (!ctrl) ctrl = $event.metaKey; // command key for mac

    if (key === 86 && ctrl) {
      // Ctrl + V Pressed
    } else if (key === 67 && ctrl) {
      // Ctrl + C Pressed
      document ? document.execCommand('copy') : false;
    }
  }
  constructor(
    private httpClient: HttpClient,
    private readonly userSessionService: UserSessionService,
    private eventEmitter: EventEmitterService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private labService: LabService,
    private modalService: BsModalService,
    private eventSrv: EventService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    @Inject('environment')
    environment
  ) {
    if(router.url.includes('my-learning')){
      this.isMylearning = true
    }
    this.environment = environment;
    this.subscribeVmDetailsChange();
    this.eventSrv.rdpDialogStatus.pipe(takeUntil(this.notifier)).subscribe((status) => {
    if (status === RDPDialogStatus.CONFIRM_CLOSED) {
      this.narratorAlertMessage = true;
      this.openNarratorAndFocusVM();
    }
    if (status === RDPDialogStatus.CLOSED) {
      this.handleFocusNoButton();
    }
  });
  this.onscroll();
  }

  onscroll() {
    document.addEventListener('scroll', (e) => {
      const vmConnected = this.manager ? this.manager.isConnected() : '';
      if (this.vmFocus && vmConnected && document.getElementById('vm-display')) {
        document.getElementById('vm-display').focus();
      }
    },true);
  }
  public subscribeVmDetailsChange(): void {
    this.vmServiceSubscription && this.vmServiceSubscription.unsubscribe();
    if((window.location.href).includes('practice-tests')){
      // For PT changes
      this.vmServiceSubscription = this.userSessionService.vmDetails$
        // .pipe(takeUntil(this.notifier), throttleTime(2000))
        .pipe(takeUntil(this.notifier))
        .subscribe(
          (data) => {
            if (data !== undefined && data !== null) {
              this.guacamoleServerUrl = data.guacamoleServerUrl;
              this.rdpUri = data.rdpUri;
              this.getAuthToken();
            }
          },
          (error) => {
            const code = 'Error - 10012';
            this.eventEmitter.debugAlert(code, error);
          }
        );
    } else {
      // for labs
      this.vmServiceSubscription = this.userSessionService.vmDetails$
        .pipe(takeUntil(this.notifier), throttleTime(2000))
        // .pipe(takeUntil(this.notifier))
        .subscribe(
          (data) => {
            if (data !== undefined && data !== null) {
              this.guacamoleServerUrl = data.guacamoleServerUrl;
              this.rdpUri = data.rdpUri;
              console.log('vm connect tokennn');

              this.getAuthToken();
            }
          },
          (error) => {
            const code = 'Error - 10012';
            this.eventEmitter.debugAlert(code, error);
          }
        );
    }
  }

  async resizeVM() {
    const state = await this.manager?.isConnected();
    if (state === true) {
      await this.manager.getClient().disconnect();
      const state2 = await this.manager?.isConnected();
      if (state2 === false) {
        await this.handleConnect();
      }
    }
  }

  /**
   * creating guacaomole remote remote desktop connection
   */
  initializeGuacamoleSession() {
    this.resizeSubsciption && this.resizeSubsciption.unsubscribe();
    unsubscribeCollection(this.managerSubscriptions);
    this.managerSubscriptions = [];
    const tunnelUrl = this.guacamoleServerUrl.replace('https://', '');
    const webSocketTunnel = new WebSocketTunnel(
      'wss://' + tunnelUrl + '/websocket-tunnel'
    );
    webSocketTunnel.receiveTimeout = 300000; // set limit to 5 min
    this.webSocketTunnels = webSocketTunnel;
    this.manager = new RemoteDesktopManager(webSocketTunnel);
    this.handleConnect();
    this.managerSubscriptions[this.managerSubscriptions.length] =
    this.manager.onReconnect
      // .pipe(takeUntil(this.notifier))
      .subscribe((reconnect) => this.handleConnect());
    this.managerSubscriptions[this.managerSubscriptions.length] =
    this.manager.onStateChange
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        takeUntil(this.notifier)
      )
      .subscribe(
        (state) => {
          this.labService.reloadVMs.next();
          this.reloadVMs.next();
        },
        (error) => {
          const code = 'Error - 10013';
          this.eventEmitter.debugAlert(code, error.error);
        },
      );
    // manage clipboard of VM
    this.managerSubscriptions[this.managerSubscriptions.length] =
    this.manager.onRemoteClipboardData.pipe(takeUntil(this.notifier)).subscribe(
      async (res: any) => {
        if (res) {
          try {
            await copyToClipboard(res);
          } catch (err) {
            const code = 'Error - 10014';
            this.eventEmitter.debugAlert(code, err);
          }
        }
      },
      (error) => {
        const code = 'Error - 10015';
        this.eventEmitter.debugAlert(code, error);
      }
    );

    this.managerSubscriptions[this.managerSubscriptions.length] =
    this.manager.onFocused.pipe(takeUntil(this.notifier)).subscribe(
      async (res: any) => {
        this.vmFocus = res;
        if (this.vmFocus) {
          emptySelection();
          // document ? document.execCommand('copy') : false;
        }
      },
      (error) => {
        const code = 'Error - 10016';
        this.eventEmitter.debugAlert(code, error);
      }
    );
    // });
    this.resizeSubsciption = this.labService.resizeEvent
      .pipe(takeUntil(this.notifier))
      .subscribe((res) => {
        if(res){
          this.resizeVM();
        }
      });
  }

  /**
   * Get authtoken
   */

   async getAuthToken() {
    this.tokenSubscription && this.tokenSubscription.unsubscribe();
    const url = this.guacamoleServerUrl + '/api/tokens';
    const body = new URLSearchParams();
    body.set('username', 'USERNAME');
    body.set('password', 'PASSWORD');
    if (!this.manager || (this.manager && !this.manager.isConnected())) {
      /* API changes here */
      this.tokenSubscription = this.httpClient
        .post<any>(`${url}`, body.toString(), { headers: this.headers })
        // .pipe(timeout(60000), retry(0))
        .pipe(takeUntil(this.notifier), debounceTime(500))
        .subscribe(
          (data) => {
            if (data && data !== undefined) {
              this.createQuickConnectSession(data.authToken);
              this.token = data.authToken;
            }
          },
          async (error) => {
            this.checkVmStatus.emit();
            const code = 'Error - 10017';
            this.eventEmitter.debugAlert(code, error);
            this.labService.vmStateUpdate.next(false);
            this.labService.reloadVMs.next();
          }
        );
    }
  }

  /**
   *
   * @param authToken Create connection
   */
  createQuickConnectSession(authToken) {
    const url =
      this.guacamoleServerUrl +
      '/api/session/ext/quickconnect/create?token=' +
      authToken;
    const body = new URLSearchParams();
    body.set('uri', this.rdpUri);
    // this.labService
    /* API changes here */
    this.vmConnectionService && this.vmConnectionService.unsubscribe()
    this.vmConnectionService = this.httpClient
      .post<any>(`${url}`, body.toString(), { headers: this.headers })
      .subscribe(
        (res) => {
          if (res.identifier == '0') {
            this.manager = undefined;
            this.initializeGuacamoleSession();
          }
        },
        (error) => {
          const code = 'Error - 10018';
          this.eventEmitter.debugAlert(code, error);
        }
      );
  }

  /**
   * Connection remotly
   */
  handleConnect() {
    this.loader = true;
    setTimeout(() => {
      const state = this.manager.isConnected();
      if (state === false) {
        const elmnt = document.getElementById('iframeBlock');
        if(elmnt){
          this.manager.connect({
            hostname: 'labvmqizpwuo2epd22.eastus.cloudapp.azure.com',
            port: 3389,
            GUAC_IMAGE: ['image/png', 'image/jpeg', 'image/webp'],
            GUAC_AUDIO: ['audio/L16'],
            GUAC_DPI: 96,
            GUAC_WIDTH: elmnt.offsetWidth,
            GUAC_HEIGHT: elmnt.offsetHeight,
          token: this.token,
          GUAC_DATA_SOURCE: 'quickconnect',
          GUAC_ID: 0,
          GUAC_TYPE: 'c',
        });
      }
    }
      this.manager.setFocused(false);
      this.loader = false;
      this.cdr.detectChanges();
    }, 1500);
    this.cdr.detectChanges();
  }

  copyToClient() {
    const selectedText = window.getSelection().toString();
    if (
      selectedText !== '' &&
      this.manager !== null &&
      this.manager !== undefined
    ) {
      this.manager.sendRemoteClipboardData(selectedText);

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(selectedText);
      }
    }
  }

  async sendClipboardToClient() {
    let text = '';
    try {
      text = await navigator.clipboard.readText();
    } catch (err) {
      console.info('not allowed');
      if (!this.vmFocus) {
        this.copyToClient();
      }
    }
    if (this.manager && text != '') {
      this.manager.sendRemoteClipboardData(text);
    }
  }

  openNarratorAndFocusVM() {
    // (this.el.nativeElement as HTMLElement).setAttribute('aria-live','assertive');
    this.manager.setFocused(true);
    this.modelShow = false;
    this.hidingRdpAriaLabel = '';
    sessionStorage.setItem('isNarratorRunning', 'true');
    setTimeout(() => {
      this.openNarratorInVM();
    });
  }

  handleFocusNoButton() {
    sessionStorage.setItem('isNarratorRunning', 'false');
    this.focusInVM();
  }

  focusInVM() {
    this.manager.setFocused(true);
    this.modelShow = false;
  }

  openNarratorInVM() {
    // opens start menu in Remote Desktop
    this.manager.getClient().sendKeyEvent(1, 65507);
    this.manager.getClient().sendKeyEvent(1, 65307);
    this.manager.getClient().sendKeyEvent(0, 65507);
    this.manager.getClient().sendKeyEvent(0, 65307);
    setTimeout(() => {
      if (this.browser.name === "edge" || this.browser.name==="edge-chromium"){
        this.manager.getClient().sendKeyEvent(1, 110);
        this.manager.getClient().sendKeyEvent(0, 110);
        this.manager.getClient().sendKeyEvent(1, 118);
        this.manager.getClient().sendKeyEvent(0, 118);
        this.manager.getClient().sendKeyEvent(1, 100);
        this.manager.getClient().sendKeyEvent(0, 100);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
      } else {
        // types 'narrator' in search box in Remote Desktop
        this.manager.getClient().sendKeyEvent(1, 110);
        this.manager.getClient().sendKeyEvent(0, 110);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
        this.manager.getClient().sendKeyEvent(1, 116);
        this.manager.getClient().sendKeyEvent(0, 116);
        this.manager.getClient().sendKeyEvent(1, 111);
        this.manager.getClient().sendKeyEvent(0, 111);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
      }
      setTimeout(() => {
        // imitates 'enter' in Remote Desktop
        this.manager.getClient().sendKeyEvent(1, 65293);
        this.manager.getClient().sendKeyEvent(0, 65293);
      }, 4000);
    }, 4000);
  }

  changeFocusToVM() {
    // this.focusInVM();
    const isFocused = this.manager.getIsFocused();
    if (!isFocused) {
      const isNarratorRunning = sessionStorage.getItem('isNarratorRunning');
      if (isNarratorRunning === 'true' || isNarratorRunning === 'false') {
        this.focusInVM();
      } else {
        //this.modelShow = true;
        if (
          this.environment.partnerNameNarratorDialog === this.lab?.PartnerName && this.environment.appType !== 'exam'
        ) {
          this.modalRef = this.modalService.show(RdpDialogComponent, {
            initialState: {
              title: this.translateSrv.instant('OPEN_NARRATOR'),
              data: {
                description: this.translateSrv.instant('NARRATOR_DIALOG'),
                failureButtonText: this.translateSrv.instant('No'),
                successButtonText: this.translateSrv.instant('Yes'),
              },
            },
          });
        } else {
          this.focusInVM();
        }
      }
    }
  }
  ngOnDestroy(): void {
      this.notifier.next();
      this.notifier.complete();
      this.resizeSubsciption && this.resizeSubsciption.unsubscribe();
      this.vmServiceSubscription && this.vmServiceSubscription.unsubscribe();
      this.vmConnectionService && this.vmConnectionService.unsubscribe()
      unsubscribeCollection(this.managerSubscriptions);
  }
}
