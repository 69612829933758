import { Inject, Injectable, Injector } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private static instance: AppInsightsService;
  appInsights: ApplicationInsights;
  gtmService: GoogleTagManagerService;
  gtmEnabled = false;
  insightEnabled = false;
  constructor(private injector: Injector, 
    @Inject('environment')
    environment) {
    // Singleton class logic
    if (AppInsightsService.instance) {
      return AppInsightsService.instance;
    }
    AppInsightsService.instance = this;
    this.insightEnabled = Boolean(environment.appInsightConfig && environment.appInsightConfig !== '');
    if (this.insightEnabled) {
      this.appInsights = new ApplicationInsights({
        config: environment.appInsightConfig,
      });
      this.appInsights.loadAppInsights();
    }
    
    this.gtmEnabled = Boolean(environment.GTMID && environment.GTMID !== '');
    if (this.gtmEnabled) {
      this.gtmService = injector.get<GoogleTagManagerService>(
        GoogleTagManagerService
      );
    }
  }

  logPageView(name?: string, url?: string): any {
    // option to call manually
    if(this.appInsights && this.insightEnabled){
      this.appInsights.trackPageView({
        name,
        uri: url,
      });
    }
    if(this.gtmEnabled){
      this.gtmService.pushTag({
        event: 'page',
        pageName: url,
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }): any {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): any {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number): any {
    if(this.appInsights && this.insightEnabled){
    this.appInsights.trackException({ exception, severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }): any {
    this.appInsights.trackTrace({ message }, properties);
  }
}
