import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppInsightsService } from '../_services/app-insights.service';

import { miscellaneousConst, handleErrLogs } from '@utility';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  errMsg = '';
  errMsgMobile = '';
  successMsg = '';
  timerConfig = 10000;
  environment: any;

  constructor(
    private appInsightsService: AppInsightsService,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
  }

  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();
  invokeErrorDummy = new EventEmitter();
  errorVar: Subscription;
  successVar: Subscription;
  public miscellaneousConst = miscellaneousConst;

  errorHandler(
    error: any,
    timer?: number,
    errorDetails?: any,
    hideError?: boolean
  ): void {
    this.errMsg = error;
    this.timerConfig = timer ? timer : 10000;
    if (!hideError) {
      this.invokeError.emit(error);
    }
    if (errorDetails) {
      this.appInsightsService.logException(errorDetails); // Manually log exception
    } else {
      this.appInsightsService.logException(error); // Manually log exception
    }
  }

  debugAlert(
    code: string,
    details: any,
    timer?: number,
    hideError?: boolean
  ): void {
    let errorMsg = '';
    let errorDetails = '';
    if (details && details.message) {
      errorMsg = details.message + this.getErrorMsg(details.correlationId);
    } else {
      errorMsg = details + this.getErrorMsg(code);
    }
    const handleLogs = handleErrLogs(details, code)
    errorDetails = JSON.stringify(handleLogs).replace(/[{}]/g, "");
    if (this.environment.production) {
      this.errorHandler(errorMsg, timer, errorDetails, hideError);
    } else {
      this.errorHandler(errorMsg, timer, errorDetails, hideError);
      this.debugPrintHighlight(errorDetails);
    }
  }

  debugPrintHighlight(message: string): void {
    // This will be disable the logger in PROD release
    if (!this.environment.production) {
      console.log('-');
      console.log('-');
      console.log(
        `%c --  ${message}  -- `,
        'color: Tomato;font-weight:bold; background-color: Black;'
      );
      console.log('-');
      console.log('-');
    }
  }

  errorHandlerForMobile(dummy: any): void {
    this.errMsgMobile = dummy;
    this.invokeErrorDummy.emit(dummy);
  }

  successHandler(success: any, timer?: number): void {
    this.successMsg = success;
    this.timerConfig = timer ? timer : 10000;
    this.invokeSuccess.emit(success);
  }

  getErrorMsg(code: string): any {
    return ` Please provide this correlation id: ${code} to the support team. `;
  }
}
