import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from '../../../services/event.services';
import { Router } from '@angular/router';
import { LabService } from '../../../services/lab.service';

@Component({
  selector: 'cloudlabs-activity-tracking',
  templateUrl: './activity-tracking.component.html',
  styleUrls: ['./activity-tracking.component.scss']
})
export class ActivityTrackingComponent implements OnInit {

  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router,
    public labSrv: LabService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalRef.hide();
    }, 115000);
  }

  async onConfirm(){
    this.eventSrv.onActivityDialog('Yes');
    this.modalRef.hide();
  }
  onClose(){
    this.eventSrv.onActivityDialog('No');
    this.modalRef.hide();
  }


}
