import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  OnDestroy,
  Inject,
} from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { VmDialogComponent } from '../vm-dialog/vm-dialog.component';
import { EventService } from '../../../services/event.services';
import {
  DatabricksResource,
  Deployment,
  Lab,
  CustomerType,
} from '../../../modals/lab.model';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import * as moment from 'moment';
import 'moment-duration-format';
import {
  getEntity,
  getEntityData,
  miscellaneousConst,
  unsubscribeCollection,
} from '@utility';
import { EventEmitterService } from '@teams-auth';
import { VMStatus } from '../../../modals/common.model';
@Component({
  selector: 'cloudlabs-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
})
export class ResourceComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() splitKey: string;
  @Input() attendeLabDetails: Deployment;
  @Input() mode;
  @Output() actionPerformedOnVM = new EventEmitter();
  @Input() manager: any;
  public docActions = false;
  public v_machine: any;
  public vmUsage: any;
  public dbUsage: any;
  public resourseList: any;
  public modalRef: BsModalRef;
  public vmType: number;
  public isVMLoading = false;
  public showVMLaunchButton = true;
  public upTimeLimit = '';
  remaining_UpTime: string;
  public showUpTimeLimit = false;
  public isVmUpTimeLimitReached = false;
  public showLaunchButton = false;
  public internalId = '';
  public dataRow;
  public modelShow = false;
  public modalData: any;
  showalert = false;
  open = true;
  disabled = true;
  private subscriptions: Subscription[] = [];
  private timeout;
  public tootltiptext1: string;
  public tootltiptext2: string;
  public VMtooltipText: string;
  public SQLtooltipText: string;
  sqlPool_remainingTime: any;
  public sqlPool_uptimeLimit = '';
  sql_Pool: any[] = [];
  show_sqlPooltime = false;
  public refreshInterval = interval(60000 * 15);
  showSqlPool = false;
  sqlResourceList: any[];
  showVirtualMachine = false;
  private dialogConfirmation = false;
  public databriksCluserList: Array<DatabricksResource> = [];
  public databriksSQLEPList: Array<DatabricksResource> = [];
  public dbClusterStatus: string[] = [
    'PENDING',
    'RUNNING',
    'TERMINATING',
    'TERMINATED',
    'FAILED',
  ];
  public customerType = CustomerType;

  vmStatus = VMStatus;
  constructor(
    private modalService: BsModalService,
    private LabSrv: LabService,
    private translateSrv: TranslateService,
    private eventSrv: EventService,
    private notificationService: NotificationService,
    private route: Router,
    private aroute: ActivatedRoute,
    private ngZone: NgZone,
    private eventEmitter: EventEmitterService,
    private notificationSrv: NotificationService,
    @Inject('environment') public environment
  ) {
    if (this.environment.customer !== CustomerType.DATABRICKS) {
      this.LabSrv.reloadVMs.pipe(takeUntil(this.notifier)).subscribe(() => {
        this.loadResources(false);
      });
      this.subscriptions[this.subscriptions.length] = this.refreshInterval
        .pipe(takeUntil(this.notifier))
        .subscribe(() => this.loadResources(false));
    } else if (this.environment.customer === CustomerType.DATABRICKS) {
      this.subscriptions[this.subscriptions.length] = this.refreshInterval
        .pipe(takeUntil(this.notifier))
        .subscribe(() => this.getDBWorkspaceResouces());
    }
  }
  ngOnInit(): void {
    //Check if Resouce Tab is loaded for Databricks workspace
    if (this.environment.customer === CustomerType.DATABRICKS) {
      this.getDBWorkspaceResouces();
    } else {
      this.timeout = setTimeout(() => {
        if (this.manager?.getState() != 'CONNECTED') {
          this.loadResources();
        }
      }, 60000);
      this.internalId = this.aroute.snapshot.params.vmurl || '';
      // get internal id by query param
      const { intId } = this.aroute.snapshot.queryParams;
      if (intId && intId !== '') {
        this.internalId = intId;
      }
      const url = this.route.url;

      if (
        url.startsWith('/odl/environment') ||
        url.startsWith('/odl/labguide') ||
        url.includes('labenvironment')
      ) {
        this.showVMLaunchButton = false;
      }

      this.VMtooltipText =
        this.SQLtooltipText =
        this.tootltiptext1 =
          'RESOURCES_TOOLTIP_1';
      this.tootltiptext2 = 'RESOURCES_TOOLTIP_2';
      if (this.mode && this.mode != 'AzurePassUserDetails') {
        this.loadResources(false);
      }
    }
    this.eventSrv.onCloseDialog.subscribe((res) => {
      if (!this.dialogConfirmation) {
        this.dialogConfirmation = true;
        this.onDialogConfirmation();
      }
    });
  }
  async loadSqlPool() {
    this.sql_Pool = (await this.LabSrv.GetSqlPool(
      this.voucherCode
    ).toPromise()) as any[];
    this.ngZone.run(() => {
      if (this.sql_Pool && this.sql_Pool.length > 0) {
        const { RemainingUptime, UptimeLimit, ResourceList } = this.sql_Pool[0];
        if (RemainingUptime && UptimeLimit) {
          this.sqlPool_remainingTime = this.manageTimestamp(RemainingUptime);
          this.sqlPool_uptimeLimit = this.manageTimestamp(UptimeLimit);
        }
        if (UptimeLimit != null) {
          this.show_sqlPooltime = true;
          if (this.sqlPool_remainingTime > this.sqlPool_uptimeLimit) {
            this.sqlPool_remainingTime = '00:00';
            this.SQLtooltipText = this.tootltiptext2;
          } else if (this.sqlPool_remainingTime == '00:00') {
            this.SQLtooltipText = this.tootltiptext2;
          }
        }
        if (ResourceList && ResourceList.length > 0) {
          this.showSqlPool = true;
          this.sqlResourceList = ResourceList;
        }
      }
    });
  }

  async loadResources(vmReload = true) {
    this.isVMLoading = true;
    this.vmUsage = (
      await this.LabSrv.GetVMUsage(this.voucherCode).toPromise()
    )[0];
    if (!this.vmUsage) {
      this.isVMLoading = false;
      return;
    }
    if (!this.vmUsage.ResourceList) {
      this.isVMLoading = false;
    }
    await this.loadVirtualMachines(vmReload);
    await this.loadSqlPool();
    this.isVMLoading = false;
  }

  async loadVirtualMachines(trigger = true) {
    if (
      trigger &&
      this.vmUsage.ResourceList &&
      this.vmUsage.ResourceList.length > 0
    ) {
      this.attendeLabDetails = (await this.LabSrv.getLabEnvironment(
        this.labDetail.UniqueName,
        this.voucherCode
      ).toPromise()) as Deployment;
      const split = localStorage.getItem(this.splitKey);
      if (split === 'true') {
        parent.postMessage(
          JSON.stringify(this.vmUsage.ResourceList),
          location.origin
        );
      } else {
        this.LabSrv.vmStateUpdate.next(this.vmUsage.ResourceList);
      }
      this.refreshAlert();
    }
    this.convertTimeStamp();
    const { VMLaunchURL } = this.attendeLabDetails;
    this.showLaunchButton =
      VMLaunchURL && VMLaunchURL.length > 1 ? true : false;

    // merge vmUrl parameters wit vMachines
    if (VMLaunchURL && VMLaunchURL.length > 0) {
      (VMLaunchURL as any).forEach((element) => {
        const index = this.vmUsage.ResourceList.findIndex(
          (item) =>
            element.VMName === item.ResourceName &&
            element.ResourceGroupName === item.ResourceGroupName &&
            element.SubscriptionId === this.vmUsage.SubscriptionId &&
            element.VMDNSName === item.DNSName
        );
        if (index > -1) {
          this.vmUsage.ResourceList[index] = {
            ...this.vmUsage.ResourceList[index],
            ...element,
          };
        }
      });
    }
  }

  refreshAlert() {
    this.ngZone.run(() => {
      const div = document.createElement('div');
      div.innerHTML = 'loading in progress';
      div.setAttribute('tabindex', '0');
      div.style.opacity = '0';
      div.style.fontSize = '0';
      const elem = document.getElementById('refresh-all-btn');
      if (elem) {
        elem.appendChild(div);
        div.focus();
        setTimeout(() => {
          elem.removeChild(div);
        }, 2000);
      }
    });
  }

  /**
   * Diallog confirmation
   */
  async onDialogConfirmation() {
    if(this.environment.customer && this.environment.customer === CustomerType.DATABRICKS){
      this.handleDataBricksResources();
      return;
    }
    if (this.dataRow) {
      this.refreshAlert();
      let data: any;
      let operationStatus: string;
      if (this.dataRow.ResourceType === 'virtualMachines/hyperVm') {
        switch (this.vmType.toString()) {
          case '7':
            operationStatus = 'StartVm';
            break;
          case '8':
            operationStatus = 'StopVm';
            break;
          case '9':
            operationStatus = 'RestartVm';
            break;
          default:
            break;
        }
        data = await this.LabSrv.hyperVmOperation(
          operationStatus,
          this.dataRow.VMGuid,
          this.voucherCode
        ).toPromise();
      } else {
        data = await this.LabSrv.virtualMachineOperations(
          this.vmType,
          this.attendeLabDetails.CloudPlatformId,
          this.vmUsage.SubscriptionId,
          this.dataRow.ResourceGroupName,
          this.dataRow.ResourceName,
          this.voucherCode
        ).toPromise();
      }
      if (data) {
        if (data.IsSuccess === true) {
          switch (this.vmType.toString()) {
            case '7':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_START'),
                null
              );
              break;
            case '8':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_STOP'),
                null
              );
              break;
            case '9':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_RESTART'),
                null
              );
              break;
            case '10':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_BACKUP'),
                null
              );
              break;
            default:
              break;
          }

          const split = localStorage.getItem(this.splitKey);
          if (split === 'true') {
            parent.postMessage(JSON.stringify([this.dataRow]), location.origin);
          }
          this.LabSrv.reloadVMs.next();
        } else {
          if (
            data.ErrorMessage ===
            'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
          ) {
            this.modalData = {
              title: 'OOPS',
              description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
            };
            this.modelShow = true;
          }
          const code = 'Error - 70027';
          this.eventEmitter.debugAlert(code, data.ErrorMessage);
        }
      }
    }
  }

  virtualMachineOperations(dataRow, type) {
    this.dialogConfirmation = false;
    this.dataRow = dataRow;
    this.vmType = type;
    let action = '';
    switch (type) {
      case '7':
        action = this.translateSrv.instant('START');
        break;
      case '8':
        action = this.translateSrv.instant('DEALLOCATE');
        break;
      case '9':
        action = this.translateSrv.instant('RESTART');
        break;
      case '10':
        action = this.translateSrv.instant('BACKUP');
        break;
      case '11':
        action = this.translateSrv.instant('START');
        break;
      case '12':
        action = this.translateSrv.instant('STOP');
        break;
      case '13':
        action = this.translateSrv.instant('START');
        break;
      case '14':
        action = this.translateSrv.instant('STOP');
        break;
    }
    this.modalRef = this.modalService.show(VmDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('VIRTUAL_MACHINE_OPERATION_TITLE'),
        data: {
          description:
            this.translateSrv.instant('DESCRIPTION_VM_OPERATION') +
            ' ' +
            action +
            ' ' +
            (this.environment.customer && this.environment.customer === CustomerType.DATABRICKS
              ? dataRow.ResourceType
              : this.translateSrv.instant('DESCRIPTION_VMACHINE_OPERATION')) +
            ' ' +
            dataRow.ResourceName +
            this.translateSrv.instant('DESCRIPTION_VM_OPERATION_QUESTION'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          successButtonText: this.translateSrv.instant('OK'),
        },
      },
    });
  }

  /**
   * launch vm
   * @param vm
   */
  async openVMLaunchURL(vm) {
    try {
      const containerCurrentStatus = await this.LabSrv.getContainerStatus(
        vm.CloudDeploymentId,
        vm.ContainerStatus
      ).toPromise();
      const vmData = await this.LabSrv.getVirtualMachineStatus(
        vm.SubscriptionId,
        vm.ResourceGroupName,
        vm.VMName,
        this.voucherCode,
        vm.Type === 'virtualMachines/hyperVm' ? true : false
      ).toPromise();
      if (
        vmData.Status === 'VM running' &&
        containerCurrentStatus === 'Running'
      ) {
        let url;
        if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
          const vcode = this.aroute.snapshot.params.clVcode;
          const clid = this.aroute.snapshot.params.clid;
          const id = clid ? `/${clid}/${vcode}/` : '/';
          url = this.route.serializeUrl(
            this.route.createUrlTree([
              getEntity() +
                `/` +
                getEntityData() +
                `${id}${this.labDetail.UniqueName}/${
                  this.voucherCode
                }/labenvironment/${vm.InternalId}${
                  vm.Type === 'virtualMachines/hyperVm'
                    ? `?vmguid=${vm.VMGuid}`
                    : ''
                }`,
            ])
          );
        } else {
          url = this.route.serializeUrl(
            this.route.createUrlTree([
              `#/odl/environment/${this.labDetail.UniqueName}/${
                this.voucherCode
              }/${vm.InternalId}${
                vm.Type === 'virtualMachines/hyperVm'
                  ? `?vmguid=${vm.VMGuid}`
                  : ''
              }`,
            ])
          );
        }
        window.open(decodeURIComponent(url), '_blank');
      } else {
        this.modalData = {
          title: 'VM_NOT_READY_MODAL_TITLE',
          description: 'VM_NOT_READY_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      }
    } catch (e) {
      if (
        e?.error?.ErrorDetail ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70028';
      this.eventEmitter.debugAlert(code, e?.error?.ErrorDetail);
    }
  }
  getUpTime(vm) {
    return this.transformTime(vm.VMRunningHours, vm.VMRunningMinutes);
  }

  convertTimeStamp() {
    // new api integrate
    if (this.vmUsage?.UptimeLimit) {
      this.showUpTimeLimit = true;
      this.upTimeLimit = this.manageTimestamp(this.vmUsage.UptimeLimit);
      this.remaining_UpTime = this.manageTimestamp(
        this.vmUsage.RemainingUptime
      );
      if (this.remaining_UpTime === '00:00') {
        this.VMtooltipText = this.tootltiptext2;
      }
    }
  }

  manageTimestamp(value: string): string {
    const dur: any = moment.duration(value);
    return dur.format('hh:mm', { trim: false });
  }

  transformTime(hoursIn: number, minutesIn: number) {
    let hours = '';
    let minutes = '';
    if (hoursIn !== null) {
      if (hoursIn < 10) {
        hours = '0' + hoursIn;
      } else {
        hours = '' + hoursIn;
      }
    } else {
      hours = '00';
    }
    if (minutesIn !== null) {
      if (minutesIn < 10) {
        minutes = '0' + minutesIn;
      } else {
        minutes = '' + minutesIn;
      }
    } else {
      minutes = '00';
    }
    return hours + ':' + minutes;
  }

  /**
   * run vm in new tab
   * @param item
   */
  async openVMandGitDoc(item) {
    try {
      const containerCurrentStatus = await this.LabSrv.getContainerStatus(
        item.CloudDeploymentId,
        item.ContainerStatus
      ).toPromise();
      const vmData = await this.LabSrv.getVirtualMachineStatus(
        item.SubscriptionId,
        item.ResourceGroupName,
        item.VMName,
        this.voucherCode,
        item.Type === 'virtualMachines/hyperVm' ? true : false
      ).toPromise();
      if (
        (containerCurrentStatus === 'Stopped' ||
          vmData.Status === 'VM deallocated' ||
          vmData.Status === 'VM stopped') &&
        vmData.Status !== 'VM deallocating'
      ) {
        if (
          (vmData.Status === 'VM deallocated' ||
            vmData.Status === 'VM stopped') &&
          containerCurrentStatus !== 'Stopped'
        ) {
          this.startVM(vmData);
        } else if (
          (vmData.Status === 'VM deallocated' ||
            vmData.Status === 'VM stopped') &&
          containerCurrentStatus === 'Stopped'
        ) {
          this.startVM(vmData);
          this.startContainer(item.CloudDeploymentId);
        } else if (
          (vmData.Status !== 'VM deallocated' ||
            vmData.Status !== 'VM stopped') &&
          containerCurrentStatus === 'Stopped'
        ) {
          this.startContainer(item.CloudDeploymentId);
        }
        this.modalData = {
          title: 'STARTED_VM_MODAL_TITLE',
          description: 'STARTED_VM_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      } else if (
        vmData.Status === 'VM running' &&
        containerCurrentStatus === 'Running'
      ) {
        let url;
        if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
          const vcode = this.aroute.snapshot.params.clVcode;
          const clid = this.aroute.snapshot.params.clid;
          const id = clid ? `/${clid}/${vcode}/` : '/';
          url = this.route.serializeUrl(
            this.route.createUrlTree([
              getEntity() +
                `/` +
                getEntityData() +
                `${id}${this.labDetail.UniqueName}/${
                  this.voucherCode
                }/labenvironment/${item.InternalId}${
                  item.Type === 'virtualMachines/hyperVm'
                    ? `?vmguid=${item.VMGuid}`
                    : ''
                }`,
            ])
          );
        } else {
          url = this.route.serializeUrl(
            this.route.createUrlTree([
              `#/odl/environment/${this.labDetail.UniqueName}/${
                this.voucherCode
              }/${item.InternalId}${
                item.Type === 'virtualMachines/hyperVm'
                  ? `?vmguid=${item.VMGuid}`
                  : ''
              }`,
            ])
          );
        }
        window.open(decodeURIComponent(url), '_blank');
      } else {
        this.modalData = {
          title: 'VM_NOT_READY_MODAL_TITLE',
          description: 'VM_NOT_READY_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      }
    } catch (e) {
      if (
        e?.error?.ErrorDetail ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70029';
      this.eventEmitter.debugAlert(code, e?.error?.ErrorDetail);
    }
  }

  private async startVM(vmData: any) {
    const data = await this.LabSrv.virtualMachineOperations(
      7,
      this.attendeLabDetails.CloudPlatformId,
      vmData.SubscriptionId,
      vmData.ResourceGroupName,
      vmData.ResourceName,
      this.voucherCode
    ).toPromise();
    if (data.IsSuccess) {
      this.notificationService.success(
        this.translateSrv.instant('MESSAGE_VM_START'),
        null
      );
    } else {
      if (
        data.ErrorMessage ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70030';
      this.eventEmitter.debugAlert(code, data.ErrorMessage);
    }
  }

  private startContainer(cloudDeploymentId: string) {
    this.LabSrv.controlContainerAction(cloudDeploymentId, 'start');
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    unsubscribeCollection(this.subscriptions);
    this.notifier.next();
    this.notifier.complete();
  }

  public async getDBWorkspaceResouces(): Promise<void> {
    this.isVMLoading = true;
    this.LabSrv.getDatabricksClusters({
      voucherCode: this.voucherCode,
    }).subscribe((result) => {
      this.databriksCluserList = result;
    });
    this.LabSrv.getDatabricksSQLEP({ voucherCode: this.voucherCode }).subscribe(
      (result) => {
        this.databriksSQLEPList = result;
        this.isVMLoading = false;
      }
    );
    this.dbUsage = (
      await this.LabSrv.GetVMUsage(this.voucherCode).toPromise()
    )[0];
    this.showUpTimeLimit =
      moment.duration(this.dbUsage.UptimeLimit).asMilliseconds() > 0;
  }

  copyContent(): void {
    this.notificationSrv.success(
      this.translateSrv.instant('MESSAGE_COPIED'),
      null
    );
  }

  public startDBCluster(dbClusterObject: DatabricksResource): Observable<any> {
    return this.LabSrv.startDBCluster({
      voucherCode: this.voucherCode,
      clusterId: dbClusterObject.ResourceId,
    });
  }

  public stopDBCluster(dbClusterObject: DatabricksResource): Observable<any> {
    return this.LabSrv.stopDBCluster({
      voucherCode: this.voucherCode,
      clusterId: dbClusterObject.ResourceId,
    });
  }

  public startDBSQLEP(dbClusterObject: DatabricksResource): Observable<any> {
    return this.LabSrv.startDBSQLEP({
      voucherCode: this.voucherCode,
      endpointId: dbClusterObject.ResourceId,
    });
  }

  public stopDBSQLEP(dbClusterObject: DatabricksResource): Observable<any> {
    return this.LabSrv.stopDBSQLEP({
      voucherCode: this.voucherCode,
      endpointId: dbClusterObject.ResourceId,
    });
  }

  private handleDataBricksResources() {
    switch (this.vmType.toString()) {
      case '11':
        this.handleResourceAction(
          this.startDBCluster,
          this.getUpdatedDBClusterStatus,
          this.dataRow,
          this.databriksCluserList.indexOf(this.dataRow),
          this.translateSrv.instant('CLUSTER_START'),
        );
        break;
      case '12':
        this.handleResourceAction(
          this.stopDBCluster,
          this.getUpdatedDBClusterStatus,
          this.dataRow,
          this.databriksCluserList.indexOf(this.dataRow),
          this.translateSrv.instant('CLUSTER_STOP'),
        );
        break;
      case '13':
        this.handleResourceAction(
          this.startDBSQLEP,
          this.getUpdatedDBSQLEPStatus,
          this.dataRow,
          this.databriksSQLEPList.indexOf(this.dataRow),
          this.translateSrv.instant('SQLEP _START'),
        );
        break;
      case '14':
        this.handleResourceAction(
          this.stopDBSQLEP,
          this.getUpdatedDBSQLEPStatus,
          this.dataRow,
          this.databriksSQLEPList.indexOf(this.dataRow),
          this.translateSrv.instant('SQLEP _STOP'),
        );
        break;
    }
  }

  private handleResourceAction(
    actionFunction: Function,
    getStatusFunction: Function,
    dbClusterObject: DatabricksResource,
    clusterIndex: number,
    successMessage: string,
  ): void {
    actionFunction.call(this, dbClusterObject, clusterIndex).subscribe(
      () => {
        getStatusFunction.call(this, dbClusterObject, clusterIndex);
        this.notificationService.success(
          successMessage,
          null
        );
      },
      (error) => {
        this.notificationService.error(
          this.translateSrv.instant('DB_ERROR_MSG'),
          null
        );
      }
    );
  }

  public getUpdatedDBSQLEPStatus(
    dbClusterObject: DatabricksResource,
    clusterIndex: number
  ) {
    this.LabSrv.getUpdatedDBSQLEPStatus({
      voucherCode: this.voucherCode,
      endpointId: dbClusterObject.ResourceId,
    }).subscribe((result) => {
      this.databriksSQLEPList[clusterIndex].ResourceStatus =
        result.ResourceStatus;
    });
  }

  public getUpdatedDBClusterStatus(
    dbClusterObject: DatabricksResource,
    clusterIndex: number
  ) {
    this.LabSrv.getUpdatedDBClusterStatus({
      voucherCode: this.voucherCode,
      clusterId: dbClusterObject.ResourceId,
    }).subscribe((result) => {
      this.databriksCluserList[clusterIndex].ResourceStatus =
        result.ResourceStatus;
    });
  }
}
