<div class="home-footer" id="footer" #footer>
    <div class="d-flex d-sm-flex justify-content-between py-2 px-4 align-items-center">
        <div class="footer-company-details">{{currentYear}} © {{companyName}}. <span>{{'ALL_RIGHTS_RESERVED' |
                translate }}. </span>
                <ng-container *ngIf="isMicrosoftFooter; else noMicrosoftLeft;">

                  <!-- later on send from component may be -->
                  <span>&nbsp;This site is hosted for Microsoft by Spektra Systems LLC.</span>

                </ng-container>
                <ng-template #noMicrosoftLeft>
                  <a class="privacy-link ts-text-primary underline" href="{{privacyLink}}" target="_blank" translate="PRIVACY_POLICY">
                    <i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2"></i>
                </a> |
                <a class="ts-text-primary underline" href="{{termsLink}}" target="_blank" translate="TERMS_CONDITIONS">
                    <i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2"></i>
                </a>
                </ng-template>

        </div>
        <div class="footer-links ml-4">
            <ng-container *ngIf="isMicrosoftFooter; else noMicrosoftRight">
              <div class="text-right">
                <a class="ts-text-primary ml-0 underline" href="https://support.microsoft.com/contactus" target="_blank">
                  <span>
                    Contact Microsoft
                    <i class="fa fa-external-link fa-1x ts-text-primary"></i>
                  </span>
                </a> | &nbsp;
                <a class="ts-text-primary underline ml-0" href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">
                  <span>
                    Privacy & Cookies
                    <i class="fa fa-external-link fa-1x ts-text-primary"></i>
                  </span>
                </a> | &nbsp;
                <a class="ts-text-primary underline ml-0" href="http://go.microsoft.com/fwlink/?LinkID=206977" target="_blank">
                  <span>
                    Terms of Use
                    <i class="fa fa-external-link fa-1x ts-text-primary"></i>
                  </span>
                </a> | &nbsp;
                <a class="ts-text-primary underline ml-0" href="https://www.microsoft.com/en-us/legal/intellectualproperty/trademarks" target="_blank">
                  <span>
                    Trademarks
                    <i class="fa fa-external-link fa-1x ts-text-primary"></i>
                  </span>
                </a> | &nbsp;
                <a class="ts-text-primary underline ml-0" href="https://www.microsoft.com/en-us/devices/safety-and-eco" target="_blank">
                  <span>
                    Safety & eco
                    <i class="fa fa-external-link fa-1x ts-text-primary underline"></i>
                  </span>
                </a>
              </div>
            </ng-container>
            <ng-template #noMicrosoftRight>
              <span *ngIf="cloudLink" class="text-right powered-by">{{'POWERED_BY' |
                translate }}
                <a class="ts-text-primary ml-0 underline" href="{{cloudLink}}" target="_blank">
                  CloudLabs<i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2"></i>
                </a>
              </span>
            </ng-template>
        </div>
    </div>
</div>
