import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LabCluster, LabClusterEntity } from './modals/lab-cluster.modal';
import {Observable, Subject} from 'rxjs';
import { environment } from '../../environments/environment';
import { EventEmitterService } from '@teams-auth';

@Injectable()
export class LabClusterService {
  labCluster: LabCluster;
  labClusterEntities: LabClusterEntity[];
  getLabCluster = new Subject<LabCluster>();
  getLabClusterEntities = new Subject<LabClusterEntity[]>();
  constructor(private httpClient: HttpClient, private eventEmitter: EventEmitterService) {
  }
  getLabClusterFromServer(uniqueIdentifier: string) {
    this.httpClient.get(environment.APIURL + 'LabCluster/GetPublicLabCluster?uniqueIdentifier='
      + uniqueIdentifier)
      .subscribe(
        (data) => {
          this.labCluster = data as LabClusterEntity;
          this.getLabCluster.next(this.labCluster);
        },
        error => {
          const code = 'Error - 70042';
          this.eventEmitter.debugAlert(code, error);
        }
      );
  }
  getPracticeTest(mockExamId: any, voucher: any) {
    return this.httpClient.get<LabClusterEntity[]>(environment.APIURL +
      'practicetest/GetPracticeTest?guid=' + mockExamId + '&inviteLink=' + voucher);
  }
  getLabClusterEntitiesFromServer(voucherCode: string) {
    return this.httpClient.get<LabClusterEntity[]>(environment.APIURL +
      'LabCluster/GetBaseLineEventUserStatus?voucher='
      + voucherCode
    );
    //   .subscribe(data => {
    //   this.labClusterEntities = data as LabClusterEntity[];
    //   this.getLabClusterEntities.next(this.labClusterEntities);
    // });
  }
  getLabClusterDetails(uniqueIdentifier: string): Observable<LabCluster> {
    return this.httpClient.get<LabCluster>(environment.APIURL + 'LabCluster/GetPublicLabCluster?uniqueIdentifier='
      + uniqueIdentifier);
  }

  getRetryLabDetails(voucher: any, uniqueName: any) {
    return this.httpClient.get<LabCluster>(environment.APIURL + 'LabCluster/CheckUserEligibleForRetry?labClusterVoucher=' + voucher + '&eventUniqueName=' + uniqueName)
  }

  getRetryLab(voucher: any, uniqueName: any ){
    return this.httpClient.get<LabCluster>(environment.APIURL + 'LabCluster/RetryRegistration?labClusterVoucher='+voucher+'&eventUniqueName='+ uniqueName)
  }
}
