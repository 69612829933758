import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkDownComponent } from './mark-down/mark-down.component';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AutoFocusModalDirective } from './directives/auto-focus-modal.directive';
import { RefocusPreviousElDirective } from './directives/refocus-previous-el.directive';
import { TrapFocusDirective } from './directives/trap-focus.directive';

import { TargetBlankPipe } from './pipes/target-blank.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { DialogRolePipe } from './pipes/dialog-role.pipe';

import { LoadingComponent } from './loading/loading.component';
import { DialogePromptComponent } from './components/dialoge-prompt/dialoge-prompt.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { VmSpinnerComponent } from './components/vm-spinner/vm-spinner.component';
import { DurationTimeComponent } from './components/duration-time/duration-time.component';
import { SkipToMainContentComponent } from './components/skip-to-main-content/skip-to-main-content.component';

import { NotificationService } from './services/notification.service';
import { LoadingService } from './services/loading.service';

import { TeamsUiModule } from '@teams-ui-angular';

@NgModule({
  declarations: [
    MarkDownComponent,
    LoadingComponent,
    TargetBlankPipe,
    FooterComponent,
    HeaderComponent,
    DialogePromptComponent,
    SafePipe,
    PluralizePipe,
    VmSpinnerComponent,
    DurationTimeComponent,
    SkipToMainContentComponent,
    AutoFocusModalDirective,
    RefocusPreviousElDirective,
    TrapFocusDirective,
    DialogRolePipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    TeamsUiModule,
    NgbDropdownModule,
    NgbModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
    }),
    DialogModule,
  ],
  exports: [
    MarkDownComponent,
    MarkdownModule,
    LoadingComponent,
    TargetBlankPipe,
    FooterComponent,
    HeaderComponent,
    DialogModule,
    DialogePromptComponent,
    SafePipe,
    PluralizePipe,
    VmSpinnerComponent,
    DurationTimeComponent,
    SkipToMainContentComponent,
    AutoFocusModalDirective,
    RefocusPreviousElDirective,
    TrapFocusDirective,
    DialogRolePipe,
  ],
  providers: [MarkdownService, LoadingService, NotificationService],
})
export class UtilityModule {}
