<ngx-remote-desktop (click)="changeFocusToVM()"
(keydown.enter)="changeFocusToVM()" *ngIf="!loader && manager !== undefined" role="application" 
[tabIndex]="manager.getState() === remoteDesktopStates.CONNECTED ? 0 : -1"
[attr.aria-label]="hidingRdpAriaLabel"
[manager]="manager" [isInsideEnv]="isInsideEnv">
  <!-- Override connection state messages -->
  <ngx-remote-desktop-connecting-message>
  </ngx-remote-desktop-connecting-message>
</ngx-remote-desktop>

  <div *ngIf="!loader && manager === undefined && !isInsideEnv" class="offset-sm-3 text-center align-items-center col-6 alert alert-info mt-4">
    {{"VM_NOT_CONNECTED" | translate}}
    <div class="mt-5">
      <teams-button *ngIf="credGenerated" aria-label="reconnect" [title]="'BUTTON_RECONNECT' | translate" (buttonClick)="subscribeVmDetailsChange()" [classList]="'btn ts-btn-fluent-primary btn-primary ngx-remote-desktop-message-body-btn mb-2'">
      </teams-button>
    </div>
  </div>
<!-- Narrator starting alert message -->
<div *ngIf="narratorAlertMessage && isInsideEnv" style="font-size: 0px; width: 0px;">
  <span role="alert">Starting Narrator Please wait..</span>
</div>

<div class="vex-splash-screen vex-splash_2" *ngIf="loader">
  <div class="wrapper">
    <div class="ball-scale-multiple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>