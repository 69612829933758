<div *ngIf="environment.customer === customerType.DATABRICKS; else otherFooter" class="db-footer">
  <footer id="footer" role="contentinfo" aria-label="footer" class="container-fluid footerBottom"
    *ngIf="!loading || enableFooter" style="background-color:#1A3138; color: #E8E8E8">
    <div class="row">
      <div class="col-md-8 footer-col-mr" style="padding-left: 30px;">
        <div class="row">
          <p class="mb-0 pt-3 pb-3">
            <span>{{year}} © Databricks {{'ALL_RIGHTS_RESERVED' | translate}}.&nbsp; </span>
          </p>
          <p style="display: flex;  align-items: flex-end;">
            <a tabindex="0" href="https://www.databricks.com/legal/privacynotice" target="_blank"
              [attr.aria-label]=" 'open in new tab ' + 'privacy policy'">{{ 'PRIVACY_NOTICE' | translate }}</a>
            <span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;">
            <a tabindex="0" href="https://www.databricks.com/legal/terms-of-use" target="_blank"
              [attr.aria-label]=" 'open in new tab ' + 'terms of use'">{{ 'TERMS_OF_USAGE' | translate }}</a>
            <span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;">
            <a tabindex="0" href="javascript:OneTrust.ToggleInfoDisplay()">{{ 'YOUR_PRIVACY_CHOICES' | translate }}</a>
            <span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;">
            <a tabindex="0" href="https://www.databricks.com/legal/privacynotice#dbadditionalinformation"
              target="_blank" [attr.aria-label]=" 'open in new tab ' + 'privacy notice'">{{
              'YOUR_CALIFORNIA_PRIVACY_RIGHTS' | translate }}</a>
            <span aria-hidden="true">&nbsp;<img
                src="https://cms.databricks.com/sites/default/files/2022-12/gpcicon_small.png" style="max-height:15px"
                alt="gpcicon"></span>
          </p>
        </div>
      </div>
      <div class="col-md-4  footer-col-pd" style="padding-right: 30px;">
        <div class="row" style="float: right;">
          <p class="mb-0 pt-3 pb-3">
            <span> {{'POWERED_BY' | translate}} </span>
            <a tabindex="0" role="link" href="{{ poweredByURL }}" target="_blank"
              [attr.aria-label]=" 'open in new tab ' + poweredByURLText ">{{ poweredByURLText }}</a>
            <span aria-hidden="true" *ngIf="showSupported">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;" *ngIf="showSupported"><i class="pr-1 fa fa-envelope" style="padding-bottom: 3px;"
              aria-hidden="true"></i>
            <span>{{"SUPPORT_EMAIL_LABEL" | translate}}:&nbsp;</span>
            <a [attr.aria-label]="'Mail to ' +SupportEmail"
              href="mailto:cloudlabs@databricks.com">cloudlabs@databricks.com</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>

<ng-template #otherFooter>
  <footer id="footer" role="contentinfo" aria-label="footer" class="container-fluid footerBottom"
    *ngIf="!loading || enableFooter">
    <div class="row">
      <div class="col-md-5 footer-col-mr" style="padding-left: 30px;">
        <div class="row">
          <p class="mb-0 pt-3 pb-3">
            <span>{{year}} © {{labData?.EnableCustomFooter ? labData?.PartnerName : 'Spektra Systems LLC'
              }}. {{'ALL_RIGHTS_RESERVED' | translate}}&nbsp;
            </span>
          </p>
          <p style="display: flex;  align-items: flex-end;"><a tabindex="0"
              href="{{ labData?.EnableCustomFooter && labData?.CustomPrivacyUrl != null && labData?.CustomPrivacyUrl != '' ? labData?.CustomPrivacyUrl : policyLink}}"
              target="_blank" [attr.aria-label]=" 'open in new tab ' + 'PRIVACY POLICY'">{{
              'PRIVACY_POLICY' | translate }}</a>
            <span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;"><a tabindex="0"
              href="{{ labData?.EnableCustomFooter && labData?.TermsofUseURL != null && labData?.TermsofUseURL != '' ? labData?.TermsofUseURL : terms}}"
              target="_blank" [attr.aria-label]=" 'open in new tab ' + 'TERMS OF SERVICE'">{{
              'TERMS_OF_SERVICE' | translate }}</a>
          </p>
        </div>
      </div>
      <div class="col-md-7  footer-col-pd" style="padding-right: 30px;">
        <div class="row" style="margin-left: 5%; float: right;">
          <p class="mb-0 pt-3 pb-3">
            <span> {{'POWERED_BY' | translate}} </span>
            <a href="{{ poweredByURL }}" target="_blank" [attr.aria-label]=" 'open in new tab ' + poweredByURLText ">{{
              poweredByURLText }}</a>
            <span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p class="mb-0 pt-3 pb-3" *ngIf="!labData?.EnableCustomFooter">
            <i class="pr-1 fa fa-envelope" style="padding-bottom: 3px;" aria-hidden="true"></i>
            <span>{{"SALES_EMAIL_LABEL" | translate}}:{{' '}}</span>
            <a [attr.aria-label]="'Mail to ' +contactEmail" href="mailto:{{ contactEmail }}" target="_blank">{{
              contactEmail }}</a><span aria-hidden="true">&nbsp;|&nbsp;</span>
          </p>
          <p style="display: flex;  align-items: flex-end;"><i class="pr-1 fa fa-envelope" style="padding-bottom: 3px;"
              aria-hidden="true"></i>
            <span>{{"SUPPORT_EMAIL_LABEL" | translate}}:{{' '}}</span>
            <a [attr.aria-label]="'Mail to ' +SupportEmail"
              href="mailto:{{ labData?.EnableCustomFooter && labData?.SupportEmail != null && labData.SupportEmail != '' ? labData?.SupportEmail : SupportEmail }}"
              target="_blank">{{labData?.EnableCustomFooter ? labData?.SupportEmail : SupportEmail }}</a>
          </p>
        </div>
      </div>
    </div>
  </footer>

</ng-template>