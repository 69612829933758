import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {EventService} from '../../services/event.services';
import {NotificationService} from '../../services/notification.service';
import { EventEmitterService } from '@teams-auth';


@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private route: Router,
    private eventSrv: EventService,
    private eventEmitter: EventEmitterService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if(error && error.name == 'HttpErrorResponse' && error.statusText == 'Unknown Error' && error.status == 0){
            this.eventSrv.tokenError();
          }
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          if (error.statusText == 'Internal Server Error') {
            let {url} = error;
            if (error.error.ErrorDetail !== 'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.') {
              if (url.includes("/GitHubDoc/GetGitHubMaster/")){
                this.notificationService.error(this.translateSrv.instant('GITHUB_DOC_INVALID_URL'), null);
              }else{
                this.notificationService.error(this.translateSrv.instant(error.error.ErrorDetail), null);
              }
            }
            if(error.error.ErrorDetail == 'Object reference not set to an instance of an object.'){
              this.route.navigate(['/error']);
            }
          }
          if(error.status === 500) {
            this.notificationService.error(error.error.ErrorDetail, null);
          }
          if (error.status === 400) {
            this.notificationService.error(error.error.Message, '' + error.status);
            this.route.navigate(['/error']);
          }
          const code = 'Error - 70059';
          this.eventEmitter.debugAlert(code, error);
          return throwError(error);
          // return observableOf<HttpEvent<any>>();
        })
      );

  }

}
