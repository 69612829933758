import { Injectable } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() {}

  public getImageTag(path, markdownService: MarkdownService): any {
    const texting = path.substring(0, path.lastIndexOf('/'));
    markdownService.renderer.image = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http')) {
        return `<img alt ="${
          title || text
        }" src="${texting}/${href}">`;
      } else {
        return `<img alt ="${title || text}" src="${href}">`;
      }
    };
  }

  getLinks(markdownService: MarkdownService) {
    markdownService.renderer.link = (
      href: string,
      title: string,
      text: string
      ) => {
        if (!href.includes('http')) {
        return (
          '<a  class="selfnavigation" tabindex="0" href="javascript:void(0)" data-id="' +
          href +
          '">' +
          text +
          '</a>'
        );
      } else {
        return (
          '<a role="link" tabindex="0" href="' +
          href +
          '" target="_blank" rel="nofollow noopener noreferrer">' +
          text +
          '</a>'
        );
      }
    };
  }
}
