<cloudlabs-skip-to-main-content *ngIf="!isMylearning && environment.appType !== 'trial'"
  [mainDiv]="'main-landmark0'"></cloudlabs-skip-to-main-content>

<cloudlabs-refined-header [customLogo]="lab.PartnerheaderLogo ? lab.PartnerheaderLogo : lab?.PartnerLogo" [lab]="lab"
  [menu]="false" [actionList]="actionList" [counter]="counter" (actionClickEvent)="actionClickEvent($event)"
  [modeOfOperation]="modeOfOperation" [attendeeEndDate]="endDateData">
</cloudlabs-refined-header>

<div class="vm-environment">

  <div #docGuide [class.sideNavBar]="isSidenav === true" [class.stretch]="!enableHeader" [ngClass]="{'inside-attendee-container': environment.appType === 'attendee', 'inside-trial-container' : environment.appType === 'trial',
   'cloudlab-portal': isMylearning === true }" *ngIf="!isComplete" [attr.role]="!isMylearning ? 'main' : ''">

    <ng-container [ngSwitch]="layoutType">
      <ng-container *ngSwitchCase="environmentLayout.DATABRICKS">
        <cloudlabs-databricks-layout [vCode]="vCode" [isDraggable]="isDraggable"></cloudlabs-databricks-layout>
      </ng-container>

      <ng-container *ngSwitchCase="environmentLayout.VM">
        <div [class.frameFull]="isDraggable" class="iframeBlock" id="iframeBlock"
          [ngClass]="{ 'overflow-y-auto': !loading && !loader && manager?.getState() !== remoteDesktopStates.CONNECTED }">
          <ng-container>
            <div class="vdi-container" *ngIf="vmstate && manager === undefined" id="vdi-container">
              <p style="display: none" id="remote-desktop-description">
                Press enter to focus in to Remote Desktop. You can focus out of VM by pressing Escape key.
              </p>
              <app-vm [loader]="loader" [isInsideEnv]="true" (checkVmStatus)="checkVmStatus()" [lab]="lab"></app-vm>
            </div>
          </ng-container>
          <ng-container *ngIf="!vmstate && !isComplete">
            <div class="alert col-md-6 col-sm-8 mx-auto mt-5" [ngClass]="{
            'alert-custom alert-light-primary' : isMylearning,
            'alert-info' : !isMylearning
          }" tabindex="0">
              <div *ngIf="remoteConnectProcessStatus === ''">
                <div role="alert" aria-live="assertive" aria-atomic="true"
                  [attr.aria-label]="('ACCESS_SESSION_CLOSED_TITLE' | translate)">
                  <h4>{{ "ACCESS_SESSION_CLOSED_TITLE" | translate }}</h4>
                </div>
                <ul class="list-unstyled high-contrast-content">
                  <li>{{ "DUPLICATE_SESSION" | translate }}</li>
                  <li>{{ "GATEWAY_NOT_RUNNING" | translate }}</li>
                  <li>{{ "NETWORK_DISCONNECTION" | translate }}</li>
                </ul>
              </div>
              <div appVmRole class="d-flex align-items-center justify-content-center text-center"
                *ngIf="remoteConnectProcessStatus !== ''">
                <div *ngIf="inProgressIndicator" class="loadingio-spinner-spinner-gu4g40ggr97">
                  <div class="ldio-bvwfzzyjjt">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                {{ remoteConnectProcessStatus }}
              </div>
            </div>
            <teams-button *ngIf="showRefreshButton" aria-label="reconnect" (buttonClick)="remoteConnect()"
              [classList]="'btn ts-btn-fluent-primary btn-primary mx-auto d-block'">
              {{'BUTTON_RECONNECT' | translate}}
            </teams-button>
          </ng-container>

        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="!loading" [class.draggable-sidebar]="isDraggable"
      class="guideBlock cl-sideBar fixed-top one vmSideBar courses-doc" [class.sidebarCollapse]="!isSidenav"
      #guideBlock>

      <p aria-label="VM Name" [ngClass]="{'no-bottom': environment?.appType === 'lab'}" aria-hidden="true"
        (click)="openSidenav()" class="vmName anc">

        {{ vmName }}

      </p>

      <div id="side-con" class="col-md-12 p-0 123">

        <div class="sidebar-close cursor-pointer" *ngIf="isSidenav" (keydown.enter)="closeSidenav()"
          (click)="closeSidenav()">

          <div role="button" tabindex="0"
            attr.aria-label="{{ labTitle === '' ? lab.title : labTitle }}Collapse Sidebar">

            <i class="fas fa-chevron-right white-color"></i>

          </div>

        </div>

        <div class="sidebar-open cursor-pointer" *ngIf="!isSidenav" (keydown.enter)="openSidenav()"
          (click)="openSidenav()">

          <div role="button" tabindex="0" attr.aria-label="{{ labTitle === '' ? lab.title : labTitle }}Expand Sidebar">

            <i class="fas fa-chevron-left white-color"></i>

          </div>

        </div>

        <div #sidebarHeader class="sidebarHeader py-1" [attr.aria-hidden]='isSidenav !== true'>


        </div>
        <cloudlabs-notes [ngClass]="{'mt-10':isMylearning && notes?.length > 0}" class="ml-4 mr-3 mt-2 mb-1" *ngIf="guideData.CustomInstruction" [customInstruction]="guideData.CustomInstruction"></cloudlabs-notes>
        <cloudlabs-doc-guide [attr.aria-hidden]="docguidescreen" *ngIf="loadGitDoc || isDeviceSmall()"
          [ngClass]="{'pl-4': environment?.appType === 'lab'}" [manager]="manager" [labDetail]="lab"
          [voucherCode]="vCode" [isSidenav]="isSidenav" [lang]="lang" [mode]="modeOfOperation" [guideData]="guideData"
          [splitKey]="splitKey" (labIsCompleted)="completeTheLab($event)" (onLoadingComplete)="calculateHeight()"
          [isMylearning]="isMylearning" (splitScreen)="splitWindow()" [layoutType]="layoutType" (collapseSideBar)="closeSidenav()"></cloudlabs-doc-guide>

      </div>

    </div>
  </div>


  <!--  Message on Deallocating -->
  <div [ngClass]="isMylearning ? 'row mt-5 flex-center pt-5' : 'col-md-7 mx-auto mt-5 flex-center'" role="alert"
    *ngIf="isComplete && modeOfOperation === 'Deallocating'">
    <div class="card text-center">
      <div class="alert mb-0" [ngClass]="{
      'alert-custom alert-light-warning' : isMylearning,
      'alert-warning' : !isMylearning
    }">
        <h4 class="block">
          <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
          &nbsp; {{ "MESSAGE_DEALLOCATING" | translate }}
        </h4>
      </div>
    </div>
  </div>

  <div class="doc-content" [ngClass]="{'card-min-height': ['Deallocated','Deployed'].includes(modeOfOperation)}">
    <!-- <div class="course-header-title" *ngIf="isMylearning && ([
    'Deallocated',
    'Deployed'].includes(modeOfOperation) && isComplete)">
      <span class="back-arrow cursor-pointer" (click)="goBackPage()"><i class="fas fa-arrow-left"></i></span>
      <h3>{{lab.Title}}</h3>
    </div> -->
    <!--  Message on Deployed -->
    <div class="col-md-7 mx-auto mt-5" [ngClass]="{'pt-5' : isMylearning}" role="alert" *ngIf="isComplete && modeOfOperation === 'Deployed'">
      <div class="card text-center" tabindex="0">
        <div class="alert mb-0" [ngClass]="{
        'alert-custom alert-light-warning' : isMylearning,
        'alert-warning' : !isMylearning
      }">
          <h4 class="block">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            &nbsp; {{ "ALERT_ALREADY_COMPLETED" | translate }}
          </h4>
          <p>{{ "ALERT_THANKS" | translate }}</p>
        </div>
      </div>
    </div>

    <!--  Message on Deallocated -->
    <div class="col-md-7 mx-auto mt-5" [ngClass]="{'pt-5' : isMylearning}" role="alert" *ngIf="isComplete && modeOfOperation === 'Deallocated'">
      <div class="card text-center">
        <div class="alert mb-0" [ngClass]="{
        'alert-custom alert-light-warning' : isMylearning,
        'alert-warning' : !isMylearning
      }">
          <h4 class="block">
            <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
            &nbsp; {{ "ALERT_ODL_END" | translate }}
          </h4>
          <p>{{ "ALERT_ODL_END_THANKS1" | translate }}</p>
          <p>{{ "ALERT_THANKS" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Error message on failed -->
  <div class="col-md-7 mx-auto mt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Failed'">
    <div class="card text-center">
      <div class="alert mb-0" [ngClass]="{
      'alert-custom alert-light-danger' : isMylearning,
      'alert-danger' : !isMylearning
    }">
        <h4 class="block">
          <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
          <span *ngIf="lab?.PartnerName !== 'VMware'"
            [outerHTML]="'MESSAGE_FAILED' | translate: { Email: lab.ContactEmail }"></span>
          <span *ngIf="lab?.PartnerName === 'VMware'"
            [outerHTML]="'MESSAGE_FAILED_VMWARE' | translate: { Email: lab.ContactEmail }"></span>
        </h4>
        <p class="text-danger" [innerHTML]="error"></p>
      </div>
    </div>
  </div>

  <!-- Error message on failed -->
  <div class="col-md-7 mx-auto mt-5" role="alert" *ngIf="isComplete && modeOfOperation === 'Error'">
    <div class="card text-center">
      <div class="alert mb-0" [ngClass]="{
      'alert-custom alert-light-danger' : isMylearning,
      'alert-danger' : !isMylearning
    }">
        <h4 class="block" *ngIf="lab?.PartnerName !== 'VMware'">
          <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
          &nbsp; {{ "MESSAGE_FAILED" | translate: { Email: lab.ContactEmail } }}
        </h4>
        <h4 class="block" *ngIf="lab?.PartnerName === 'VMware'">
          <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
          &nbsp; {{ "MESSAGE_FAILED_VMWARE" | translate: { Email: lab.ContactEmail } }}
        </h4>
        <p class="text-danger">{{ "RDP_ERROR" | translate }}</p>
      </div>
    </div>
  </div>

  <!-- Loading screen -->
  <div class="vex-splash-screen" *ngIf="loading">
    <div class="wrapper">
      <div class="ball-scale-multiple">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

</div>

<ng-container *ngIf="showImagePopup">
  <cloudlabs-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl *ngIf="showImagePopup"
    [popupWidth]="imagePopupStyle.width" [popupHeight]="imagePopupStyle.height" [baseZIndex]="9999902"
    [isDialog]="showImagePopup" (popupResponse)="closeImagePopup($event)">
    <div header class="subheading">
      {{ 'PREVIEW_BUTTON'| translate }}
    </div>
    <div content class="">
      <div class="text-center" [innerHTML]="imagePopupElement">
      </div>
    </div>
    <div footer class="col-md-12 mt-3">
      <teams-button [ngClasses]="environment?.appType === 'lab'? 'ts-btn-fluent-primary': 'ts-btn-fluent-white pt-2 pb-2'" class="" [classList]="'mt-2 btn ts-btn-fluent ts-btn-grey text-nowrap'"
        [title]="'CLOSE' | translate" (click)="showImagePopup = false">
      </teams-button>
    </div>
  </cloudlabs-dialoge-prompt>
</ng-container>

<div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
  aria-describedby="modal-desc" *ngIf="modelShow" [class.validation]="modelShow" id="validation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="modal-title" class="modal-title">{{modalData.title | translate}}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <p id="modal-desc">
          {{modalData.description | translate}}
        </p>
      </div>
      <div class="modal-footer">
        <teams-button *ngIf="modalData.showCancel === true"
          [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'" (buttonClick)="modelShow=false">{{'CANCEL'
          | translate}}
        </teams-button>
        <teams-button *ngIf="modalData.type === 'DEALLOCATE_LAB'"
          [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="deallocateLab()">
          {{'OK' |
          translate}}</teams-button>
      </div>
    </div>
  </div>
</div>