import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Account, InteractionRequiredAuthError } from 'msal';
import { BehaviorSubject, interval, ReplaySubject, Subscription } from 'rxjs';
import {
  getSilentRenewIntervalTime,
  inIframe,
  isExternalFlowRegUniqueName,
  setStyleColours,
} from '@utility';
import {
  removeHeaderTrailerFromKey,
  updateKey,
} from '../_utils/encryption-util';
import { EventEmitterService } from './event-emitter.service';
import { UserSessionService } from './user-session.service';
import * as forge from 'node-forge';
import * as microsoftTeams from '@microsoft/teams-js';
import { DOCUMENT } from '@angular/common';
import { LoadingService, miscellaneousConst } from '@utility';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static instance: AuthService;
  // private fields
  authToken: BehaviorSubject<string> = new BehaviorSubject('');
  environment: any;
  private unsubscribe: Subscription[] = [];
  public teamsInitialized = false;
  public params: any;
  isUser = true;
  isIframe = false;
  isSession = 0;
  attendeeMenu = new ReplaySubject();
  private baseUrl: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private msalService: MsalService,
    private router: Router,
    private eventEmitter: EventEmitterService,
    private userSessionService: UserSessionService,
    private loadingService: LoadingService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private broadcastService: BroadcastService,
    private readonly translateService: TranslateService,
    private http: HttpClient,
    @Inject('environment')
    environment
  ) {
    this.baseUrl = environment.apiUrl;
    // Singleton class logic
    if (AuthService.instance) {
      return AuthService.instance;
    }

    AuthService.instance = this;

    this.environment = environment;

    microsoftTeams.initialize(() => {
      this.teamsInitialized = true;
      localStorage.setItem('theme', 'default');
      this.document.body.classList.add('theme-default');
    });
  }

  get currentUserValue(): Account {
    const accountData = this.msalService.getAccount();
    if (accountData) {
      return accountData;
    } else {
      return null;
    }
  }

  public isTeamsAppUser(): boolean {
    const isTeamsAppUser = localStorage.getItem('isTeamapp');
    return isTeamsAppUser && isTeamsAppUser === 'true' ? true : false;
  }

  public getToken(): any {
    return this.authToken.asObservable();
  }

  public setToken(token: any): void {
    this.authToken.next(token);
    localStorage.setItem('authToken', token);
    localStorage.setItem('msal.idtoken', token);
    localStorage.setItem('context', this.appType);
  }

  public setTokenForExternal(token: any): void {
    this.authToken.next(token);
  }

  login() {
    const loginUrl = `${this.router['location']._platformLocation.location.origin}/`;
    let url = localStorage.getItem('preUrl');
    if (url) {
      url = this.executeIfPreURLAvailable(url, loginUrl);
      url = url.replace(/([^:]\/)\/+/g, '$1'); // Remove any duplicates // from URL
    } else {
      url = `${loginUrl}`;
    }
    localStorage.setItem('isLogginProcessed', 'true');
    this.msalService.loginRedirect({ redirectStartPage: url });
    localStorage.setItem('context', this.appType);

  }

  private checkForPracticeTestsCourserUrl(
    currentUrl: string,
    loginUrl: string,
    path: string
  ) {
    return currentUrl.includes('/details')
      ? `${loginUrl}${currentUrl}`
      : `${loginUrl}catalog/${path}`;
  }

  private executeIfPreURLAvailable(url: string, loginUrl: string) {
    if (url.includes('catalog/labs')) {
      url = `${loginUrl}catalog/labs`;
    } else if (url.includes('catalog/practice-tests')) {
      url = this.checkForPracticeTestsCourserUrl(
        url,
        loginUrl,
        'practice-tests'
      );
    } else if (url.includes('catalog/courses')) {
      url = this.checkForPracticeTestsCourserUrl(url, loginUrl, 'courses');
    } 
    // else if (url.includes('request-consent')) {
    //   url = localStorage.getItem('preUrl');

    //   const index = url.indexOf('#id_token')
    //   if (index >-1) {
    //     const returnUrl = url.substring(0, index);
    //     // url = `${loginUrl}${returnUrl}`
    //     url = returnUrl
    //   } else {
    //     // url = `${loginUrl}${localStorage.getItem('preUrl')}`;
    //     url = localStorage.getItem('preUrl');
    //   }
    // }  
    else {
      url = `${loginUrl}${url}`;
    }
    return url;
  }

  /**
   * @description To call interval method based on renew token time provided
   */
  public renewTokenCallOnInterval(isTeamsInitialized: boolean): void {
    this.getToken().subscribe(
      (token) => {
        if (
          token &&
          !isTeamsInitialized &&
          !this.userSessionService.isClouldLabsUser
        ) {
          if (getSilentRenewIntervalTime(token) < 0) {
            this.logout();
          } else {
            this.unsubscribe[this.unsubscribe.length] = interval(
              getSilentRenewIntervalTime(token) * 60 * 1000
            ).subscribe(
              (x) => {
                this.getSilentToken();
              },
              (error) => {
                const code = 'Error - 30021';
                this.eventEmitter.debugAlert(code, error.error);
              }
            );
          }
        }
      },
      (error) => {
        const code = 'Error - 30022';
        this.eventEmitter.debugAlert(code, error.error);
      }
    );
  }

  /**
   * @description To get silent renew token
   */
  private getSilentToken(): void {
    const renewIdTokenRequest = {
      scopes: [this.environment.clientId],
    };

    this.msalService
      .acquireTokenSilent(renewIdTokenRequest)
      .then((response) => {
        if (
          response.idToken.rawIdToken !== '' &&
          response.idToken.rawIdToken !== undefined &&
          response.idToken.rawIdToken !== null
        ) {
          this.setToken(response.idToken.rawIdToken);
        }
      })
      .catch((error) => {
        // if it is an InteractionRequired error, send the same request in an acquireToken call
        if (error instanceof InteractionRequiredAuthError) {
          this.getSilentToken();
        }
      });
  }

  /**
   * @description To generate RSA key pair
   * For now, only generating once for the app
   * Can be changed later to support unique generation for every API call
   */
  public generateRSAKeyPair(): void {
    const rsa = forge.pki.rsa;
    const keypair = rsa.generateKeyPair({ bits: 2048, e: 0x10001 });
    const publicKey = forge.pki.publicKeyToPem(keypair.publicKey).trim();
    const privateKey = forge.pki.privateKeyToPem(keypair.privateKey).trim();
    this.userSessionService.setOriginalKeyPair(publicKey, privateKey);
    this.userSessionService.setKeyPair(
      removeHeaderTrailerFromKey(publicKey),
      removeHeaderTrailerFromKey(privateKey)
    );
    this.updateKey();
  }

  /**
   * @description To update key
   */
  private updateKey(): void {
    const publicKey = this.userSessionService.getKeyPair().public;
    const newKey = updateKey(publicKey);
    this.userSessionService.setModifiedKey(newKey);
  }

  get appType(): string {
    if (this.environment.appType === 'attendee') {
      return 'CloudLabs';
    } else if (this.environment.appType === 'exam') {
      return 'PracticeTest';
    } else return null;
  }

  /**
   * @description To clear local storage and session storage and logout from the app
   * @isPreURLRemove will be true for manual logout from user.
   */
  public logout(isPreURLRemove = false): void {
    localStorage.removeItem('detailReport');
    localStorage.removeItem('courseFlow');
    localStorage.removeItem('isCourse');
    localStorage.removeItem('context');

    localStorage.removeItem('authToken');
    localStorage.removeItem('msal.idtoken');
    localStorage.setItem('isLogginProcessed', '');
    localStorage.removeItem('dummyPath');

    sessionStorage.clear();
    if (isPreURLRemove) {
      localStorage.removeItem('preUrl');
    }
    if(this.environment.appType === 'parental-consent') {
      localStorage.getItem('preUrl').includes('redirecturl=') ? this.returnUrlHandle(window.location.href) : this.returnUrlHandle(localStorage.getItem('redirectUrl'))
    }
    this.msalService.logout();
  }

  private returnUrlHandle(url: string) {
    const splitUrl =  url.split('redirecturl=')
    const returnUrl = splitUrl[1]
    const formatedReturnUrl = decodeURIComponent(returnUrl);
    window.location.href = formatedReturnUrl
  }

  private checkIfInsideTeamsIframe(): boolean {
    return (
      inIframe() &&
      window.name.trim() !== '' &&
      (window.name.trim() === miscellaneousConst.teamsIframeName ||
        window.name.trim() === miscellaneousConst.teamsIframeName2)
    );
  }

  private checkIfNotATeamsIframe(): boolean {
    return (
      inIframe() &&
      window.name.trim() !== '' &&
      window.name.trim() !== miscellaneousConst.teamsIframeName &&
      window.name.trim() !== miscellaneousConst.teamsIframeName2
    );
  }

  /**
   * @description To execute in interval of 2 seconds on app load
   */
  public appExecutionForSetTimeOut(): void {
    if (this.teamsInitialized) {
      this.executeForTeams();
    } else if (this.deviceDetectorService.isMobile() && localStorage.getItem('context') === 'PracticeTest') {
      this.executeForTeams(); // Inside mobile flow then Teams token
    } else if (this.checkIfInsideTeamsIframe()) {
      // embedded-page-container and extension-tab-frame is the iframe name of our app inside Teams web and desktop app respectively
      // Double check and execute code for inside teams flow
      this.executeForTeams();
    } else {
      if (this.environment.appType === 'attendee') {
        this.outSideFlowforAttendee();
      } else if (this.environment.appType === 'exam') {
        this.outSideFlowForPT();
      } else if(this.environment.appType === 'parental-consent') {
        this.parentalConsentFlow();
      }
    }
    this.initializeTheme();
    if (this.environment.appType === 'exam') { /* to Stop the loader called from the PT's app.component.ts */
      this.loadingService.loadingOff();
    }
  }

  private executeForTeams(): void {
    microsoftTeams.appInitialization.notifyAppLoaded();
    this.microsoftAppAuth();
    localStorage.setItem('isTeamapp', 'true');
    this.userSessionService.isTeamsAppUser = true;
  }

  private checkForExternalFlowCondition(): boolean {
    return (
      window.location.href.includes('?regUniqueName') ||
      isExternalFlowRegUniqueName(this.params) ||
      window.location.href.includes('/download')
    );
  }

  private checkForPureBrowser(): boolean {
    return !this.environment.production || this.environment.production;
  }

  /**
   * @description Only process for outside flow and not Teams app flow
   */
  private outSideFlowForPT(): void {
    if (this.checkIfNotATeamsIframe()) {
      if (this.checkForExternalFlowCondition()) {
        this.setTokenForExternal('external token');
        this.userSessionService.isClouldLabsUser = true;
      }
    } else if (this.checkForExternalFlowCondition()) {
      this.setTokenForExternal('external token');
      this.userSessionService.isClouldLabsUser = true;
    } else if (this.checkForPureBrowser()) {
      localStorage.setItem('isTeamapp', 'false');
      this.isMicrosoftAppAuth();
      this.userSessionService.isLocalDevUser = true;
      this.renewTokenCallOnInterval(this.teamsInitialized);
    } else {
      this.isUser = false;
    }
  }
  /**
   * @description Only process for outside flow and not Teams app flow
   */
  private outSideFlowforAttendee(): void {
    const url = localStorage.getItem('preUrl');
    if (url) {
      // Commenting for CLOUD-119
      // this.auth.login();
    } else {
      this.renewTokenCallOnInterval(this.teamsInitialized);
    }
  }

  /**
   * @description Only process for parental consent flow
   */
   private parentalConsentFlow(): void {
    const url = localStorage.getItem('preUrl');
    if (url) {
      if (!window.location.href.includes('grant-consent') ) {
        // const count = sessionStorage.getItem('pc-login-counter')
        // if (count === null || parseInt(count) < 2) {
        //   const c  = count ==null ? 1: parseInt(count)+1;
        //   sessionStorage.setItem('pc-login-counter', c.toString())
        if (localStorage.getItem('msal.idtoken')){
          // Skip a flow
        } else {
          this.login();
        }
      }
    } else {
      this.renewTokenCallOnInterval(this.teamsInitialized);
    }
  }

  /**
   * @description To initialize and set default theme for the user
   */
   private initializeTheme(): void {
     if (this.userSessionService.isTeamsAppUser) {
      this.userSessionService.currentTheme = 'default';
      this.document.body.classList.add('theme-default');
      /* add default theme color variables */
      setStyleColours(4);
      document.documentElement.style.setProperty('--font-family','Segoe UI')
    }
    localStorage.setItem('theme', this.userSessionService.currentTheme);
    this.document.body.classList.add('theme-blue');
  }

  /**
   * microsoft/teams login
   */

  public microsoftAppAuth(): void {
    microsoftTeams.registerOnThemeChangeHandler((theme) => {
      setTheme(theme);
    });

    function setTheme(theme: any): void {
      if (theme) {
        // DO NOT ADD THEME BECAUSE WE ARE PROVIDING OUR OWN TEAMS THEME
      }
    }
    const authTokenRequest = {
      successCallback: (result) => {
        localStorage.setItem('isLogginProcessed', '');
        this.setToken(result);
        microsoftTeams.appInitialization.notifySuccess(); // important in case of "showLoadingIndicator": true in manifest file
        localStorage.setItem('msteam', '1');
      },

      failureCallback: (error) => {
        console.log('Microsoft login error', error);
        microsoftTeams.appInitialization.notifySuccess();
        window.location.reload();
      },
    };
    microsoftTeams.getContext((context) => {
      if (context) {
        if (context.theme) {
          setTheme(context.theme);
        }
        if (context.locale !== undefined && context.locale !== '') {
          this.translateService.use(
            context.locale.match(/es|es-ES/) ? 'es' : 'en'
          );
          this.userSessionService.defaultLanguage.value = context.locale.match(
            /es|es-ES/
          )
            ? 'es'
            : 'en';
        }
      }
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    });
  }

  public isMicrosoftAppAuth(): void {
    const isLogin = localStorage.getItem('authToken');
    const isLogginProcessed = localStorage.getItem('isLogginProcessed');
    if (isLogginProcessed !== '1') {
      if (
        isLogin === '' ||
        isLogin == null ||
        !isLogin ||
        getSilentRenewIntervalTime(isLogin) < 0
      ) {
        this.msalService.loginRedirect();
        localStorage.setItem('context', 'PracticeTest');
        localStorage.setItem('isLogginProcessed', '1');
      } else {
        this.setToken(isLogin);
      }
    }

    this.isIframe = window !== window.parent && !window.opener;

    this.unsubscribe[this.unsubscribe.length] = this.broadcastService.subscribe(
      'msal:loginSuccess',
      (res) => {
        localStorage.setItem('isLogginProcessed', '');
        localStorage.setItem('authToken', res.idToken.rawIdToken);
        this.setToken(res.idToken.rawIdToken);
        this.isSession = 1;
        const date = new Date();
        date.setTime(date.getTime() + 1810 * 1000);
        document.cookie =
          'sessionValue=' +
          this.isSession +
          ';' +
          'expires=' +
          date.toUTCString();
      }
    );

    this.unsubscribe[this.unsubscribe.length] = this.broadcastService.subscribe(
      'msal:loginFailure',
      (error) => {
        console.log('Login Fails:', error);
      }
    );

    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });
  }

  public initialSubscription(){
    const token = localStorage.getItem('msal.idtoken');
    if (token) {
      this.getParentConsentStatus().then((response: any) => {
        if (response?.RequiresParentalConsent === true ) {
          if (response?.ConsentStatus === 1 || response?.ConsentStatus === 2 || response?.ConsentStatus === 3 || response?.ConsentStatus === 6 || response?.ConsentStatus === 7) {
            setTimeout(() => {
              // localStorage.setItem('currentUrl', window.location.href)
              this.router.navigate([
                'request-consent'
              ]);
            }, 100);
          }
        } else {
          this.loadCloudLabsPortal()
        }
      }).catch(() => this.loadingService.loadingOff());
    }
  }

  private loadCloudLabsPortal() {
    const token = localStorage.getItem('msal.idtoken');
    if (token) {
      this.getRequest().then((response) => {
        // emit the menu items globally to prevent duplicate API call
        this.attendeeMenu.next(response);
        if (
          ['/', '/home', '/request-consent'].includes(this.router['location']._platformLocation.location.pathname)
        ) {
          setTimeout(() => {
            this.router.navigate([
              // TODO Need to change this back to catalog after API changes fix
              // response.HasOngoingLearning ? 'my-learning' : 'catalog',
              'my-learning'
            ]);
          }, 100);
        }
      }).catch(() => this.loadingService.loadingOff());
    }
  }

  getRequest() {
    return this.http.get<any>(`${this.baseUrl}Menu/GetAttendeeMenu`).toPromise();
  }

  getParentConsentStatus() {
    return this.http.get(`${this.baseUrl}ParentConsent/GetParentConsentStatus`).toPromise();
  }

  getAttendeeMenu(){
    return this.attendeeMenu.asObservable();
  }

  public destroySubscription() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
