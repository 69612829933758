import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'teams-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  /**
   * Holds the current value of the input
   */
  @Input() id = '';
  @Input() value = '';
  @Input() placeholder = '';
  @Input() classList = '';
  @Input() ngClassList = '';
  @Input() size = 25;
  @Input() type = 'text';
  @Input() title = '';
  @Input() maxlength = '';
  @Input() pattern: string;
  @Input() required: boolean;
  @Input() autocomplete: 'off' | 'on';
  @Input() disabled: boolean;
  @Input() isDirective = false;
  @Input() ariaLabel: string;
  @Input() isReadOnly = false;
  @Input() ariaDescribedBy: string;
  @Input() ariaLabeledBy: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }
  /**
   * Invoked when the model has been changed
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges(value: string) {
    this.value = value;
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value): void {
    this.value = value;
    if (value !== '' && value !== null) {
      this.updateChanges(this.value);
    }
  }

  /**
   * @description When user copy paste using keyboard or mouse right click
   */
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.updateChanges(event.clipboardData.getData('text') ? event.clipboardData.getData('text') : '');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFocusOut() {}

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
