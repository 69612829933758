import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appWhiteSpaceValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: WhiteSpaceValidatorDirective, multi: true}]
})
export class WhiteSpaceValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors|null {
    let val= control.value;
    if(val){
      val = val.trim();
      let len = val.length;

      if(len === 0){
        return {'isEmpty': true};
      }
    }
  }
}