<header class="container-fluid" role="banner">
  <nav class="navbar navbar-expand-md navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/home']">
        <img src="../../assets/images/{{logosrc}}" alt="cloudLabs logo" width="200"
          aria-label="cloudLabs" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll"
        aria-controls="navbarScroll" aria-expanded="true" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse cap" id="navbarScroll">
        <ul role="listbox" class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px">
          <!-- <li class="nav-item" role="listitem">
            <a class="nav-link active" aria-current="Login" href="#">Login</a>
          </li> -->
          <li class="nav-item" role="listitem">
            <a class="nav-link" aria-label="contact us" target="_blank" href="{{contactus}}">CONTACT US</a>
          </li>
          <li class="nav-item" role="listitem">
            <a class="nav-link" aria-label="support" target="_blank" href="mailto:{{supportEmail}}">SUPPORT</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<div id="main-section">
  <div class="container-fluid">
    <div class="text-content">
      <h1 class="title" *ngIf="environment.customer === customerType.DATABRICKS else otherHeader">Databricks Labs</h1>
      <ng-template #otherHeader>
        <h1 class="title">CloudLabs</h1>
      </ng-template>
      <h2 id="changing-text" class="text"></h2>
    </div>
  </div>
</div>

<div id="info-bar" class="container-fluid">
  <div class="row" role="row" aria-label="info section">
    <div class="col-md-4 col-12 outer-box">
      <span class="avatar">
        <img aria-label="test drive img" src="../../assets/images/test-logo.svg" alt="test " />
      </span>
      <div class="text-content">
        <h3 class="title" role="text">Test Drive</h3>
        <p class="text" role="text">
          Explore, test and demo the lab environments of your favourite
          solutions.
        </p>
      </div>
    </div>
    <div class="col-md-4 col-12 outer-box">
      <span class="avatar">
        <img aria-label="schedule img" src="../../assets/images/schedule-logo.svg" alt="schedule " />
      </span>
      <div class="text-content">
        <h3 class="title" role="text">Schedule</h3>
        <p class="text" role="text">
          Found what you like? Schedule your workshops and lab environment
          requirements.
        </p>
      </div>
    </div>
    <div class="col-md-4 col-12 outer-box">
      <span class="avatar">
        <img aria-label="manage img" src="../../assets/images/manage-logo.svg" alt="manage " />
      </span>
      <div class="text-content">
        <h3 class="title" role="text">Manage</h3>
        <p class="text" role="text">
          Easy to use HOL Management platform where you can also manage
          attendees, view reports, control the lab environments, and much more.
        </p>
      </div>
    </div>
  </div>
</div>

<app-footer class="appfooter" [showSupported]="false"></app-footer>