//Modules
export * from './lib/common-lab.module';
export * from './lib/labs/labs.module';
export * from './lib/shared/pagination/pagination.module';
export * from './lib/labenvironment/labenvironment.module';
export * from './lib/shared/shared.module';

// components
export * from './lib/shared/vm-dialog/vm-dialog.component';
export * from './lib/shared/survey-form/survey-form.component';
export * from './lib/shared/toggle-header/toggle-header.component';
export * from './lib/shared/notes/notes.component';
export * from './lib/shared/pagination/pagination-controls.component';
export * from './lib/shared/auto-complete/auto-complete.component';
export * from './lib/shared/doc-guide/doc-guide.component';
export * from './lib/shared/virtual-machine/virtual-machine.component';
export * from './lib/shared/lab-validation/lab-validation.component';
export * from './lib/shared/lab-environment/lab-environment.component';
export * from './lib/shared/lab-guide/lab-guide.component';
export * from './lib/shared/lession/lession.component';
export * from './lib/shared/header/header.component';
export * from './lib/shared/theme/theme.component';
export * from './lib/shared/footer/footer.component';
export * from './lib/shared/trial-environment/trial-environment.component';
export * from './lib/shared/trial-resource/trial-resource.component';
export * from './lib/vm/vm.component'
export * from './lib/base-registration/base-registration.component'
export * from './lib/shared/refined-header/refined-header.component'
export * from './lib/shared/environment-deallocate-dialog/environment-deallocate-dialog.component'

//directives
export * from './directives/progress-time.directive';
export * from './directives/debouce-click.directive';
export * from './directives/arrow-keys-accessible.directive';
export * from './lib/shared/pagination/pagination-controls.directive';
export * from './directives/auto-focus-modal.directive';
export * from './directives/focus-invalid-input.directive';
export * from './directives/lab-focus.directive';
export * from './directives/refocus-previous-el.directive';
export * from './directives/remove-focus-ok-button.directive';
export * from './directives/remove-focus-on-press.directive';
export * from './directives/restart-dialog.directive';
export * from './directives/role-dialog.directive';
export * from './directives/trap-focus.directive';
export * from './directives/vm-role.directive';
export * from './directives/white-space-validator.directive';

//pipes
export * from './pipes/time-transform.pipe';
export * from './pipes/sanitize-html.pipe';
export * from './pipes/calculate-duration.pipe';
export * from './lib/shared/pagination/paginate.pipe';
export * from './pipes/format-time.pipe'

//resolvers
export * from './resolver/attende-lab.resolver';
export * from './resolver/environment.resolver';
export * from './resolver/lab-resolver';
export * from './resolver/profile.resolver';
export * from './resolver/vmurl.resolver';

export * from './lib/remote/components/index' //remote module and components

//Service
export * from './services/notification.service'
