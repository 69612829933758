import {Component, Input, OnInit} from '@angular/core';
import {NotesService} from '../../../../services/notes.service';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() note;

  constructor(private notesService: NotesService) { }

  ngOnInit(): void {
  }

  clearNote() {
    this.notesService.removeNote(this.note.id);
  }
}
