/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl;
  token = localStorage.getItem('msal.idtoken');
  constructor(
    private http: HttpClient,
    @Inject('environment')
    environment
  ) {
    this.baseUrl = environment.apiUrl;
  }

  // getRequest(link) {
  //   return this.http.get<any>(this.baseUrl + link).toPromise();
  // }
  getRequest() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    };
    return this.http
      .get<any>(`${this.baseUrl}/Menu/GetAttendeeMenu`, { headers: headers })
      .toPromise();
  }
  postRequest(link, data) {
    return this.http.post<any>(this.baseUrl + link, data).toPromise();
  }

  patchRequest(link, data) {
    return this.http.patch<any>(this.baseUrl + link, data).toPromise();
  }

  deleteRequest(link) {
    return this.http.delete<any>(this.baseUrl + link).toPromise();
  }

  getDownload(link, bodyPayload: any) {
    return this.http
      .post(this.baseUrl + link, bodyPayload, {
        responseType: 'blob',
      })
      .toPromise();
  }

  getDownloadRequest(link) {
    return this.http
      .get(this.baseUrl + link, {
        responseType: 'blob',
      })
      .toPromise();
  }

  getRequestWithQueryData(link, data) {
    return this.http
      .get<any>(this.baseUrl + link, {
        params: data,
      })
      .toPromise();
  }

  postFileRequest(link, data) {
    const req = new HttpRequest('POST', this.baseUrl + link, data, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  redeemVoucher(voucherCode, id) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
      loginUserInternalId: id,
    };
    return this.http
      .get(`${this.baseUrl}/AECUser/RedeemVoucher/${voucherCode}`, {
        headers: headers,
      })
      .toPromise();
  }

  getProfile() {
    const token = localStorage.getItem('msal.idtoken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return this.http
      .get<any>(`${this.baseUrl}/AECUser/attendeeprofile`, { headers: headers })
      .toPromise();
  }

  upadateProfile(data, id) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
      loginUserInternalId: id,
    };
    return this.http
      .put<any>(`${this.baseUrl}/AECUser/UpdateAttendeeProfile`, data, {
        headers: headers,
      })
      .toPromise();
  }

  getAttendeeEvents(id) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
      loginUserInternalId: id,
    };
    return this.http
      .get<any>(`${this.baseUrl}/AECUser/GetAttendeeEvents`, {
        headers: headers,
      })
      .toPromise();
  }
}
