import { Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { EventService } from '../../../services/event.services';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../services/notification.service';
import { Router } from '@angular/router';
import { miscellaneousConst } from '@utility';

@Component({
  selector: 'cloudlabs-toggle-header',
  templateUrl: './toggle-header.component.html',
  styleUrls: ['./toggle-header.component.scss'],
})
export class ToggleHeaderComponent implements OnDestroy{
  @Input() labTitle: any;
  toggle = true;
  appType;
  showExpand = true;
  @ViewChild('toggle') toggleContent:ElementRef
  isFullScreen = false;
  hidingAlertMessages = false;
  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private router: Router,
    @Inject('environment')
    environment
  ) {
    this.appType = environment.appType;
    const isTeamsApp = JSON.parse(localStorage.getItem('isTeamapp'));
    if (
      isTeamsApp &&
      isTeamsApp !== undefined &&
      isTeamsApp !== null &&
      isTeamsApp !== false
    ) {
      this.showExpand = false;
    }
  }

  hideShow() {
    this.hidingAlertMessages = true;
    this.isFullScreen = !this.isFullScreen;
    setTimeout(()=>{
      this.hidingAlertMessages = false;
      this.toggleContent.nativeElement.focus();
    },500)
    this.eventService.emmitToggleHeader();
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      const el = document.getElementsByClassName('toggle-hide');
      const ktel = document.getElementById('kt_wrapper');
      const aside = document.getElementById('kt_aside');
      if (this.toggle) {
        el[0].classList.add('toggle-screen');
        el[1].classList.add('toggle-screen');
        ktel.classList.add('toggle-fullscreen');
        aside.classList.add('remove-aside');
        this.toggle = !this.toggle;
      } else {
        el[0].classList.remove('toggle-screen');
        el[1].classList.remove('toggle-screen');
        ktel.classList.remove('toggle-fullscreen');
        aside.classList.remove('remove-aside');
        this.toggle = !this.toggle;
      }
    }
  }

  copyToClipBoard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.success(
      this.translateSrv.instant('MESSAGE_COPIED'),
      null
    );
  }

  ngOnDestroy(): void {
    if (!this.toggle) {
      this.hideShow();
    }
  }
}
