export const miscellaneousConst = {
  renewTokenTime: 56,
  encryptCloudLabs: 'labscloud',
  elapsedTime: 'Elapsed time',
  labStatusDeploying: 'Deploying',
  labStatusSucceeded: 'Succeeded',
  labStatusDeleting: 'Deleting',
  labStatusDeleted: 'Deleted',
  performanceQuizSectionType: 'Performance Quiz',
  performanceQuizwithVM: 'Performance Quiz with VM',
  caseStudySectionType: 'Case Study',
  freeFlowSectionType: 'Free Flow',
  performanceTask: 'Performance Task',
  azurePortalUrl: 'https://portal.azure.com',
  arrayGreaterValue: 10,
  answeredArrayMinLength: 0,
  arrayOneValue: 1,
  http: 'https://',
  domainNotAllowed: 'Domain is not allowed to take test',
  mobileViewPortSize: 700,
  actions: {
    continue: 'Continue',
    take: 'Take'
  },
  userFlows: {
    multipleSectionQuestionInProgress: 'multipleSectionQuestionInProgress',
    firstSectionOfFirstQuestion: 'firstSectionOfFirstQuestion',
    firstQuestionOfFirstSection: 'firstQuestionOfFirstSection',
    externalUserFlow: 'externalUserFlow',
    jumpToInprogressQuestionExternalUserElse: 'jumpToInprogressQuestionExternalUserElse'
  },
  dialogeHeader: {
    feedback: 'Feedback Form',
    confirmation: 'Confirmation',
    examCompleted: 'Exam Completed',
    helpSection: 'Help Section',
    testDetails: 'Test Details',
    completeSection: 'Section Review'
  },
  navigation: {
    tests: 'tests',
    testInstance: 'testInstance',
    section: 'section',
    sections: 'sections',
    questions: 'questions',
    detail: 'detail',
    results: 'results',
    review: 'review',
    myLearning: 'my-learning',
    practiceTests: 'practice-tests',
    test: 'test',
    courses: 'courses',
    practiceTest: 'practice-test',
    learningPath: 'learning-paths'
  },
  download: {
    windowsLink: 'https://cloudlabsai.blob.core.windows.net/practicetest/CloudLabs-Setup-1.0.0.exe',
    macLink: 'https://cloudlabsai.blob.core.windows.net/practicetest/CloudLabs-Setup-1.0.0.dmg'
  },
  language: {
    defaulLang: {
      value: 'en',
      label: 'English'
    },
    Spanish: 'Spanish',
    Español: 'Español'
  },
  languages: {
    en: 'en',
    es: 'es',
    loadTestEn: 'Loading Practice Tests...',
    loadTestEs: 'Creando prueba...'
  },
  completedTestHeader: {
    practicetest : 'Practice Tests',
    completedTest : 'Completed Tests'
  },
  CloudLabs: {
    practiceTestAttempts: {
      passedStatus: 'Passed',
      failedStatus: 'Failed'
    },
    events: {
      practiceTest: 'PracticeTest',
      course: 'Course',
      lab: 'Lab',
      lecture: 'Lecture'
    }
  },
  productType: {
    certificationExam: 'Certification Exam',
    actualTest: 'Actual Test',
    practiceTest: 'Practice Test'
  },
  lectures: {
    video: {
      youtube: 'youtube',
      youtu: 'youtu.be',
      vimeo: 'vimeo',
      embed: 'embed',
      youtubeLink: 'https://www.youtube.com/embed/',
      vimeoLink: 'https://player.vimeo.com/video/',
      autoplay: '&autoplay='
    }
  },
  catalog: {
    lab: 'Lab',
    course: 'Course'
  },
  mobileQueryParam: '?IsMobileResponsive=',
  teamsIframeName: 'embedded-page-container',
  teamsIframeName2: 'extension-tab-frame',
  errorMsg: 'Oops! We could not process the last request. Please try again. If the issue persists, please contact the administrator with this error ID.',
  supportContact: 'cloudlabs-support@spektrasystems.com',
  cloudlab: 'CloudLabs',
  entitiesOrder: ['Courses', 'Learning Paths', 'Labs', 'Practice Tests', 'Lectures'],
  SUCCESS: 'SUCCESS',
  learningEntity: {
    courses: "Course",
    lab: "Lab",
    lecture: "Lecture",
    practiceTest: "PracticeTest",
    learningPath: "LearningPath"
  }
};


