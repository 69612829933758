import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../../services/event.services';
import {User} from '../../models/user.model';
import {TranslateCacheService} from 'ngx-translate-cache';
import {Subject} from 'rxjs';
import {Language} from '../../../services/modals/language.modal';
import {UtilityService} from '../../../services/utility.service';
import {Lab} from '../../models/lab.model';
import {LabCluster} from '../../../services/modals/lab-cluster.modal';
import { detect } from 'detect-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  @Output() openSideNavbar = new EventEmitter<string>();
  @Input() menu: boolean;
  @Input() customLogo: string;
  @Input() lab: Lab | LabCluster;
  public showLanguage: string;
  public labuser: User;
  public browser = detect()
  public languages: Language[];
  public languageAriaLabel : string;
  public IsEdge:boolean;
  constructor(
    public translate: TranslateService,
    public translateCacheService: TranslateCacheService,
    public eventServ: EventService,
    private util: UtilityService
  ) {
    this.getUserDetail();
  }

  async ngOnInit() {
    this.languages = await this.util.getLanguages().toPromise();
    this.languageAriaLabel = this.languages.find(i=> i.Code == (localStorage.getItem('language') || 'en')).Name ;
    this.showLanguage = localStorage.getItem('language') || 'en';
    this.translate.use(this.showLanguage);
    this.translateCacheService.init();
    this.eventServ.languageChanged.subscribe((lang) => {
      this.showLanguage = lang;
    });
    this.switchLanguage(this.showLanguage);
    if (this.browser.name.includes('edge')){
      this.IsEdge = true;
    }else{
      this.IsEdge = false;  
    }
  }

  /**
   * Change language switcher
   * @param language
   */
  public switchLanguage(language) {
    document.getElementsByTagName('html')[0].setAttribute('lang',language);
    this.languageAriaLabel = this.languages.find(i=> i.Code == language).Name;
    this.util.switchLanguage(language);
    if (this.lab) {
      const key = `lab_language_${this.lab.UniqueName}`;
      localStorage.setItem(key, language);
    }
  }

  getUserDetail() {
    let user = localStorage.getItem('labuser');
    if (user) {
      this.labuser = JSON.parse(user);
    }
  }

  openSideNav() {
    this.eventServ.onMenuClick();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
