<app-header [menu]="true"></app-header>
<div class="container pt-4">
  <div class="row">
    <div class="col-md-12">
        <h3>Learn and certify - Azure fundamentals (Az-900)</h3>
        <p>By: Microsoft</p>
    </div>
  </div>
  <div class="row">
      <div class="col-md-3" *ngFor="let item of lessions">
        <app-lession [lession]="item"></app-lession>
      </div>
  </div>
</div>
