import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { LOADING_BAR_CONFIG, LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SharedModule } from './shared/shared.module';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CourseModule } from './course/course.module';
import { ErrorComponent } from './error/error.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpErrorInterceptor } from './shared/interceptor/http-error.interceptor';
import { AppConfigService } from './services/app.config.services';
import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { CountdownModule } from 'ngx-countdown';

import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';
import { ClipboardModule } from 'ngx-clipboard';
import { MultiWindowModule } from 'ngx-multi-window';
import { LabClusterService } from './services/lab-cluster.service';
import {
  TranslateCacheModule,
  TranslateCacheSettings,
  TranslateCacheService,
} from 'ngx-translate-cache';
import { HomeModule } from './home/home.module';
import { environment } from '../environments/environment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CommonLabModule } from '@common-lab';

export function appConfigService(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

/**
 * markdown file links target to blank pages
 */
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(
      /^<a /,
      '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" '
    );
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [AppComponent, ErrorComponent],

  imports: [
    MultiWindowModule.forRoot({
      keyPrefix: 'cloudlabs_mw_',
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CourseModule,
    ClipboardModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    CountdownModule,
    HomeModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: (translateService, translateCacheSettings) => {
          return new TranslateCacheService(
            translateService,
            translateCacheSettings
          );
        },
        deps: [TranslateService, TranslateCacheSettings],
      },
    }),
    CommonLabModule,
  ],
  exports: [TranslateModule],
  providers: [
    AppConfigService,
    LabClusterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigService,
      multi: true,
      deps: [AppConfigService],
    },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    // for accessing environment variables in labs
    { provide: 'environment', useValue: environment },
    { provide: 'googleTagManagerId', useValue: environment.GTMID },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
