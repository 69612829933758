import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from '../../../services/event.services';
import { LabService } from '../../../services/lab.service';

@Component({
  selector: 'cloudlabs-resource-action-dialog',
  templateUrl: './resource-action-dialog.component.html',
  styleUrls: ['./resource-action-dialog.component.scss']
})
export class ResourceActionDialogComponent implements OnInit {
 
  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router,
    public labSrv: LabService) {

     }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalRef.hide();
    }, 115000);
  }

  async onConfirm(){
    this.eventSrv.onResourceDialog('Yes');
    this.modalRef.hide();
  }
  onClose(){
    this.eventSrv.onResourceDialog('No');
    this.modalRef.hide();
  }

}
