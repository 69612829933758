import { Component, Inject, OnInit } from '@angular/core';
import { NotesService } from '../../../services/notes.service';
import { CustomerType } from '@libs/common-lab/src/modals/lab.model';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  notes;

  constructor(
    private notesService: NotesService,
    @Inject('environment') private environment
  ) {}

  ngOnInit(): void {
    this.notesService.notesObserver.subscribe((data) => {
      this.notes = data;
    });
    this.notesService.clearAllNotes();
  }
}
