import { NgModule, SecurityContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabenvironmentRoutingModule } from './labenvironment-routing.module';
import { EnvironmentComponent } from './components/environment/environment.component';
import { SharedModule } from '../shared/shared.module';
import { NgxRemoteDesktopModule } from '../remote';
import { AngularDraggableModule } from 'angular2-draggable';
import { LabguideComponent } from './components/labguide/labguide.component';
import { MultiWindowModule } from 'ngx-multi-window';
import { TeamsUiModule } from '@teams-ui-angular';
import { VmComponent } from '../vm/vm.component';
import { TranslateModule } from '@ngx-translate/core';
import { UtilityModule } from '@utility';
import { DatabricksLayoutComponent } from './components/environment/databricks-layout/databricks-layout.component';

@NgModule({
  declarations: [
    EnvironmentComponent,
    LabguideComponent,
    VmComponent,
    DatabricksLayoutComponent,
  ],
  imports: [
    CommonModule,
    LabenvironmentRoutingModule,
    NgxRemoteDesktopModule,
    SharedModule,
    UtilityModule,
    MultiWindowModule.forRoot({
      keyPrefix: 'cloudlabs_mw_',
    }),
    AngularDraggableModule,
    TeamsUiModule,
    TranslateModule,
  ],
  providers: [],
  exports: [
    VmComponent,
  ],
})
export class LabenvironmentModule {}
