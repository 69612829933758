import { Component } from '@angular/core';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent {

  lessions = [
    { id: 1, image: '/assets/images/lession.png', duration : '1 hours', progress: 10, tag: 'Az900', desc: 'Practice exam-question Certification Exam', title: 'In Progress', time: '00:45:30' },
    { id: 2, image: '/assets/images/lession.png', duration : '2 hours', progress: 60, tag: 'Az900', desc: 'Practice exam-question Certification Exam', title: 'In Progress', time: '00:20:30' },
    { id: 3, image: '/assets/images/lession.png', duration : '', progress: 30, tag: 'Az900', desc: 'Practice exam-question Certification Exam', title: 'In Progress', time: '01:15:30' },
    { id: 4, image: '/assets/images/lession.png', duration : '', progress: 90, tag: 'Az900', desc: 'Practice exam-question Certification Exam', title: 'In Progress', time: '00:30:30' },
  ];

}
