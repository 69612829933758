import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NotesService } from '../../../services/notes.service';
import { CustomerType } from '../../../modals/lab.model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cloudlabs-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnDestroy {
  @Input() customInstruction = '';
  @Input() set htmlContent(htmlVal: string) {
    this.htmlString = htmlVal;
    this.setNotesforDB();
  }
  public htmlString = '';
  public notes: any;

  private notesObserverSubscription: Subscription;

  constructor(
    private notesService: NotesService,
    private translateSrv: TranslateService,
    @Inject('environment') private environment
  ) {}

  ngOnInit(): void {
    //this.notesService.clearAllNotes();
    this.notesObserverSubscription = this.notesService.notesObserver.subscribe(
      (data) => {
        this.notes = data;
      }
    );
    if (this.customInstruction && this.customInstruction !== '') {
      this.notesService.addNote(this.customInstruction);
    }
  }

  public setNotesforDB(): void {
    this.notesObserverSubscription = this.notesService.notesObserver.subscribe(
      (data) => {
        this.notes = data;
      }
    );
    if (
      this.htmlString &&
      this.environment.customer === CustomerType.DATABRICKS
    ) {
      this.notesService.addNote(
        this.translateSrv.instant('WITH_EXPERIENCE'),
        this.htmlString
      );
    }
  }

  ngOnDestroy(): void {
    if (this.notesObserverSubscription) {
      this.notesObserverSubscription.unsubscribe();
    }
  }
}
