import { ElementRef } from '@angular/core';
import { Directive, Input, HostBinding, OnInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { detect } from 'detect-browser';

@Directive({
  selector: '[appProgressTime]'
})
export class ProgressTimeDirective implements OnInit {

  private static TIME_PERIOD_FOR_ARIA_LIVE_ASSERTIVE = 500;
  private static TIME_PERIOD_FOR_ONE_INTERVAL= 60000;
  public browser = detect();

  @Input("appProgressTime") remainingTime: any;
  time: number;
  counter:any;
  //  @HostBinding('style.color') role: 'red';


  constructor(
    private el: ElementRef
  ) { }


  ngOnInit() {
    this.time = this.remainingTime.leftTime;
    this.changeAlertLiveAttr();
    this.startCountDown();
  }

  private changeAlertLiveAttr() {
    (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'assertive');
    if(!this.browser.name.includes("edge")){
      (this.el?.nativeElement as HTMLElement).setAttribute('aria-atomic', 'false');
    }
    setTimeout(() => {
      (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'off');
      if(!this.browser.name.includes("edge")){
        (this.el?.nativeElement as HTMLElement).setAttribute('aria-atomic', 'true');
      }
    }, ProgressTimeDirective.TIME_PERIOD_FOR_ARIA_LIVE_ASSERTIVE);
  }
  
  private startCountDown(){
    this.counter = setInterval(() => {
      if(this.time<0){
        this.stopCountDown();
      }
      this.changeAlertLiveAttr();
      this.time -  ProgressTimeDirective.TIME_PERIOD_FOR_ONE_INTERVAL;
    }, ProgressTimeDirective.TIME_PERIOD_FOR_ONE_INTERVAL)
  }
  private stopCountDown(){
    if(this.counter){
      clearInterval(this.counter);
    }
  }
}
