<ng-container *ngIf="isDirective">
    <input *ngIf="type !== 'textarea'" #inputData [id]="id" [title]="title" [type]="type" [value]="value" (blur)='onTouched()'
    [size]="size" [placeholder]="placeholder" [class]="classList" (focusout)="onFocusOut()" (paste)="onPaste($event)" (keyup)="updateChanges(inputData.value)"
    [attr.pattern]="pattern" [attr.maxlength]="maxlength" [required]="required" [attr.autocomplete]="autocomplete"
    [disabled]="disabled" [attr.aria-describedby]="ariaDescribedBy" [attr.aria-labelledby]="ariaLabeledBy" [readonly]="isReadOnly" [ngClass]="ngClassList" [attr.aria-label]="ariaLabel" cloudlabsNumberOnly>
</ng-container>
<ng-container *ngIf="!isDirective">
    <input *ngIf="type !== 'textarea'" [id]="id" #inputData role="textbox" [title]="title" [type]="type" [value]="value" (blur)='onTouched()'
    [size]="size" [placeholder]="placeholder" [class]="classList" (focusout)="onFocusOut()" (paste)="onPaste($event)" (keyup)="updateChanges(inputData.value)"
    [attr.pattern]="pattern" [attr.maxlength]="maxlength" [required]="required" [attr.autocomplete]="autocomplete"
    [disabled]="disabled" [attr.aria-describedby]="ariaDescribedBy" [attr.aria-labelledby]="ariaLabeledBy" [readonly]="isReadOnly" [ngClass]="ngClassList" [attr.aria-label]="ariaLabel">
</ng-container>
<textarea *ngIf="type === 'textarea'" [id]="id" type="textarea" #textData [placeholder]="placeholder"
    (blur)='onTouched()' [class]="classList" [ngClass]="ngClassList" (paste)="onPaste($event)" (keyup)="updateChanges(textData.value)"
    [disabled]="disabled" [attr.aria-label]="ariaLabel" [attr.aria-describedby]="ariaDescribedBy" [attr.aria-labelledby]="ariaLabeledBy" [readonly]="isReadOnly">{{value}}</textarea>
