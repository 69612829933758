import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from './../../../services/event.services';
import { Router } from '@angular/router';
import { LabService } from './../../../services/lab.service';

@Component({
  selector: 'cloudlabs-complete-testdrive-dialog',
  templateUrl: './complete-testdrive-dialog.component.html',
  styleUrls: ['./complete-testdrive-dialog.component.scss']
})
export class CompleteTestdriveDialogComponent implements OnInit {

  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router,
    public labSrv : LabService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalRef.hide();
    }, 89000);
  }

 onConfirm(){
   this.eventSrv.oncompleteDialogDialog();
   this.modalRef.hide();
  }
  onClose(){
    this.modalRef.hide();
  }

}
