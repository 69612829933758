import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from './.././../../services/event.services';
import { Router, ActivatedRoute } from '@angular/router';
import { LabService } from './../../../services/lab.service';
import { EventEmitterService } from '@teams-auth';

@Component({
  selector: 'cloudlabs-extend-duration',
  templateUrl: './extend-duration.component.html',
  styleUrls: ['./extend-duration.component.scss'],
})
export class ExtendDurationComponent implements OnInit {
  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router,
    public labSrv: LabService,
    public aroute: ActivatedRoute,
    private eventEmitter: EventEmitterService
  ) {}

  ngOnInit(): void {
    this.eventSrv.closeduration.subscribe((res) => {
      this.modalRef.hide();
    });
  }

  async onConfirm() {
    this.modalRef.hide();
    try {
      this.labSrv
        .extendLabDurationByAttendee(this.data.v_code, this.data.duration)
        .subscribe(
          (res) => {
            if (res && res == true) {
              setTimeout(() => {
                this.eventSrv.extendduration();
              }, 1000);
              sessionStorage.setItem('duration', this.data.v_code);
            }
          },
          (err) => {
            if (
              err?.error?.ErrorDetail ==
              'You are not allowed to extend the lab now. Please contact CloudLabs support team' ||
              err?.error?.ErrorDetail ==
              `You can extend this lab only upto ${this.data.duration || 15} minutes.`
            ) {
              this.eventSrv.extendDurationError();
            }
            sessionStorage.setItem('cancelduration', this.data.v_code);
            sessionStorage.removeItem('duration');
            const code = 'Error - 70019';
            this.eventEmitter.debugAlert(code, err.error.ErrorDetail);
          }
        );
    } catch (err) {
      if (
        err?.error?.ErrorDetail ==
          'You are not allowed to extend the lab now. Please contact CloudLabs support team' ||
        err?.error?.ErrorDetail ==
          `You can extend this lab only upto ${this.data.duration || 15} minutes.`
      ) {
        sessionStorage.setItem('cancelduration', this.data.v_code);
        sessionStorage.removeItem('duration');
      }
      const code = 'Error - 70020';
      this.eventEmitter.debugAlert(code, err.error.ErrorDetail);
    }
  }

  onCancel() {
    this.eventSrv.extendDurationCancel();
    sessionStorage.setItem('cancelduration', this.data.v_code);
    sessionStorage.removeItem('duration');
    this.modalRef.hide();
  }
}
