<div id="resource-container">

  <div *ngIf="environment.customer === customerType.DATABRICKS; else vmBlock">
    <div class="square-box heading-outer">
      <span class="heading" role="heading" aria-level="2">{{ "TAB_RESOURCES" | translate }}</span>
      <div id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-primary btn-primary refresh text-right"
        tabindex="0" (click)="getDBWorkspaceResouces()" (keyup.enter)="getDBWorkspaceResouces()"
        attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}" style="padding-bottom: 0px;">
        <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 mb-2 refresh-vm"
          [ngClass]="{ rotate: isVMLoading }">
          <span *ngIf="!docActions"> </span>
        </span>
        <span>{{ "BUTTON_REFRESH" | translate }}</span>
      </div>
    </div>

    <div class="vm-group square-box" *ngIf="databriksCluserList.length>0">
      <span class="resources-heading" resources-heading >
        <div class="d-flex heading-box">
          <div class="heading">
            <div class="caption-subject" role="heading" aria-level="2" aria-label="DATABRICKS_CLUSTERS">
              {{ "DATABRICKS_CLUSTERS" | translate }}
            </div>
          </div>
          <p role="heading" aria-level="3" *ngIf="dbUsage && showUpTimeLimit">
            {{'MAXIMUM_UPTIME_LIMIT'| translate}}{{ " (hh:mm) : " + manageTimestamp(dbUsage.UptimeLimit) }}
          </p>
          <p role="heading" aria-level="3" *ngIf="dbUsage && showUpTimeLimit">
            {{'REMAINING_UPTIME'| translate}} {{ " (hh:mm) : " + manageTimestamp(dbUsage.RemainingUptime) }}
            <span id="vm-tooltip" class="info-tooltip" [ngClass]="{ timeReached: dbUsage.RemainingUptime === '00:00' }"
              tooltip="{{ 'RESOURCES_TOOLTIP_1' | translate }}">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          </p>
        </div>
      </span>
      <div class="portlet-body">
        <div class="table-responsive">
          <table aria-label="Lab Resource Table" class="table" tabindex="0">
            <tr>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
              <th>
                {{ "ACTIONS" | translate }}
              </th>
            </tr>
            <tbody>
              <tr *ngFor="let row of databriksCluserList; let i = index">
                <td>
                  {{ row.ResourceName }}
                </td>
                <td>
                  {{dbClusterStatus.includes(row.ResourceStatus) ? row.ResourceStatus : 'UPDATING'}}
                </td>
                <td class="text-left vm-buttons" header-class="'text-left'">
                  <ng-container *ngIf="!row.IsSharedResource">
                    <teams-button [ariaLabel]="'Start'" [titleText]="'START' | translate"
                      id="{{ 'START' | translate }}{{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row,'11')"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                      [disabled]="row.ResourceStatus === 'RUNNING' || row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'FAILED' || !row.ResourceId">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                    </teams-button>
                    <teams-button [ariaLabel]="'Stop'" [titleText]="'STOP' | translate"
                      id="{{ 'STOP' | translate }}{{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row,'12')"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                      [disabled]="row.ResourceStatus === 'TERMINATING' || row.ResourceStatus === 'TERMINATED' || row.ResourceStatus === 'FAILED' || !row.ResourceId">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                    </teams-button>
                  </ng-container>
                  <teams-button [ariaLabel]="'SYNC'" [titleText]="'SYNC' | translate"
                    id="{{ 'SYNC' | translate }}{{ row.ResourceName }}" (buttonClick)="getUpdatedDBClusterStatus(row,i)"
                    [disabled]="!row.ResourceId"
                    [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'">
                    <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-refresh"></i>
                  </teams-button>
                </td>
              </tr>

              <tr *ngIf="databriksCluserList.length<=0">
                <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="sqlPool-section" class="square-box" *ngIf="databriksSQLEPList.length>0">
      <div class="heading-box">
        <h3 class="heading">{{'DB_SQL_EP_HEADER' |translate}}</h3>
      </div>
      <div class="sql-group">
        <div class="portlet-body">
          <div class="table-responsive">
            <table aria-label="Sql Pool Table" class="table" tabindex="0">
              <tr>
                <th>{{ "NAME" | translate }}</th>
                <th>{{ "STATUS" | translate }}</th>
                <th>
                  {{ "ACTIONS" | translate }}
                </th>
              </tr>
              <tbody>
                <tr let *ngFor="let row of databriksSQLEPList; let i = index">
                  <td>
                    {{ row.ResourceName }}
                  </td>
                  <td>
                    {{row.ResourceStatus}}
                  </td>
                  <td class="text-left vm-buttons" header-class="'text-left'">
                    <ng-container *ngIf="!row.IsSharedResource">
                      <teams-button [ariaLabel]="'Start'" [titleText]="'START' | translate"
                        id="{{ 'START' | translate }}{{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row,'13')"
                        [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                        [disabled]="row.ResourceStatus === 'RUNNING'">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                      </teams-button>
                      <teams-button [ariaLabel]="'STOP'" [titleText]="'STOP' | translate"
                        id="{{ 'STOP' | translate }}{{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row,'14')"
                        [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                        [disabled]="row.ResourceStatus === 'PENDING' || row.ResourceStatus === 'STOPPED'">
                        <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                      </teams-button>
                    </ng-container>
                    <teams-button [ariaLabel]="'SYNC'" [titleText]="'SYNC' | translate"
                      id="{{ 'SYNC' | translate }}{{ row.ResourceName }}" (buttonClick)="getUpdatedDBSQLEPStatus(row,i)"
                      [disabled]="!row.ResourceId"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-refresh"></i>
                    </teams-button>
                  </td>
                </tr>
                <tr *ngIf="databriksSQLEPList.length<=0">
                  <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="noResources" class="square-box" *ngIf="databriksSQLEPList.length<=0 && databriksCluserList.length<=0">
      <p>{{ "NO_ACTIVE_RESOURCES" | translate }}</p>
    </div>
  </div>

  <ng-template #vmBlock>
    <div class="square-box heading-outer">
      <span class="heading" role="heading" aria-level="2">{{ "TAB_RESOURCES" | translate }}</span>
      <button id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-primary btn-primary refresh text-right"
        tabindex="0" (click)="loadResources()" (keyup.enter)="loadResources()"
        attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}">
        <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 mb-2 refresh-vm"
          [ngClass]="{ rotate: isVMLoading }">
          <span *ngIf="!docActions"> </span>
        </span>
        <span>{{ "BUTTON_REFRESH" | translate }}</span>
      </button>
    </div>
    <!-- VM labs section started -->
    <div class="vm-group square-box">
      <span class="resources-heading" resources-heading *ngIf="vmUsage">
        <div class="d-flex heading-box">
          <div class="heading">
            <div class="caption-subject" role="heading" aria-level="3" aria-label="TAB VIRTUAL MACHINES">
              {{ "TAB_VIRTUAL_MACHINES" | translate }}
            </div>
          </div>
          <p role="heading" aria-level="4" *ngIf="attendeLabDetails.ComputeVMUsage && showUpTimeLimit">
            {{'MAXIMUM_UPTIME_LIMIT'| translate}}{{ " (hh:mm) : " + upTimeLimit }}
          </p>
          <p role="heading" aria-level="4" *ngIf="showUpTimeLimit">
            {{'REMAINING_UPTIME'| translate}} {{ " (hh:mm) : " + remaining_UpTime }}
            <span id="vm-tooltip" class="info-tooltip" [ngClass]="{ timeReached: remaining_UpTime === '00:00' }"
              tooltip="{{ VMtooltipText | translate }}">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>
          </p>
        </div>
      </span>
      <!-- virtual box section -->
      <div class="portlet-body">
        <div class="table-responsive">
          <table aria-label="Lab Resource Table" class="table" tabindex="0">
            <tr>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
              <th class="hide-column">{{ "DNS_NAME" | translate }}</th>
              <th class="hide-column">IP Address</th>
              <!-- <th *ngIf="attendeLabDetails.ComputeVMUsage">
              {{ "UP_TIME" | translate }}{{ " " }}(hh:mm)
            </th> -->
              <th *ngIf="attendeLabDetails.EnableResourceOperations">
                {{ "ACTIONS" | translate }}
              </th>
            </tr>
            <tbody *ngIf="vmUsage">
              <tr *ngFor="let row of vmUsage.ResourceList">
                <ng-container *ngIf="!row.IsDynamicRG">
                  <td>
                    <div class="d-flex align-items-center vm-name">
                      <i alt="" role="img" aria-label="img" aria-hidden="true" class="mr-2"
                      [ngClass]="row.ResourceType === 'Microsoft.Compute/virtualMachines' ? 'fas fa-desktop' : 'fas fa-server' "></i> 
                      <span>
                        {{ row.ResourceName }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span *ngIf="row.Status === '' || row.Status === null">{{
                      "UPDATING_STATUS" | translate
                      }}</span>
                    <span *ngIf="row.Status !== '' && row.Status !== null">{{
                      row.Status
                      }}</span>
                    <span *ngIf="
                  vmUsage.UptimeLimit !== null &&
                  vmUsage.RemainingUptime !== null &&
                  vmUsage.RemainingUptime === '00:00:00' &&
                  (row.Status === 'VM deallocated' ||
                  row.Status === 'Deallocating' ||
                  row.Status === 'VM deallocating')
                  ">-&nbsp;{{
                      "RUNTIME_LIMIT_EXHAUSTED_STATUS" | translate
                      }}</span>
                  </td>

                  <td *ngIf="!docActions" class="hide-column" style="word-break: break-word">
                    {{ row.DNSName }}
                    <a *ngIf="row.DNSName" role="button" ngxClipboard tooltip="{{'COPY' | translate}} DNS Name"
                    [cbContent]="row.DNSName" class="" href="javascript:void(0)"
                    attr.aria-label="{{'COPY' | translate}} username" (click)="copyContent()">
                    <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                    </a>
                  </td>

                  <td class="hide-column" style="word-break: break-word">
                    {{ row.PublicIpAddress }}
                    <a *ngIf="row.PublicIpAddress" role="button" ngxClipboard tooltip="{{'COPY' | translate}} IP Address"
                    [cbContent]="row.PublicIpAddress" class="" href="javascript:void(0)"
                    attr.aria-label="{{'COPY' | translate}} username" (click)="copyContent()">
                    <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                    </a>
                  </td>
                  <!-- <td *ngIf="attendeLabDetails.ComputeVMUsage">
                {{ getUpTime(row) }}
              </td> -->
                  <td class="text-left vm-buttons" header-class="'text-left'"
                    *ngIf="attendeLabDetails.EnableResourceOperations">
                    <teams-button [ariaLabel]="'Start'" [tooltip]="'START' | translate" id="{{ 'START' | translate }}
                  {{ row.ResourceGroupName }}
                  {{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row, '7')"
                      [classList]="'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'"
                      [disabled]="
                  (['VM running','running', 'terminated']).includes(row.Status) ||
                  (attendeLabDetails.ComputeVMUsage === true &&
                  vmUsage.UptimeLimit !== null &&
                  vmUsage.RemainingUptime !== null &&
                  vmUsage.RemainingUptime === '00:00:00' )">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                    </teams-button>
                    <teams-button [ariaLabel]="'Restart'" [tooltip]="'RESTART' | translate" id="{{ 'RESTART' | translate }}{{ row.ResourceGroupName }}{{
                    row.ResourceName
                  }}" (buttonClick)="virtualMachineOperations(row, '9')"
                      [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                      [disabled]="
                    !(['VM running','running']).includes(row.Status) ||
                    (row.Status !== null &&
                      attendeLabDetails.ComputeVMUsage === true &&
                      vmUsage.UptimeLimit !== null &&
                      vmUsage.RemainingUptime !== null &&
                      vmUsage.RemainingUptime === '00:00:00' )
                  ">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-sync-alt"></i>
                    </teams-button>
                    <teams-button [ariaLabel]="'Deallocate'" [tooltip]="'DEALLOCATE' | translate" id="deallocate{{ row.ResourceGroupName }}{{
                    row.ResourceName
                  }}" (buttonClick)="virtualMachineOperations(row, '8')"
                      [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                      [disabled]="
                    (['VM deallocated','VM deallocating','deallocated', 'deallocating', 'Stopping', 'stopped', 'terminated']).includes(row.Status) ||
                    (row.Status !== null &&
                      attendeLabDetails.ComputeVMUsage === true &&
                      vmUsage.UptimeLimit !== null &&
                      vmUsage.RemainingUptime !== null &&
                      vmUsage.RemainingUptime === '00:00:00')
                  ">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                    </teams-button>
                    <teams-button role="button" aria-label="Launch Lab"
                      tooltip="{{ 'LAUNCH_VM_IN_BROWSER' | translate }}" (buttonClick)="openVMLaunchURL(row)"
                      [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                      *ngIf="row.VMLaunchURL && showVMLaunchButton" [disabled]="
                    !(['VM running','running']).includes(row.Status) ||
                    (row.Status !== null &&
                      attendeLabDetails.ComputeVMUsage === true &&
                      vmUsage.UptimeLimit !== null &&
                      vmUsage.RemainingUptime !== null &&
                      vmUsage.RemainingUptime === '00:00:00' )
                  ">
                      <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                    </teams-button>

                    <teams-button aria-label="Launch url" role="button"
                      tooltip="{{ 'GO_TO' | translate }} {{ row?.ResourceName }}" (buttonClick)="openVMandGitDoc(row)"
                      *ngIf="
                    showLaunchButton &&
                    vmUsage?.ResourceURI !== null &&
                    vmUsage?.ResourceURI !== '' &&
                    this?.internalId !== ''
                  " [disabled]="
                    row?.InternalId === this.internalId ||
                    row.Status !== 'VM running' ||
                    (row.Status !== null &&
                      attendeLabDetails.ComputeVMUsage === true &&
                      vmUsage.UptimeLimit !== null &&
                      vmUsage.RemainingUptime !== null &&
                      vmUsage.RemainingUptime === '00:00:00')
                  " [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-sm mr-2 mb-2 '">
                      <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                    </teams-button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tr *ngIf="!isVMLoading && vmUsage?.ResourceType === null">
              <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal" *ngIf="modelShow" [class.validation]="modelShow" id="validation" appTrapFocus>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" aria-label="modal title">
                {{ modalData.title | translate }}
              </h4>
              <teams-button aria-label="Close" role="button" [classList]="'close'" (buttonClick)="modelShow = false">
                &times;
              </teams-button>
            </div>
            <div class="modal-body" aria-label="modal description">
              <p>
                {{ modalData.description | translate }}
              </p>
            </div>
            <div class="modal-footer">
              <teams-button aria-label="Confirm" (buttonClick)="modelShow = false">
                {{ "OK" | translate }}
              </teams-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- VM labs section closed -->
    <!-- sql Pool section started -->
    <div id="sqlPool-section" class="square-box" *ngIf="showSqlPool">
      <div class="heading-box">
        <h3 class="heading" role="heading" aria-level="3">{{'SYNAPSE_SQL_POOL' |translate}}</h3>
        <p role="heading" aria-level="4" *ngIf="show_sqlPooltime">
          {{'MAXIMUM_UPTIME_LIMIT'|translate}}{{ " (hh:mm) : " + sqlPool_uptimeLimit }}
        </p>
        <p role="heading" aria-level="4" *ngIf="show_sqlPooltime">
          {{'REMAINING_UPTIME'|translate}} {{ " (hh:mm) : " + sqlPool_remainingTime }}
          <span id="sql-tooltip" class="info-tooltip" [ngClass]="{ timeReached: sqlPool_remainingTime === '00:00' }"
            tooltip="{{ SQLtooltipText | translate }}" tabindex="0"
            attr.aria-label="{{ SQLtooltipText | translate }}"><i class="fas fa-info-circle"></i></span>
        </p>
      </div>
      <div class="sql-group">
        <div class="portlet-body">
          <div class="table-responsive">
            <table aria-label="Sql Pool Table" class="table" tabindex="0">
              <tr>
                <th>{{ "NAME" | translate }}</th>
                <th>{{ "STATUS" | translate }}</th>
              </tr>
              <tr let *ngFor="let row of sqlResourceList">
                <td>
                  {{ row.ResourceName }}
                </td>
                <td>
                  <span *ngIf="row.Status === '' || row.Status === null">
                    {{ "UPDATING_STATUS" | translate }}
                  </span>
                  <span *ngIf="row.Status !== '' && row.Status !== null">
                    {{ row.Status }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- sql Pool section closed -->
    <!-- Section if no rsources are available -->
    <div id="noResources" class="square-box" *ngIf="!showSqlPool && !isVMLoading && !vmUsage">
      <p>{{ "NO_ACTIVE_RESOURCES" | translate }}</p>
    </div>
  </ng-template>
</div>