import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, retry, switchMap, timeout } from 'rxjs/operators';

import { DatabricksResource, Lab } from '../modals/lab.model';
import { CountryList } from '../modals/common.model';

import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class LabService {
  apiUrl: any;
  headers;
  labDetailsInES = new Subject<Lab>();
  loading = new Subject<boolean>();
  guideTitle = new Subject<string>();
  reloadVMs = new Subject();
  languageSwitch = new Subject();
  vmStateUpdate = new Subject();
  labValidationRefresh = new Subject();
  resizeEvent = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private utilService: UtilityService,
    @Inject('environment')
    environment
  ) {
    if (environment.apiUrl) {
      this.apiUrl = environment.apiUrl;
    } else this.apiUrl = environment.APIURL;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  /**
   * Api for get detail of
   * Main page
   */
  getDetails(id: string, lang: string): Observable<Lab> {
    if (lang === 'en') {
      lang = 'en-us';
    }
    return this.httpClient.get<Lab>(
      `${this.apiUrl}AttendeeTestDrive/OnDemandLab/${id}/${lang}`
    );
  }
  getDetailsInLang(id: string, lang: string): void {
    if (lang === 'en') {
      lang = 'en-us';
    }
    this.httpClient
      .get<Lab>(`${this.apiUrl}AttendeeTestDrive/OnDemandLab/${id}/${lang}`)
      .subscribe((labDetails) => {
        this.labDetailsInES.next(labDetails);
      });
  }

  controlContainerAction(deploymentId: string, action: string) {
    return this.httpClient.post(
      `${this.apiUrl}AttendeeTestDrive/ControlContainerAction?deploymentid=${deploymentId}&action=${action}`,
      {}
    );
  }

  getContainerStatus(
    deploymentId: string,
    containerStatus: string
  ): Observable<string> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post<string>(
      `${this.apiUrl}AttendeeTestDrive/GetContainerStatus?deploymentId=${deploymentId}&status=${containerStatus}`,
      {}
    );
  }

  getVirtualMachineStatus(
    subscriptionId: string,
    resourceGroupName: string,
    VMName: string,
    voucherCode: string,
    isHyperv?: boolean
  ): Observable<any> {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetVirtualMachineStatus/${subscriptionId}/${resourceGroupName}/${VMName}/${voucherCode}${isHyperv ? `/true` : ''}`
    );
  }

  getACIDeployment(voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/InitiateACIDeployment?voucherCode=${voucherCode}`
    );
  }

  /**
   *
   * @param internalId
   */
  getEnvironmentStatus(internalId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiUrl}AttendeeTestDrive/InitiateDeployment/${internalId}`
    );
  }

  /**
   *
   * @param internalId
   * @param voucherCode
   * @param region
   */
  initialDeployment(
    internalId: string,
    voucherCode: string,
    region: any
  ): Observable<any> {
    let url = `${this.apiUrl}AttendeeTestDrive/InitiateDeployment/${internalId}`;
    if (voucherCode !== null && voucherCode !== undefined) {
      url = `${url}/${voucherCode}`;
    }
    if (region !== null && region !== undefined) {
      url = `${url}/${region.RegionName}`;
    }
    return this.httpClient.get<any>(url);
  }

  /**
   *
   * @param voucherCode
   */
  getAttendeeLab(voucherCode: string) {
    return this.httpClient.get<any>(
      `${this.apiUrl}AttendeeTestDrive/GetAttendeeTestDrive/${voucherCode}`
    );
  }

  /**
   *
   * @param internalId
   * @param voucherCode
   */
  checkLabActivationClosed(internalId: string, voucherCode: string) {
    return this.httpClient.get<any>(
      `${this.apiUrl}AttendeeTestDrive/CheckLabActivationClosed?internalId=${internalId}&voucherCode=${voucherCode}`
    );
  }

  /**
   *
   * @param uniqueName
   * @param voucherCode
   */
  getRDPLabEnvironmentStatus(uniqueName: string, voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetRDPLabEnvironmentStatus/${uniqueName}/${voucherCode}`
    );
  }

  /**
   *
   * @param videoUrl
   */
  getVideoId(videoUrl) {
    let videoId;
    if (videoUrl.includes('vimeo')) {
      videoId = videoUrl.split('vimeo.com/')[1];
      videoId = videoId.split('/')[0];
    } else if (videoUrl.includes('youtube')) {
      videoId = videoUrl.split('watch?v=')[1];
      videoId = videoId.split('/')[0];
    }

    return videoId;
  }

  /**
   *
   * @param uniqueName
   * @param voucherCode
   */
  async getGitHubDocs(uniqueName: string, voucherCode: string) {
    const lang = localStorage.getItem('language') || 'en';
    const [{ Name }] = (await this.utilService
      .getLanguages()
      .pipe(
        map((languages) => languages.filter((item: any) => item.Code === lang))
      )
      .toPromise()) as any;
    return this.httpClient
      .get(
        `${this.apiUrl}GitHubDoc/GetGitHubMaster/${uniqueName}/${voucherCode}/${Name}`
      )
      .toPromise();
  }

  /**
   *
   * @param uniqueName
   * @param voucherCode
   */
  getLabEnvironment(uniqueName: string, voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetLabViewDetails/${uniqueName}/${voucherCode}`
    );
  }

  /**
   *
   * @param voucherCode
   */
  GetVirtualMachine(voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetVirtualMachines/${voucherCode}`
    );
  }

  /**
   *
   * @param voucherCode
   */
  GetVMUsage(voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetVMUsage/${voucherCode}`
    );
  }

  /**
   *
   * @param voucherCode
   */
  GetSqlPool(voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetResourceUsage/${voucherCode}`
    );
  }

  /**
   *
   * @param voucherCode
   */
  getValidationStatus(voucherCode: string) {
    return this.httpClient.get(
      `${this.apiUrl}TemplateValidation/GetValidationStatusForAttendee/${voucherCode}/false`
    );
  }

  /**
   *
   * @param id
   * @param vmGuid
   */
  vmlaunchUrl(id, vmGuid?: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetVMLaunchURL/${id}/${vmGuid}`
    );
  }

  /**
   *
   * @param url
   * @param data
   */
  getAuthToken(url, data) {
    const options = { headers: this.headers };
    return this.httpClient
      .post<any>(`${url}`, data, options)
      .pipe(timeout(60000), retry(0));
  }

  /**
   *
   * @param url
   * @param data
   */
  createQuickConnection(url, data) {
    const options = { headers: this.headers };
    return this.httpClient.post<any>(`${url}`, data, options);
  }

  /**
   *
   * @param type
   * @param platformId
   * @param id
   * @param groupname
   * @param name
   * @param vcode
   */
  virtualMachineOperations(
    type,
    platformId,
    id,
    groupname,
    name,
    vcode: string
  ): Observable<any> {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/VirtualMachineOperations/${type}/${platformId}/${id}/${groupname}/${name}/${vcode}`
    );
  }

  /**
   *
   * @param step
   * @param vcode
   */
  hyperVmOperation(operation: string, vmGuid: string, vcode: string): Observable<any> {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/HyperVmOperations/${operation}/${vmGuid}/${vcode}`
    );
  }

  /**
   *
   * @param step
   * @param vcode
   */
  getAttendeeParameters(step, vcode: string) {
    return this.httpClient.get(
      `${this.apiUrl}TemplateValidation/GetAttendeeParameters/${step}/${vcode}`
    );
  }

  /**
   *
   * @param step
   */
  validateCustomTemplateStep(step) {
    return this.httpClient.post(
      `${this.apiUrl}TemplateValidation/ValidateCustomTemplateStep`,
      step
    );
  }

  /**
   *
   * @param code
   * @param item
   */
  validateTemplateModule(code: string, item) {
    return this.httpClient.post(
      `${this.apiUrl}TemplateValidation/ValidateTemplateModule/${code}`,
      item
    );
  }

  /**
   *
   * @param internalId
   * @param userInviteLink
   */
  grabAzurePass(internalId: string, userInviteLink?: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GrabAzurePass/${encodeURIComponent(
        internalId
      )}${userInviteLink ? `/${userInviteLink}` : ''}`
    );
  }

  /**
   *
   * @param internalId
   * @param userInviteLink
   */
  onGrabAzurePass(internalId: string, userInviteLink?: string) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/CheckLabActivationClosed?internalId=${internalId}&voucherCode=${userInviteLink}`
    );
  }

  /**
   *
   * @param code
   * @param time
   */
  extendLabDurationByAttendee(code, time) {
    return this.httpClient
      .post(
        `${this.apiUrl}OnDemandLabUser/ExtendLabDurationByAttendee?voucherCode=${code}&durationToExtend=${time}`,
        '',
        { observe: 'response' }
      )
      .pipe(switchMap((res) => (res.status === 204 ? of(true) : of(false))));
  }

  // public getFromAPI() {
  //   return this.httpClient.get('/Products/latest', { observe: 'response' }).pipe(
  //     switchMap(res => res.status === 204 ? of([]) : of(res))
  //   ).subscribe();
  // }

  /**
   *
   * @param userInviteLink
   */
  createAttendeeActivityLog(userInviteLink) {
    return this.httpClient.post(
      `${this.apiUrl}AttendeeTestDrive/CreateAttendeeActivityLog/${userInviteLink}`,
      ''
    );
  }

  /**
   *
   * @param InternalId
   */
  completeTestDrive(InternalId) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/CompleteTestDrive/${encodeURIComponent(
        InternalId
      )}`
    );
  }

  /**
   *
   * @param VoucherCode
   */
  stopResourceOnIdleTimeOut(VoucherCode) {
    return this.httpClient.get(
      `${
        this.apiUrl
      }AttendeeTestDrive/StopResourcesOnIdleTimeout/${encodeURIComponent(
        VoucherCode
      )}`
    );
  }

  /**
   *
   * @param VoucherCode
   */
  checkResourcesStoppedByIdleTimeout(VoucherCode) {
    return this.httpClient.get(
      `${
        this.apiUrl
      }AttendeeTestDrive/CheckResourcesStoppedByIdleTimeout/${encodeURIComponent(
        VoucherCode
      )}`
    );
  }

  /**
   *
   * @param VoucherCode
   */
  restartResources(VoucherCode) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/RestartResources/${encodeURIComponent(
        VoucherCode
      )}`
    );
  }

  /**
   *
   * @param VoucherCode
   */
  checkResourcesCurrentStatus(VoucherCode) {
    return this.httpClient.get(
      `${
        this.apiUrl
      }AttendeeTestDrive/GetResourcesGeneralStatus/${encodeURIComponent(
        VoucherCode
      )}`
    );
  }

  /**
   * Check current connection
   * @param currentRemoteConnect
   */
  checkRemoteConnectResourceStatus(currentRemoteConnect: any) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/CheckRemoteConnectResourceStatus/${currentRemoteConnect.SubscriptionId}/${currentRemoteConnect.ContainerName}/${currentRemoteConnect.ContainerResourceGroup}/${currentRemoteConnect.VMName}/${currentRemoteConnect.VMResourceGroup}`
    );
  }

  /**
   * Get the voucher code of the lab
   * @param inputGuid
   * @param isDirect
   */
  getVoucherCodeAndUniqueName(inputGuid, isDirect = false) {
    return this.httpClient.get(
      `${this.apiUrl}OnDemandLabUser/GetUserVoucherCode?inputGuid=${inputGuid}&isDirect=${isDirect}`
    );
  }

  // poll?uid=e3859526-10ec-432b-a158-77f6b76ecb3e
  /**
   * Api for survey form detail
   *
   */
  getSurvetForm(uniqueName) {
    return this.httpClient.get(`${this.apiUrl}poll?uid=${uniqueName}`);
  }

  addSurvetFormData(id, data) {
    return this.httpClient.post(`${this.apiUrl}Response?PollId=${id}`, data);
  }

  getCountry(uniqueName, labType) {
    const lang = localStorage.getItem('language') || 'en';
    return this.httpClient.get<CountryList[]>(
      `${this.apiUrl}Option/Countries?entityType=${labType}&entityUniqueName=${uniqueName}&lang=${lang}`
    );
  }

  /**
   * Get the voucher code of the lab
   * @param eventUniqueName
   */
  getRegionByLabUniqueName(eventUniqueName) {
    return this.httpClient.get(
      `${this.apiUrl}AttendeeTestDrive/GetRegionByLabUniqueName/${eventUniqueName}`
    );
  }

  /**
   * Get the voucher code of the lab
   * @param labClusterUserVoucher
   */
  getLabClusterUserDetail(labClusterUserVoucher) {
    return this.httpClient.get(
      `${this.apiUrl}LabCluster/GetLabClusterUserDetail/${labClusterUserVoucher}`
    );
  }
/**
 * @param labClusterUserVoucher
 */
resendEmail(labClusterUserVoucher){
  return this.httpClient.get(
    `${this.apiUrl}AECUser/ResendEmail/${labClusterUserVoucher}`
  );
}

  saveExtendedUserAttribute(voucherCode, data) {
    return this.httpClient.post(`${this.apiUrl}LabCluster/SaveExtendedUserAttributeValues/${voucherCode}`, data);
  }

  createNavigationAnalytics(data) {
    return this.httpClient.post(`${this.apiUrl}GitHubDoc/CreateNavigationAnalytics`, data);
  }

  getTemplateLabAssetDetail(id, voucherCode) {
    return this.httpClient.get(`${this.apiUrl}TemplateLabAsset/GetTemplateLabAssetDetail/${id}/${voucherCode}`);
  }

  public getDatabricksFrameForm(queryParams):Observable<any>{
    return this.httpClient.get(`${this.apiUrl}DatabricksWorkspace/AutoSignIn`,{params:queryParams});
  }

  public getDatabricksClusters(queryParams):Observable<Array<DatabricksResource>>{
    return this.httpClient.get<Array<DatabricksResource>>(`${this.apiUrl}DatabricksWorkspace/GetDatabricksClusters`,{params:queryParams});
  }

  public getDatabricksSQLEP(queryParams):Observable<Array<DatabricksResource>>{
    return this.httpClient.get<Array<DatabricksResource>>(`${this.apiUrl}DatabricksWorkspace/GetDatabricksSqlEndpoints`,{params:queryParams});
  }

  public startDBCluster(queryParams):Observable<any>{
    return this.httpClient.post(`${this.apiUrl}DatabricksWorkspace/StartDatabricksCluster`,null,{params:queryParams});
  }

  public stopDBCluster(queryParams):Observable<any>{
    return this.httpClient.post(`${this.apiUrl}DatabricksWorkspace/StopDatabricksCluster`,null,{params:queryParams});
  }

  public getUpdatedDBClusterStatus(queryParams):Observable<DatabricksResource>{
    return this.httpClient.get<DatabricksResource>(`${this.apiUrl}DatabricksWorkspace/GetLatestDatabricksClusterStatus`,{params:queryParams});
  }

  public startDBSQLEP(queryParams):Observable<any>{
    return this.httpClient.post(`${this.apiUrl}DatabricksWorkspace/StartDatabricksSqlEndpoint`,null,{params:queryParams});
  }

  public stopDBSQLEP(queryParams):Observable<any>{
    return this.httpClient.post(`${this.apiUrl}DatabricksWorkspace/StopDatabricksSqlEndpoint`,null,{params:queryParams});
  }

  public getUpdatedDBSQLEPStatus(queryParams):Observable<DatabricksResource>{
    return this.httpClient.get<DatabricksResource>(`${this.apiUrl}DatabricksWorkspace/GetLatestDatabricksSqlEndpointStatus`,{params:queryParams});
  }

}

