<ng-container *ngIf="!loading || isRefreshing">
  <div class="row" *ngIf="templateValidation?.TemplateValidationStatus?.length > 0">
    <div class="col-lg-9"></div>
    <div class="col-lg-3 refresh-btn">
      <div style="float: right; padding-bottom: 15px">
        <button role="button" class="btn btn-sm ts-btn-fluent-primary refresh" tabindex="0"
          attr.aria-label="{{ 'REFRESH_LAB_VALIDATION' | translate }}" (click)="getLabValidationData()"
          (keyup.enter)="getLabValidationData()">
          <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 refresh-vm"
            [ngClass]="{ rotate: isRefreshing }" role="button" title="{{ 'REFRESH_LAB_VALIDATION' | translate }}">
            <span *ngIf="!docActions"> </span>
          </span>
          <span>{{ "BUTTON_REFRESH" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!templateValidation ||
      (templateValidation?.TemplateValidationStatus?.length === 0 &&
      !isRefreshing)
    ">
    <div class="no-modules alert" [ngClass]="{
      'alert-custom alert-light-primary process-azurepass' : isMylearning,
      'alert-info' : !isMylearning
    }">
      <strong translate="NO_VALIDATION_FOUND"></strong>
    </div>
  </div>
  <div *ngIf="
      templateValidation?.TemplateValidationStatus?.length > 0 && !isRefreshing
    " class="portlet box default">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div id="accordionExample" class="accordion shadow">
          <div class="card" *ngFor="let module of templateValidation?.TemplateValidationStatus">
            <div [id]="'headingOne' + module.ModuleNumber" class="card-header bg-white shadow-sm border-0">
              <div style="font-size: 1rem" role="heading" aria-level="2" class="mb-0 font-weight-bold labTitle">
                <!-- <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="d-block position-relative text-dark text-uppercase collapsible-link py-2">{{module.ModuleNumber}}. {{module.Name}}</a>-->
                <a role="button" href="javascript:void(0)" data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + module.ModuleNumber" aria-expanded="true"
                  [attr.aria-controls]="'collapseOne' + module.ModuleNumber" class="
                    labTitle-anchor
                    d-block
                    position-relative
                    text-dark text-uppercase
                    labTitle-anchor
                    collapsible-link
                    py-2
                  ">{{ module.ModuleNumber }}. {{ module.Name }}</a>
                <div class="actionLInks">
                  <a class="actionLInks-anchor mr-3" role="button" title="{{ 'VALIDATION_SUCCESS' | translate }}"
                    *ngIf="module.ConsolidatedStatus == 'Succeeded'"><i role="img" aria-label="img" aria-hidden="true"
                      class="fa fa-check-square" style="color: forestgreen"></i></a>
                  <a class="actionLInks-anchor" role="button" href="javascript:void(0)"
                    (click)="validateModule(module, 'validateAll')" *ngIf="module.ConsolidatedStatus != 'Succeeded'"
                    title="{{ 'VALIDATION_PENDING' | translate }}"><i class="fa fa-exclamation-triangle"
                      style="color: #f2784b"></i>
                  </a>
                </div>
              </div>
            </div>
            <div [id]="'collapseOne' + module.ModuleNumber" [attr.aria-labelledby]="'headingOne' + module.ModuleNumber"
              data-parent="#accordionExample" class="collapse show">
              <div class="card-body p-0">
                <div class="row" style="text-align: center; font-weight: bold; padding: 10px"
                  *ngIf="module.Exercises?.Type == 'lab'" translate="MESSAGE_NO_LABS"></div>
                <div *ngFor="let exercise of module?.Exercises">
                  <div class="row" *ngIf="exercise.Type === 'lab'">
                    <div class="table-responsive col-md-12">
                      <p style="background-color: #eee" class="text-left mb-0 exercise-name pt-3 pb-2">
                        <b role="heading" aria-level="3">{{ exercise.Name }}</b>
                      </p>
                      <table class="table lab-validation-table" [attr.aria-label]="
                          'Lab Validation table for ' + exercise.Name
                        " tabindex="0">
                        <tr style="background-color: #eee">
                          <th>{{ "VALIDATION_STEP" | translate }}</th>
                          <th class="hide-column">
                            {{ "MESSAGE" | translate }}
                          </th>
                          <th>{{ "STATUS" | translate }}</th>
                          <th>{{ "ACTIONS" | translate }}</th>
                        </tr>
                        <tbody>
                          <tr *ngFor="let row of exercise.Steps">
                            <td>
                              {{ row.Name }}
                            </td>
                            <td class="hide-column">
                              {{ row.Message }}
                            </td>
                            <td class="status_val">
                              <span *ngIf="row.Status == 'Succeeded'" title="{{ row.Message }}"
                                class="text-white label bg-success" style="float: left">{{ "SUCCESS_CAPITAL" | translate
                                }}</span>
                              <span *ngIf="row.Status == 'Failed'" class="text-white label bg-danger"
                                style="float: left">{{ "FAIL_CAPITAL" | translate }}</span>
                              <span *ngIf="row.Status == 'Not Validated'" title="{{ row.Message }}" class="
                                  text-white
                                  label
                                  bg-info bg-not-validated
                                " style="float: left">{{ "NOT_VALIDATED" | translate }}</span>
                              <span *ngIf="row.Status == 'Validation Pending'" title="{{ row.Message }}"
                                class="text-white label bg-primary bg-pending" style="float: left">{{ "PENDING_CAPITAL"
                                | translate }}</span>
                              <span *ngIf="row.Status == 'In Progress'" class="
                                  text-white
                                  label
                                  bg-primary bg-in-progress
                                " style="float: left">{{ "IN_PROGRESS" | translate }}</span>
                              <span *ngIf="row.Status == 'Failed'" tabindex="0" class="info-tooltip" tooltip="{{ row.Message }}"
                                [attr.aria-label]="row.Message">
                                <i class="fas fa-info-circle"></i>
                              </span>
                            </td>
                            <td style="text-align: left">
                              <div *ngIf="
                                  row.Status == 'Failed' ||
                                  row.Status == 'Not Validated'
                                ">
                                <button role="button" tabindex="0" class="btnValidate btn ts-btn-fluent-outline" (keydown.enter)="
                                    validateManualStep(module, row)
                                  " (click)="validateManualStep(module, row)"
                                  title="{{ 'VALIDATE_STEP' | translate }}"><i class="fa fa-check"></i><span
                                    style="margin-left: 5px">{{
                                    "BUTTON_VALIDATE" | translate
                                    }}</span></button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="no-validation" *ngIf="exercise.Steps && exercise.Steps.length === 0">
                        <p>{{ "NO_VALIDATIONS_FOUND" | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--   validate Manual Step start    -->
<div class="modal modelShow modulevalidation" role="dialog" *ngIf="modelShow" id="validation" appTrapFocus
  appAutoFocusModal appRefocusPreviousEl aria-labelledby="lab-val-input-head">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" id="lab-val-input-head">
        <h4 class="modal-title">{{ "PARAMETER" | translate }}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <h6 class="attendee-input-title">
          {{ "PARAMETER_INPUT_MESSAGE" | translate }}
        </h6>
        <div *ngFor="let attendeeParameter of attendeeParameters" class="row attendee-par-input-container">
          <div class="col-md-12 col-lg-4">
            <teams-input [ngClassList]="showValidationError[attendeeParameter.Name] ? 'error-2': ''" [classList]="'attendee-par-input'" [value]="attendeeParameter.Name" [disabled]="true"></teams-input>
          </div>
          <div class="col-md-12 col-lg-8">
            <teams-input [ngClassList]="showValidationError[attendeeParameter.Name] ?
                'error-field' : '' " [placeholder]="'VALUE_LABEL' | translate"
              [classList]="'attendee-par-input modal-input'" (change)="onChange($event, attendeeParameter)"
              [value]="attendeeParameter['Value']"></teams-input>
          </div>
          <div class="ml-4 mt-2 error-2" *ngIf="showValidationError[attendeeParameter.Name]">
            {{ "MESSAGE_THIS_FIELD_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <teams-button [classList]="'btn ts-btn-fluent-red modal-close-button'" (buttonClick)="modelShow = false"
          data-dismiss="modal">
          {{ "CANCEL" | translate }}
        </teams-button>
        <teams-button [classList]="'btn ts-btn-fluent-primary modal-close-button'" (buttonClick)="validateSubmit()">
          {{ "BUTTON_SUBMIT" | translate }}
        </teams-button>
      </div>
    </div>
  </div>
</div>
<!--   validate Manual Step End    -->
