import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Lab } from './../models/lab.model';
import { LabService } from './../../services/lab.service';
import { LabClusterService } from '../../services/lab-cluster.service';
import { LabCluster } from '../../services/modals/lab-cluster.modal';
import { setSeconds } from 'ngx-bootstrap/chronos/utils/date-setters';
import { EventEmitterService } from '@teams-auth';

@Injectable({ providedIn: 'root' })
export class LabResolver implements Resolve<any> {
  constructor(private labSrv: LabService,
    private labClusterService: LabClusterService,
    private eventEmitter: EventEmitterService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Lab> | Observable<LabCluster> {
    const url = state.url;
    const urlParts = url.split('/');
    if (urlParts.includes('baselineevent')) {
      return this.labClusterService.getLabClusterDetails(route.params.uniqueIdentifier).pipe(
        catchError(error => {
          const code = 'Error - 70051';
          this.eventEmitter.debugAlert(code, "Error getting Lab Cluster details");
          return of(null);
        })
      );
    }
    const lang = localStorage.getItem('language') || 'en';
    return this.labSrv.getDetails(route.params.id, lang).pipe(
      catchError(error => {
        const code = 'Error - 70052';
        this.eventEmitter.debugAlert(code, error);
        return of(null);
      })
    );
  }
}
