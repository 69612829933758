import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  NgZone,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
} from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { CustomerType, Deployment, EnvironmentLayout, Lab, UserLabType } from '../../../modals/lab.model';
import { MarkdownService } from 'ngx-markdown';
import { ClipboardService } from 'ngx-clipboard';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../services/event.services';
import { htmlDecode, manageServiceVariables, stringToHtml } from '@utility';
import { NotificationService } from '../../../services/notification.service';
import { RemoteDesktopManager } from '../../../lib/remote';
import { EventEmitterService, UserSessionService } from '@teams-auth';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GithubDataService, CommonService } from '@utility';
import { ToastrService } from 'ngx-toastr';
declare const $: any;
import * as moment from 'moment';
@Component({
  selector: 'cloudlabs-doc-guide',
  templateUrl: './doc-guide.component.html',
  styleUrls: ['./doc-guide.component.scss'],
})
export class DocGuideComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() lang: string;
  @Input() manager: RemoteDesktopManager;
  @Input() guideData: Deployment;
  @Input() isSidenav = false;
  @Input() mode;
  @Input() layoutType:string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onLoadingComplete = new EventEmitter();
  @Output() labIsCompleted = new EventEmitter<string>();
  @Output() spliLabIsCompleted = new EventEmitter<string>();
  @Output() collapseSideBar = new EventEmitter();
  @Input() splitKey: string;

  public loading = false;
  public minGuide = true;
  public tabView = 'guide';
  public currentTab: any;
  public itemsPerPage = 5;
  public showDropdown = false;
  public labGuideData = [];
  public p = 1;
  public gitHUbdocs: any;
  public guideTab: any = [];
  public EdgeMessage = false;
  public FirefoxHelpMessage = false;
  public attendeLabDetails: any;
  public IsSupportQuestFlag: boolean;
  public defaultProps = {
    style: '',
    enableCopy: true,
  };
  appType: string;
  private notifier = new Subject();
  vmTabKey: string;
  currentGuideKey: string;
  @Input() isSplitScreen = false;
  public index: any;
  @Input() isMylearning;
  @Output() splitScreen = new EventEmitter();
  @Output() joinScreen = new EventEmitter();
  public environmentLayout = EnvironmentLayout;
  public customerType = CustomerType;

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if ($event.target.id !== 'showdropdown-menu') {
      this.showDropdown = false;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.updatePreHeights();
  }

  constructor(
    private LabSrv: LabService,
    public markdownService: MarkdownService,
    private _clipboardService: ClipboardService,
    private translateSrv: TranslateService,
    private notificationService: NotificationService,
    private eventSrv: EventService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private userSessionService: UserSessionService,
    private gitHubSrv: GithubDataService,
    private commonSrv: CommonService,
    private eventEmitter: EventEmitterService,
    private tostrSrv: ToastrService,
    @Inject('environment') public environment
  ) {
    this.appType = environment.appType;
    this._clipboardService.copyResponse$.subscribe((re) => {
      if (re.isSuccess) {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_COPIED'),
          null
        );
      }
    });
  }

  async ngOnInit() {
    this.vmTabKey = `vmTab_${this.labDetail.UniqueName}_${this.voucherCode}`;
    this.currentGuideKey = `currentPage_${this.labDetail.UniqueName}_${this.voucherCode}`;
    this.lang = localStorage.getItem('language') || 'en';
    this.currentTab = localStorage.getItem(this.vmTabKey);
    this.loading = true;
    this.IsSupportQuestFlag = this.labDetail.IsSupportQuest;

    // ui reflect changes
    await this.ngZone.run(async () => {
      await this.getLabDocs();
    });

    this.attendeLabDetails = await this.LabSrv.getLabEnvironment(
      this.labDetail.UniqueName,
      this.voucherCode
    ).toPromise();
    this.createTab();
    setTimeout(() => {
      this.loading = false;
    }, 1000);

    setTimeout(() => {
      // manage dynamic tabs calculation
      this.reFlow();
      $(window).on('resize', this.reFlow);
      $(window).on('resize-tabs', this.reFlow);
    }, 1500);

    $(document).ready(() => {
      if (/Edge/.test(navigator.userAgent)) {
        this.EdgeMessage = true;
      }
      if (navigator.userAgent.indexOf('Firefox') !== -1) {
        this.FirefoxHelpMessage = true;
      }
    });
    this.onLoadingComplete.emit(true);
    this.eventSrv.windowJoin.subscribe(() => {
      this.getVmTab();
    });
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 1000);
    this.userSessionService.vmRenderOnLeftPanelChange$.subscribe(
      (boolValue) => {
        setTimeout(() => {
          this.reFlow();
        }, 1500);
      }
    );
    this.translateSrv.onLangChange.subscribe(() => {
      setTimeout(() => {
        this.getLabDocs();
        this.reFlow();
      }, 1500);
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      for (const key in changes) {
        if (
          Object.prototype.hasOwnProperty.call(changes, key) &&
          changes[key].currentValue
        ) {
          this[key] = changes[key].currentValue;
        }
        if (
          !changes[key].isFirstChange() &&
          key === 'lang' &&
          (changes[key].currentValue ||
            (changes[key].hasOwnProperty('previousValue') &&
              changes[key].currentValue !== changes[key].previousValue))
        ) {
          this.loading = true;

          this.labGuideData = [];
          this.gitHUbdocs = null;
          this.p = 0;
          this.guideTab = [];
          await this.ngOnInit();
          this.loading = false;
          setTimeout(() => {
            window.dispatchEvent(new Event('resize-tabs'));
          }, 100);
        }
      }
    }
  }

  splitWindow() {
    this.splitScreen.emit();
  }

  joinWindow() {
    this.joinScreen.emit();
  }

  createTab() {
    if (this.guideData?.IsGitDocAvailable == true) {
      this.guideTab.push({
        id: 1,
        link: 'guide',
        title: 'HEADING_LAB_GUIDE',
        selected: true,
      });
    }
    if (this.labDetail) {
      this.guideTab.push({
        id: 2,
        link: 'environment',
        title: 'TAB_ENVIRONMENT_DETAILS',
        selected: false,
      });
    }
    if (
      this.labDetail?.EnableLabValidation === true &&
      !this.IsSupportQuestFlag
    ) {
      this.guideTab.push({
        id: 3,
        link: 'validation',
        title: 'TAB_LAB_VALIDATION',
        selected: false,
      });
    }
    if (
      (!this.guideData?.IsSharedTestDrive &&
        this.attendeLabDetails.CloudPlatformId === 1) ||
      (this.guideData.CloudPlatformId == 1 || this.guideData.CloudPlatformId == 2) ||
      !this.guideData?.IsSharedTestDrive &&
      (this.attendeLabDetails.CloudPlatformId === 1 ||
        this.attendeLabDetails.CloudPlatformId === 2)
    ) {
      this.guideTab.push({
        id: 4,
        link: 'resources',
        title: 'TAB_RESOURCES',
        selected: false,
      });
    }
    if (this.labDetail) {
      this.guideTab.push({
        id: 5,
        link: 'help',
        title: 'HELP',
        selected: false,
      });
    }
    if (this.labDetail.LabLaunchPageDescription) {
      this.guideTab.unshift({
        id: 6,
        link: 'description',
        title: 'LAB_DESCRIPTION_TAB',
        selected: true,
      });
      this.tabView = 'description';
    }
  }

  getcalue() {
    this.navigationAnalytics();
    const page = this.p - 1;
    const path = this.gitHUbdocs.Files[page].RawFilePath;
    const texting = path.substring(0, path.lastIndexOf('/'));

    this.markdownService.renderer.image = (
      href: string,
      title: string,
      text: string
    ) => {
      if (!href.includes('http')) {
        return `<img alt ="${title || text}" src="${texting}/${href}">`;
      } else {
        return `<img alt ="${title || text}" src="${href}">`;
      }
    };
    const el = $(`#guideView`);
    el.animate({ scrollTop: 0 });
    setTimeout(() => {
      this.handleLabGuideElements('');
    }, 500);
    el.focus();
  }

  navigationAnalytics() {
    const data = {
      VoucherCode: this.voucherCode,
      VisitedPageNumber: this.p,
      TotalPages: this.gitHUbdocs.Files.length,
      VisitedTime: moment.utc().format(),
    };
    this.LabSrv.createNavigationAnalytics(data).subscribe();
  }

  /**
   * get lab docs md file
   */
  async getLabDocs() {
    if (
      this.guideData.IsGitDocAvailable == true
      // Commenting this code for Databrick WorkSpace - Vijay Chaudhary (320)
      //|| !this.labDetail.EnableAccessOverHttp
    ) {
      this.labGuideData = [];
      this.gitHUbdocs = null;
      this.gitHUbdocs = await this.LabSrv.getGitHubDocs(
        this.labDetail.UniqueName,
        this.voucherCode
      );
      this.navigationAnalytics();
      if (this.gitHUbdocs.EventName && this.gitHUbdocs.EventName !== '') {
        this.LabSrv.guideTitle.next(this.gitHUbdocs.EventName);
      }
      if (this.gitHUbdocs.Files.length > 0) {
        this.getVmTab();
        // manage the markdown renderers
        const path = this.gitHUbdocs.Files[0].RawFilePath;
        const texting = path.substring(0, path.lastIndexOf('/'));

        this.markdownService.renderer.code = (code: string) => {
          const injectTagRegex =
            /<inject\s+key="([^"]*)"(\s+style="([^"]*)")?(\s+enableCopy="([^"]*)")?\s*\/?>/g;
          let style = '';
          let enableCopy = true;
          
          // Find and store all <inject> and </inject> tags
          const injectTags = [];
          code = code.replace(injectTagRegex, (match) => {
            injectTags.push(match);
            return ''; // Remove the <inject> tags temporarily
          });

          // Escape angle brackets in the remaining code
          code = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');

          // Restore the <inject> and </inject> tags
          let tagIndex = 0;
          code = code.replace(/&lt;inject.*?&gt;|&lt;\/inject&gt;/g, () => {
            return injectTags[tagIndex++]; // Put back the <inject> tags
          });
          
          code = code.replace(
            injectTagRegex,
            (
              _,
              key,
              styleAttr,
              styleValue,
              enableCopyAttr,
              enableCopyValue
            ) => {
              key = key.replace(/ +/g, '').toLowerCase();
              const replacements = {
                azureaduseremail: this.guideData.AADEmail,
                azureaduserpassword: this.guideData.TempPassword,
                accesskey: this.guideData.AADSPAppId,
                displayname: this.guideData.AADSPDisplayName,
                secretkey: this.guideData.AADSPAppKey,
                subscriptionid: this.guideData.SubscriptionGuid,
                tenantid: this.guideData.TenantId,
                tenantdomainname: this.guideData.TenantDomainName,
              };

              // Iterate through OutputValues and add to replacements
              if (
                this.attendeLabDetails &&
                Array.isArray(this.attendeLabDetails.DeploymentOutputValues)
              ) {
                this.attendeLabDetails.DeploymentOutputValues.forEach(
                  (deployment: any) => {
                    deployment.OutputValues.forEach((outputValue: any) => {
                      replacements[
                        outputValue.Name.replace(/ +/g, '').toLowerCase()
                      ] = outputValue.Value;
                    });
                  }
                );
              }

              // Update style if available
              if (styleAttr && styleValue) {
                style = styleValue;
              }

              // Handle enableCopy attribute
              enableCopy = enableCopyValue === 'false' ? false : true;

              return replacements[key] ?? '';
            }
          );
          return `<pre class="line-numbers language-none" tabindex="0"><code class="language-none" enablecopy="${enableCopy}" style="${style}">${code}</code></pre>`;
        };

        this.markdownService.renderer.html = (inject: string) => {
          if (inject === '</inject>') {
            return '';
          }
          const elm = stringToHtml(inject);
          if (elm) {
            const key = elm
              .getAttribute('key')
              .replace(/ +/g, '')
              .toLowerCase();
            const props = { ...this.defaultProps };
            if (elm.hasAttribute('enableCopy')) {
              props.enableCopy = Boolean(
                elm.getAttribute('enableCopy') === 'true'
              );
            }
            if (elm.hasAttribute('style')) {
              props.style = elm.getAttribute('style');
            }
            // add deployment variable data
            let outputValue;
            if (
              this.attendeLabDetails &&
              this.attendeLabDetails.DeploymentOutputValues.length > 0
            ) {
              this.attendeLabDetails.DeploymentOutputValues.forEach((item) => {
                const { OutputValues } = item;
                if (
                  OutputValues &&
                  OutputValues.length > 0 &&
                  OutputValues.findIndex(
                    (value) =>
                      value.Name.replace(/ +/g, '').toLowerCase() === key
                  ) > -1
                ) {
                  outputValue = OutputValues.find(
                    (value) =>
                      value.Name.replace(/ +/g, '').toLowerCase() === key
                  );
                }
              });
            }

            // set the output params
            if (outputValue) {
              return this.setGuideVariableHTML(props, outputValue.Value, key);
            }
            // these for the variable and service variables
            switch (key) {
              case 'azureaduseremail':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.AADEmail,
                  'username'
                );
              case 'azureaduserpassword':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.TempPassword,
                  'password'
                );
              case 'applicationid':
              case 'accesskey':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.AADSPAppId,
                  key
                );
              case 'displayname':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.AADSPDisplayName,
                  key
                );
              case 'secretkey':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.AADSPAppKey,
                  key
                );
              case 'subscriptionid':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.SubscriptionGuid,
                  key
                );
              case 'tenantid':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.TenantId,
                  key
                );
              case 'tenantdomainname':
                return this.setGuideVariableHTML(
                  props,
                  this.guideData.TenantDomainName,
                  key
                );
              default:
                return '';
            }
          }
          return '';
        };

        this.commonSrv.getImageTag(path, this.markdownService);
        this.commonSrv.getLinks(this.markdownService);

        const gitdocObserables = this.gitHUbdocs.Files.map((file) =>
          this.gitHubSrv.getGitHubData(file.RawFilePath)
        );
        combineLatest(gitdocObserables)
          .pipe(
            takeUntil(this.notifier),
            map((resp) =>
              resp.map((res, index) => ({
                res,
                order: this.gitHUbdocs.Files[index].Order,
              }))
            )
          )
          .subscribe(
            (res) => {
              this.labGuideData = res;
              this.labGuideData.sort((x, y) => {
                return x.order - y.order;
              });
              setTimeout(() => {
                this.handleLabGuideElements(texting);
              }, 3000);
            },
            (err) => {
              this.labGuideData = [
                { res: 'Error: One or more documents not found', order: 1 },
              ];
              const code = 'Error - 70018';
              this.eventEmitter.debugAlert(code, err.error);
            }
          );
      }
    } else {
      this.tabView = 'environment';
      this.collapseSideBar.emit();
    }
  }

  async completeTheLab(event: string) {
    if (localStorage.getItem(this.splitKey)) {
      this.spliLabIsCompleted.emit(event);
      localStorage.setItem(`delete-lab-${this.voucherCode}`, event);
    }
    this.labIsCompleted.emit(event);
  }

  setGuideVariableHTML(props, value, label) {
    return `<span class="copydetails ${
      props.enableCopy ? '' : 'hide-copy-btn'
    }"  aria-label="${label}">
    <span ${props.style !== '' ? `style="${props.style}"` : ''}>${value}</span>
    </span>`;
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
  /**
   * selected tab
   * @param item
   */

  viewTab(item, list, spliceItem: boolean, spliceItem2: boolean) {
    this.tabView = item;
    setTimeout(() => {
      this.handleLabGuideElements('');
    }, 500);
    this.guideTab.forEach((element) => {
      if (element.title == list.title) {
        element.selected = true;
        localStorage.setItem(this.vmTabKey, element.link);
      } else {
        element.selected = false;
      }
    });
  }

  reFlow() {
    const tab_wrapper = $('.nav-tabs');
    const wrapper_width = tab_wrapper.width();
    const dropdown_width = tab_wrapper.find('li.dropdown').width();
    let width_sum = 0;
    tab_wrapper.find('>li:not(li.dropdown)').each(function (index, elm) {
      width_sum += $(elm).outerWidth(true);
      if (width_sum + dropdown_width + 30 > wrapper_width) {
        $(elm).hide();
      } else {
        $(elm).show();
      }
    });
    const hidden_lists = tab_wrapper.find('>li:not(li.dropdown):not(:visible)');
    if (hidden_lists.length > 0) {
      $('li.dropdown').show();
      tab_wrapper.find('ul.dropdown-menu li').hide();
      hidden_lists.map(function (element) {
        tab_wrapper
          .find('ul.dropdown-menu li#' + hidden_lists[element].id + '-dup')
          .show();
      });
    } else {
      $('li.dropdown').hide();
    }
  }

  handleLabGuideElements(texting) {
    const { DeploymentOutputValues } = this.attendeLabDetails;
    const guideData = this.guideData;
    const defaultProps = { ...this.defaultProps };
    if (texting !== '') {
      $('#guideView img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');

        if ($(elm).attr('src') && !$(elm).attr('src').includes('http')) {
          $(elm).attr('src', texting + '/' + $(elm).attr('src'));
        }
      });
    } else {
      $('#guideView img').each(function (index, elm) {
        // Add tab index to all images
        $(elm).attr('tabindex', '0');
        $(elm).addClass('img-click');
      });
    }
    const clickToCopyText = this.translateSrv.instant('COPY');
    $('#guideView .copydetails').each(function () {
      $(this).find('a').remove();
      let text = $(this).text();
      if (text !== undefined && text !== null && text !== '') {
        text = text.trim();
      }

      const ariaLabel = $(this).attr('aria-label');
      $(this).append(
        '<a style="' +
          $(this).attr('style') +
          '" tabindex="0" role="button" aria-label="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '"  data-toggle="tooltip" ' +
          '  title="' +
          clickToCopyText +
          ' ' +
          ariaLabel +
          '" alt="" class="fa fa-clone copyelement">' +
          text +
          '</a>'
      );
      // set tooltip
      $('[data-toggle="tooltip"]').tooltip({
        placement: 'bottom',
        trigger: 'hover',
      });
    });
    $('#guideView pre').each(function () {
      $(this).find('a').remove();
      const text = $(this).find('code')[0].innerHTML;
      const htmlText = htmlDecode(text);
      const elm = stringToHtml(htmlText);
      let outputValue;
      const props = { ...defaultProps };
      if (elm) {
        const key = elm.getAttribute('key').replace(/ +/g, '').toLowerCase();
        if (elm.hasAttribute('enableCopy')) {
          props.enableCopy = Boolean(elm.getAttribute('enableCopy') === 'true');
        }
        if (elm.hasAttribute('style')) {
          props.style = elm.getAttribute('style');
        }

        if (DeploymentOutputValues && DeploymentOutputValues.length > 0) {
          DeploymentOutputValues.forEach((item) => {
            const { OutputValues } = item;
            if (
              OutputValues &&
              OutputValues.length > 0 &&
              OutputValues.findIndex(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              ) > -1
            ) {
              outputValue = OutputValues.find(
                (value) => value.Name.replace(/ +/g, '').toLowerCase() === key
              );
            }
          });
        }

        // manage the service variable
        const Value = manageServiceVariables(key, guideData);
        if (Value !== '') {
          outputValue = { Value };
        }
      }

      const value = outputValue !== undefined ? outputValue.Value : text;
      if (outputValue !== undefined) {
        $(this).find('code')[0].innerHTML = value;
      }
      // handle css style on variables
      if (props.style) {
        const codeElm = $(this).find('code')[0];
        if (codeElm) {
          $(codeElm).attr('style', props.style);
        }
      }
      let enablecopy = $(this).find('code')[0]?.attributes['enablecopy']?.value || true;
      props.enableCopy = enablecopy === 'false' ? false : true;
      let heightinPX = $(this).css('height');
      $(this).css('display', 'inline-block');
      if ($(this).next('a').length === 0) {
        $(this).after(
          `<a tabindex="0" title="${clickToCopyText}" role="button" data-toggle="tooltip" aria-label="${clickToCopyText}" alt="" class="fa fa-clone copyelement" style="bottom:${
            parseInt(heightinPX.slice(0, -2)) - 10
          }px;left:0.4rem"><p style="display:none">${value.trimEnd()}</p></a>`
        );
        $('[data-toggle="tooltip"]').tooltip({
          placement: 'bottom',
          trigger: 'hover',
        });
      }
    });
    $('.selfnavigation').on('click', function () {
      const id = $(this).data('id');
      const elementID = id.replace('#', '');
      const el = document.getElementById(elementID);
      el.scrollIntoView();
    });
  }
  getVmTab(): void {
    this.currentTab = localStorage.getItem(this.vmTabKey);
    if (this.currentTab !== null) {
      this.tabView = this.currentTab;
    } else if (this.guideData.IsGitDocAvailable) {
      this.tabView = 'guide';
    } else {
      this.tabView = 'environment';
    }
  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private updatePreHeights(): void {
    $('#guideView pre').each(function () {
      let heightinPX = $(this).css('height');
      if ($(this).next('a').length > 0) {
        $(this)
          .next('a')
          .css('bottom', parseInt(heightinPX.slice(0, -2)) - 10 + 'px');
      }
    });
  }
}
