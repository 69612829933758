import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable()
export class LoadingService {
  private static instance: LoadingService;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private loadingMsgSubject = new BehaviorSubject<string[]>([]);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();
  loadingMsg$: Observable<string[]> = this.loadingMsgSubject.asObservable();

  constructor() {
    if (LoadingService.instance) {
      return LoadingService.instance;
    }

    LoadingService.instance = this;
  }

  showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())
    );
  }

  loadingOn(msg?: string[]) {
    if (msg !== undefined && msg.length > 0) {
      this.loadingMsgSubject.next(msg);
    }
    this.loadingSubject.next(true);
  }

  loadingOff() {
    this.loadingSubject.next(false);
    this.loadingMsgSubject.next([]);
  }
}
