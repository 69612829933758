import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { Lab } from '../../../modals/lab.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { RemoteDesktopManager } from '../../remote/services/remote-desktop-manager.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LabValidationDialogComponent } from '../lab-validation-dialog/lab-validation-dialog.component';
import {
  EventService,
  ValidationDialogStatus,
} from '../../../services/event.services';
import { EventEmitterService } from '@teams-auth';
import { miscellaneousConst } from '@utility';

@Component({
  selector: 'cloudlabs-lab-validation',
  templateUrl: './lab-validation.component.html',
  styleUrls: ['./lab-validation.component.scss'],
})
export class LabValidationComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() manager: RemoteDesktopManager;
  public templateValidation: any;
  public loading = false;
  public docActions = false;
  public expandModule = false;
  public modelShow = false;
  public modulemodelShow = false;
  public selectedModule: any;
  public manualStep: boolean;
  public CurrentStepIdCollection: Array<any> = [];
  public attendeeParameters: any;
  public steps: any;
  public AttendeeValues: any;
  public validationModule: any;
  public isRefreshing = false;
  public showValidationError: any;
  public modalRef: BsModalRef;
  isMylearning = false;
  constructor(
    private LabSrv: LabService,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private router: Router,
    private translateSrv: TranslateService,
    private modalService: BsModalService,
    private eventSrv: EventService,
    private eventEmitter: EventEmitterService
  ) {
    this.LabSrv.labValidationRefresh
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.getEnvironment();
      });
      const context = localStorage.getItem('context');
      if (context && context === miscellaneousConst.cloudlab) {
        this.isMylearning = true;
      }
  }

  async ngOnInit() {
    await this.getEnvironment();

    this.eventSrv.validationDialogStatus.subscribe((status) => {
      if (status === ValidationDialogStatus.CONFIRM_CLOSED) {
        this.validateLabModule();
      }
    });
  }

  async getEnvironment() {
    this.loading = true;
    const data: any = await this.LabSrv.getValidationStatus(
      this.voucherCode
    ).toPromise();
    this.templateValidation = data.length > 0 ? data[0] : null;
    this.loading = false;
  }

  async validateManualStep(validationModule, step) {
    this.validationModule = validationModule;
    this.steps = step;
    this.selectedModule = validationModule.ModuleId;
    if (step.ValidationType === 'manual') {
      this.manualStep = true;
    }
    this.CurrentStepIdCollection.push(step.StepId);

    if (
      step.ValidationType === 'custom' ||
      step.ValidationType === 'cloudlabs-azure'
    ) {
      this.LabSrv.getAttendeeParameters(step.StepId, this.voucherCode)
        .toPromise()
        .then((res: any) => {
          if (res) {
            this.attendeeParameters = res;
            if (this.attendeeParameters.length > 0) {
              this.buildShowValidationError(this.attendeeParameters);
              this.modelShow = true;
              this.manager.setFocused(false);
              //this.manager.setFocused(false)
            } else {
              this.validateModule(validationModule);
            }
          }
        });
    } else {
      this.validateModule(validationModule);
    }
  }

  closeDialog(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modulemodelShow = false;
    }
  }

  buildShowValidationError(validationData: any[]) {
    this.showValidationError = {};
    for (const field of validationData) {
      this.showValidationError[field.Name] = false;
    }
  }

  validateSubmit() {
    let isFormValid = true;
    for (const field of this.attendeeParameters) {
      if (field.Value === null || field.Value === '') {
        this.showValidationError[field.Name] = true;
        isFormValid = false;
      }
    }
    if (!isFormValid) {
      return;
    }
    this.AttendeeValues = {};
    this.AttendeeValues.Parameters = this.attendeeParameters;
    this.AttendeeValues.InviteLink = this.voucherCode;
    this.AttendeeValues.StepId = this.steps.StepId;
    this.LabSrv.validateCustomTemplateStep(this.AttendeeValues).subscribe(
      (res: any) => {
        this.getLabValidationData();
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_VALIDATED'),
          null
        );
        this.CurrentStepIdCollection = [];
      }, (error) => {
        const code = 'Error - 50027';
        this.eventEmitter.debugAlert(code, error);
      }
    );
    this.modelShow = false;
  }

  validateModule(validationModule, source?: string) {
    this.validationModule = validationModule;
    if (source === 'validateAll') {
      //this.modulemodelShow = true;
      this.modalRef = this.modalService.show(LabValidationDialogComponent, {
        initialState: {
          title: this.translateSrv.instant('SUBMIT_ALERT_MODEL_TITLE'),
          data: {
            description: this.translateSrv.instant('LAB_VALIDATION_ALERT'),
            failureButtonText: this.translateSrv.instant('CANCEL'),
            successButtonText: this.translateSrv.instant('OK'),
          },
        },
      });
    } else {
      this.validateLabModule();
    }
  }

  validateLabModule() {
    const exercises: any = this.validationModule?.Exercises;
    if (this.CurrentStepIdCollection.length === 0) {
      exercises.forEach((exercise) => {
        exercise.Steps.forEach((step) => {
          if (
            step.ValidationType !== 'manual' ||
            step.HasAttendeeParameter === false
          ) {
            this.CurrentStepIdCollection.push(step.StepId);
          }
        });
      });
    }
    this.LabSrv.validateTemplateModule(
      this.voucherCode,
      this.CurrentStepIdCollection
    ).subscribe((res: any) => {
      this.getLabValidationData();
      if (this.manualStep === true) {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_DATA_SUBMITED'),
          null
        );
      } else {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_VALIDATION_INITIATED'),
          null
        );
      }
      this.CurrentStepIdCollection = [];
    }, (error) => {
      const code = 'Error - 50028';
      this.eventEmitter.debugAlert(code, error);
    });
    this.modulemodelShow = false;
  }

  async getLabValidationData() {
    this.isRefreshing = true;
    await this.getEnvironment();
    this.isRefreshing = false;
  }

  onChange(event, field: any) {
    field.Value = (event.target as HTMLInputElement).value;
  }
  keyPressed(event: KeyboardEvent, field: any) {
    const urlParts = this.router.url.split('/');
    if (urlParts.includes('environment')) {
      const inputKeys =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890! @#$%^&*()-_=+[]{};:\'",.<>?/|\\';
      if (inputKeys.includes(event.key)) {
        field.Value = field.Value + event.key;
      } else if (event.key === 'Backspace') {
        if (field.Value.length > 0) {
          field.Value = field.Value.slice(0, -1);
        }
      }
    } else {
      field.Value = (event.target as HTMLInputElement).value;
    }
    if (field.Value !== null || field.Value !== '') {
      this.showValidationError[field.Name] = false;
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
