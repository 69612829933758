import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Lab } from './../models/lab.model';
import { LabService } from './../../services/lab.service';
import { EventEmitterService } from '@teams-auth';

@Injectable({ providedIn: 'root' })
export class EnvironmentResolver implements Resolve<any> {
  constructor(private labSrv: LabService, private eventEmitter: EventEmitterService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Lab> {
    return this.labSrv.getLabEnvironment(route.params.id, route.params.voucherCode).pipe(
      catchError(error => {
        const code = 'Error - 70050';
        this.eventEmitter.debugAlert(code, "Error getting Lab Environment");
        return of(null);
      })
    );
  }
}