<button [class]="classList" [id]="id" [title]="titleText" [style]="style" (click)="routerButtonLink !=='' ? routeTo(): onButtonClick()"
    [disabled]="disabled || showLoading && btnLoadingService.indexValue === index" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby">
    <i *ngIf="isIcon" [class]="iconClass" [title]="iconTitle" aria-hidden="true"></i>
    <img *ngIf="isImg" [width]="imgWidth" [class]="imgClass" [src]="imgSrc" [alt]="imgAlt" />
    <span *ngIf="showLoading && btnLoadingService.indexValue === index" class="mr-1 align-middle spinner-border spinner-border-sm"
                                role="status">
                                <span class="sr-only">Loading...</span>
                              </span>
    {{ processingTitle && btnLoadingService.indexValue === index ? processingTitle : title }} {{title2}}
    <ng-content></ng-content>
</button>