import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lession',
  templateUrl: './lession.component.html',
  styleUrls: ['./lession.component.scss']
})
export class LessionComponent implements OnInit {
  @Input() lession;
  constructor() { }

  ngOnInit(): void {
  }

}
