import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    HostListener,
    Output,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as screenfull from 'screenfull';

import { RemoteDesktopManager } from '../services';
import { ConnectingMessageComponent } from './messages/connecting-message.component';
import { DisconnectedMessageComponent } from './messages/disconnected-message.component';
import { ErrorMessageComponent } from './messages/error-message.component';

/**
 * The main component for displaying a remote desktop
 */
@Component({
    selector: 'ngx-remote-desktop',
    template: `
        <div class="ngx-remote-desktop" #container>
            <!-- Toolbar items template -->
        <div
        [attr.aria-hidden]="manager.getState() === 'CONNECTED' ? false : true"
        >
            <ng-template #toolbarItems>
                <ul class="ngx-remote-desktop-toolbar-items">
                    <ng-content select='ngx-remote-desktop-toolbar-item[align=left]'></ng-content>
                </ul>
                <ul class="ngx-remote-desktop-toolbar-items">
                    <ng-content select='ngx-remote-desktop-toolbar-item[align=right]'></ng-content>
                </ul>
            </ng-template>
            <!-- End toolbar items template -->
            <!-- Normal toolbar -->
            <div class="ngx-remote-desktop-toolbar" *ngIf="!manager.isFullScreen()" >
                <template [ngTemplateOutlet]="toolbarItems"></template>
            </div>
            <!-- End normal toolbar -->
            <!-- Full screen toolbar -->
            <div class="ngx-remote-desktop-toolbar ngx-remote-desktop-toolbar-fullscreen" *ngIf="manager.isFullScreen()"
                [@toolbarAnimation]="toolbarVisible" #toolbar>
                <template [ngTemplateOutlet]="toolbarItems"></template>
            </div>
        </div>
            <!-- End full screen toolbar -->
            <section class="ngx-remote-desktop-container">
                <!-- Connecting message -->
                <div *ngIf="(state|async) === states.CONNECTING">
                    <div class="ngx-remote-desktop-message" *ngIf="connectingMessage" >
                        <ng-content select="ngx-remote-desktop-connecting-message"></ng-content>
                    </div>
                    <ngx-remote-desktop-message  *ngIf="!connectingMessage"
                        title="Connecting to remote desktop"
                        message="Attempting to connect to the remote desktop. Waiting for response..."
                        type="success"
                        [isMylearning]="isMylearning">
                    </ngx-remote-desktop-message>
                </div>
                <!-- End connecting message -->

                {{disconnectedMessage}}
                <!-- Disconnected message -->
                <div *ngIf="(state|async) === states.DISCONNECTED || (state|async) === states.CLIENT_ERROR">
                    <div class="ngx-remote-desktop-message" *ngIf="disconnectedMessage">
                        <ng-content select="ngx-remote-desktop-disconnected-message"></ng-content>
                    </div>
                    <ngx-remote-desktop-message *ngIf="!disconnectedMessage"
                        title="{{'ACCESS_SESSION_CLOSED_TITLE' | translate}}"
                        message='<ul class="list-unstyled"><li>{{ "DUPLICATE_SESSION" | translate }}</li><li>{{ "GATEWAY_NOT_RUNNING" | translate }}</li><li>{{ "NETWORK_DISCONNECTION" | translate }}</li></ul>'
                        type="error"
                        [isMylearning]="isMylearning">
                        <teams-button aria-label="reconnect" [title]="'BUTTON_RECONNECT' | translate" (buttonClick)="manager.onReconnect.next(true)" [classList]="'btn ts-btn-fluent-primary btn-primary ngx-remote-desktop-message-body-btn mb-2'">
                        </teams-button>
                    </ngx-remote-desktop-message>
                </div>

                <!-- Error message -->
                <div *ngIf="(state|async) === states.ERROR">
                    <div class="ngx-remote-desktop-message" *ngIf="errorMessage">
                        <ng-content select="ngx-remote-desktop-error-message"></ng-content>
                    </div>

                    <ngx-remote-desktop-message *ngIf="!errorMessage && isInsideEnv"
                        title="{{'GATEWAY_NOT_RUNNING' | translate}}"
                        type="error"
                        [isMylearning]="isMylearning">
                    </ngx-remote-desktop-message>

                    <!-- message when error state is inside performance quiz -->
                    <ngx-remote-desktop-message *ngIf="!errorMessage && !isInsideEnv"
                        title="{{'RDP_STATE' | translate: { state: state|async} }} {{'RDP_REFRESH' | translate}}"
                        type="error"
                        [isMylearning]="isMylearning">
                        <teams-button aria-label="reconnect" [title]="'BUTTON_RECONNECT' | translate" (buttonClick)="manager.onReconnect.next(true)" [classList]="'btn ts-btn-fluent-primary btn-primary ngx-remote-desktop-message-body-btn mb-2'">
                        </teams-button>
                    </ngx-remote-desktop-message>

                </div>
                <!-- End error message -->

                <!-- Display -->
                <ngx-remote-desktop-display *ngIf="(state|async) === states.CONNECTED"
                    [manager]="manager"
                    (onMouseMove)="handleDisplayMouseMove($event)">
                </ngx-remote-desktop-display>
                <!-- End display -->
            </section>
            <section [class.ngx-remote-desktop-status-bar-hidden]="manager.isFullScreen()">
                <ng-content select="ngx-remote-desktop-status-bar"></ng-content>
            </section>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class RemoteDesktopComponent implements OnInit, OnDestroy {
    /**
     * Client that manages the connection to the remote desktop
     */
    @Input()
    public manager: RemoteDesktopManager;

    /*
    *Check it's CL or not
    */
    @Input()
    public isMylearning;
    /*

    *Check it's inside Performance quiz or lab environment
    */
    @Input()
    public isInsideEnv;
    /**
     * Guacamole has more states than the list below however for the component we are only interested
     * in managing four states.
     */
    public states = {
        CONNECTING: 'CONNECTING',
        CONNECTED: 'CONNECTED',
        DISCONNECTED: 'DISCONNECTED',
        ERROR: 'ERROR',
        CLIENT_ERROR: 'CLIENT_ERROR'
    };

    /**
     * Manage the component state
     */
    public state: BehaviorSubject<string> = new BehaviorSubject<string>(this.states.CONNECTING);

    @ContentChild(ConnectingMessageComponent)
    public connectingMessage: ConnectingMessageComponent;

    @ContentChild(DisconnectedMessageComponent)
    public disconnectedMessage: DisconnectedMessageComponent;

    @ContentChild(ErrorMessageComponent)
    public errorMessage: ErrorMessageComponent;

    @ViewChild('container')
    public container: ElementRef;

    @ViewChild('toolbar')
    public toolbar: ElementRef;

    /**
     * Subscriptions
     */
    public subscriptions: Subscription[] = [];

    /**
     * Hide or show the toolbar
     */
    public toolbarVisible = true;

    /**
     * Subscribe to the connection state  and full screen state when the component is initialised
     */
    ngOnInit(): void {
        this.bindSubscriptions();
    }

    /**
     * Remove all subscriptions when the component is destroyed
     */
    ngOnDestroy(): void {
        this.unbindSubscriptions();
    }

    /**
     * Bind the subscriptions
     */
    public bindSubscriptions(): void {
        this.subscriptions.push(this.manager.onStateChange.subscribe(this.handleState.bind(this)));
        this.subscriptions.push(this.manager.onFullScreen.subscribe(this.handleFullScreen.bind(this)));
    }

    /**
     * Unbind the subscriptions
     */
    public unbindSubscriptions(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Set the component state to the new guacamole state
     * @param newState
     */
    public setState(newState: string): void {
        this.state.next(newState);
    }

    /**
     * Receive the state from the desktop client and update this components state
     * @param newState - state received from the guacamole client
     */
    public handleState(newState: string) {
        switch (newState) {
            case RemoteDesktopManager.STATE.CONNECTED:
                this.setState(this.states.CONNECTED);
                break;
            case RemoteDesktopManager.STATE.DISCONNECTED:
                this.exitFullScreen();
                this.setState(this.states.DISCONNECTED);
                break;
            case RemoteDesktopManager.STATE.CONNECTING:
            case RemoteDesktopManager.STATE.WAITING:
                this.setState(this.states.CONNECTING);
                break;
            case RemoteDesktopManager.STATE.CLIENT_ERROR:
                this.setState(this.states.CLIENT_ERROR);
                break;
            case RemoteDesktopManager.STATE.TUNNEL_ERROR:
                this.exitFullScreen();
                this.setState(this.states.ERROR);
                break;
        }

    }

    /**
     * Exit full screen and show the toolbar
     */
    public exitFullScreen(): void {
        if (!screenfull.isFullscreen) {
            return;
        }
        const containerElement = this.container.nativeElement;
        screenfull.exit();
    }

    /**
     * Enter full screen mode and auto hide the toolbar
     */
    public enterFullScreen(): void {
        if (screenfull.isFullscreen) {
            return;
        }
        const containerElement = this.container.nativeElement;
        screenfull.request(containerElement);
        screenfull.on('change', (change: any) => {
            if (!screenfull.isFullscreen) {
                this.manager.setFullScreen(false);
            }
            this.handleToolbar();
        });
    }

    /**
     * Go in and out of full screen
     */
    public handleFullScreen(newFullScreen: boolean): void {
        if (newFullScreen) {
            this.enterFullScreen();
        } else {
            this.exitFullScreen();
        }
    }

    public handleToolbar(): void {
        this.toolbarVisible = (this.manager.isFullScreen()) ? false : true;
    }

    /**
     * Handle the display mouse movement
     * @param event Mouse event
     */
    public handleDisplayMouseMove($event: any): void {
        if (!this.manager.isFullScreen()) {
            return;
        }
        const toolbarWidth = this.toolbar.nativeElement.clientWidth;
        if ($event.x >= toolbarWidth) {
            this.toolbarVisible = false;
        }
    }

    @HostListener('document:mousemove', ['$event'])
    public onDocumentMousemove($event: MouseEvent) {
        if (!this.manager.isFullScreen()) {
            return;
        }
        const toolbarWidth = this.toolbar.nativeElement.clientWidth;
        const x = $event.x;
        if (x >= -1 && x <= 0) {
            this.toolbarVisible = true;
        }
        if (x >= toolbarWidth) {
            this.toolbarVisible = false;
        }
    }
}
