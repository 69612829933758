<!-- <div class="vm-lab-guide"> -->
  <div id="main-landmark" tabindex="-1" class="tab-wrp vm-lab-guide tab-wrpguide pb-5 mb-3" [ngClass]="{'ts-tabs-panel mt-0': appType === 'attendee', 'tab-wrpguide-cl': appType === 'attendee' && notes?.length > 0}" *ngIf="!loading && appType !== 'trial'">
    <ul class="nav nav-tabs" role="tablist" [ngClass]="{'ts-tabs-list': appType === 'attendee'}">
        <li id="link_guide" class="nav-item li-active lab-guide" role="presentation" *ngIf="guideData?.IsGitDocAvailable === true && !labDetail.EnableAccessOverHttp || type === 'full'">
            <a class="nav-link ts-tab-item tab-link" role="tab" aria-label="LAB GUIDE DETAILS" style="text-decoration: none;" [class.active-tab]="tabView === 'guide'" (click)="viewTab('guide')" href="javascript:void(0)"><b>{{'HEADING_LAB_GUIDE' | translate}}</b></a>
        </li>
        <li id="link_description" class="nav-item li-active" role="presentation" *ngIf="labDetail.LabLaunchPageDescription || (labDetail.LabLaunchPageDescription && guideData && !guideData?.IsGitDocAvailable)" [ngClass]="{'fix-tab': labDetail.LabLaunchPageDescription || (labDetail.LabLaunchPageDescription && guideData && !guideData?.IsGitDocAvailable)}">
            <a class="nav-link ts-tab-item tab-link" role="tab" aria-label="LAB DESCRIPTION" style="text-decoration: none;" [class.active-tab]="tabView === 'description'" (click)="viewTab('description')" href="javascript:void(0)"><b>{{'LAB_DESCRIPTION_TAB' | translate}}</b></a>
        </li>
        <li id="link_environment" class="nav-item li-active fix-tab" role="presentation">
            <a class="nav-link ts-tab-item" role="tab" aria-label="ENVIRONMENT DETAILS" style="text-decoration: none;" [class.active-tab]="tabView === 'environment'" (click)="viewTab('environment')" href="javascript:void(0)" ><b>{{ 'TAB_ENVIRONMENT_DETAILS' | translate}}</b></a>
        </li>
        <li id="link_validation" class="nav-item li-active" role="presentation" *ngIf="labDetail.EnableLabValidation === true && !labUserData?.IsSupportQuest" [ngClass]="{'fix-tab': labDetail.EnableLabValidation === true}">
            <a class="nav-link ts-tab-item" role="tab" aria-label="LAB VALIDATION" style="text-decoration: none;" [class.active-tab]="tabView === 'validation'" (click)="viewTab('validation')"  href="javascript:void(0)"  ><b>{{'TAB_LAB_VALIDATION' | translate}}</b></a>
        </li>
        <li id="link_resources" class="nav-item li-active" role="presentation" *ngIf="(!guideData?.IsSharedTestDrive && guideData.CloudPlatformId !== 3) || (guideData?.IsSharedTestDrive && environment.customer === customerType.DATABRICKS)" [ngClass]="{'fix-tab': !guideData?.IsSharedTestDrive  && guideData.CloudPlatformId !== 3}">
            <a class="nav-link ts-tab-item" role="tab" aria-label="RESOURCES" style="text-decoration: none;" [class.active-tab]="tabView === 'resources'" (click)="viewTab('resources')"  href="javascript:void(0)"  ><b>{{'TAB_RESOURCES' | translate}}</b></a>
        </li>
        <li class="nav-item dropdown" role="presentation" [ngClass]="{'attendee-droppdown': appType === 'attendee'}">
            <a href="#" id="myTabDrop1" class="dropdown-toggle" aria-label="More items" style="text-decoration: none;" data-toggle="dropdown" aria-controls="myTabDrop1-contents" aria-expanded="true">
                <i id="showdropdown-menu" title="Show Dropdown" style="margin:4px;" class="fa fa-ellipsis-h"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="myTabDrop1" id="myTabDrop1-contents">
                <li id="link_guide-dup" class="nav-item li-active" role="presentation"
                    *ngIf="guideData?.IsGitDocAvailable === true && !labDetail.EnableAccessOverHttp || type === 'full'">
                    <a class="nav-link ts-tab-item tab-link" style="text-decoration: none;"
                        aria-label="LAB GUIDE DETAILS" [class.active-tab]="tabView === 'guide'"
                        (click)="viewTab('guide')" href="javascript:void(0)"><b>{{'HEADING_LAB_GUIDE' |
                            translate}}</b></a>
                </li>
                <li id="link_description-dup" class="nav-item li-active" role="presentation"
                    *ngIf="labDetail.LabLaunchPageDescription && !guideData?.IsGitDocAvailable">
                    <a class="nav-link ts-tab-item tab-link" style="text-decoration: none;"
                        aria-label="LAB GUIDE DETAILS" [class.active-tab]="tabView === 'description'"
                        (click)="viewTab('description')" href="javascript:void(0)"><b>{{'LAB_DESCRIPTION_TAB' |
                            translate}}</b></a>
                </li>
                <li id="link_environment-dup" class="nav-item li-active">
                    <a class="nav-link ts-tab-item" style="text-decoration: none;" aria-label="ENVIRONMENT DETAILS"
                        [class.active-tab]="tabView === 'environment'" (click)="viewTab('environment')"
                        href="javascript:void(0)"><b>{{ 'TAB_ENVIRONMENT_DETAILS' | translate}}</b></a>
                </li>
                <li id="link_resources-dup" class="nav-item li-active"
                    *ngIf="(!guideData?.IsSharedTestDrive  && guideData.CloudPlatformId !== 2 && guideData.CloudPlatformId !== 3) || (guideData.CloudPlatformId === 2 || guideData.CloudPlatformId === 1)">
                    <a class="nav-link ts-tab-item" style="text-decoration: none;" aria-label="MACHINE DETAILS"
                        [class.active-tab]="tabView === 'resources'" (click)="viewTab('resources')"
                        href="javascript:void(0)"><b>{{'TAB_RESOURCES' | translate}}</b></a>
                <li id="link_resources-dup" class="nav-item li-active" *ngIf="!guideData?.IsSharedTestDrive && guideData.CloudPlatformId !== 3">
                    <a class="nav-link ts-tab-item" style="text-decoration: none;" aria-label="RESOURCES" [class.active-tab]="tabView === 'resources'" (click)="viewTab('resources')"  href="javascript:void(0)"  ><b>{{'TAB_RESOURCES' | translate}}</b></a>
                </li>
                <li id="link_validation-dup" class="nav-item li-active"
                    *ngIf="labDetail.EnableLabValidation === true && !labUserData?.IsSupportQuest">
                    <a class="nav-link ts-tab-item" style="text-decoration: none;" aria-label="LAB VALIDATION"
                        [class.active-tab]="tabView === 'validation'" (click)="viewTab('validation')"
                        href="javascript:void(0)"><b>{{'TAB_LAB_VALIDATION' | translate}}</b></a>
                </li>
            </ul>
        </li>
    </ul>
    <div class="content-wrp mt-3">
        <div class="tab-content" id="myTabContent">
            <div class="guide-tabs" [class.showtab]="tabView === 'guide'" tabindex="0" id="lab-guideview">
                <div id="lab-guide-scroll-div"></div>
                <ng-container *ngIf="labGuideData?.length > 0">
                    <div id="guide-page-"
                        *ngFor="let item of labGuideData | paginate: { itemsPerPage: 1, currentPage: p }">
                        <cloudlabs-markdown class="variable-binding" [readMeData]="item.res"></cloudlabs-markdown>
                    </div>
                </ng-container>
            </div>
            <div class="guide-tabs p-3" [class.showtab]="tabView === 'description'" id="lab-description">
                <div id="lab-description-scroll-div"></div>
                <div style="padding-bottom: 100px" [innerHTML]="labDetail.LabLaunchPageDescription | sanitizeHtml"
                    tabindex="0"></div>
            </div>
            <div class="guide-tabs" [class.showtab]="tabView === 'environment'">
                <div id="lab-environment-scroll-div"></div>
                <cloudlabs-lab-environment (startingLab)="startLab()" [minGuide]="minGuide" [mode]="mode"
                    [labDetail]="labDetail" [type]="type" [voucherCode]="voucherCode"
                    (deallocateLab)="completeTheLab($event)"></cloudlabs-lab-environment>
                <div class="container-fluid" *ngIf="isComplete && operationMode === 'Deallocated'">
                    <div class="row mt-4">
                        <div class="col-md-6 mx-auto mt-5">
                            <div class="card">
                                <div class="alert alert-warning text-center mb-0">
                                    <h4 class="block">
                                        <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle"
                                            role="img"></i>
                                        &nbsp; {{ "ALERT_ODL_END" | translate }}
                                    </h4>
                                    <p style="margin-left: 30px">
                                        {{ "ALERT_ODL_END_THANKS1" | translate }}
                                    </p>
                                    <p style="margin-left: 30px">
                                        {{ "ALERT_THANKS" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid" *ngIf="isComplete && operationMode === 'Deployed'">
                    <div class="row mt-4">
                        <div class="col-md-6 mx-auto mt-5">
                            <div class="card">
                                <div class="alert alert-warning text-center mb-0">
                                    <h4 class="block">
                                        <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle"
                                            role="img"></i>
                                        &nbsp; {{ "ALERT_ALREADY_COMPLETED" | translate }}
                                    </h4>
                                    <p style="margin-left: 30px">
                                        {{ "ALERT_THANKS" | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="guide-tabs" [class.showtab]="tabView === 'validation'" *ngIf="!labUserData?.IsSupportQuest">
                <div id="lab-validation-scroll-div"></div>
                <cloudlabs-lab-validation [labDetail]="labDetail"
                    [voucherCode]="voucherCode"></cloudlabs-lab-validation>
            </div>
            <!-- <div class="guide-tabs" [class.showtab]="tabView === 'vmmachine'">
              <div id="lab-vmmachine-scroll-div"></div>
              <cloudlabs-virtual-machine [labDetail]="labDetail" [attendeLabDetails]="guideData" [voucherCode]="voucherCode" [splitKey]="splitKey"></cloudlabs-virtual-machine>
            </div> -->
            <div class="guide-tabs" *ngIf="guideData.CloudPlatformId === 1 || guideData.CloudPlatformId === 2" [class.showtab]="tabView === 'resources'">
                <div id="resources-scroll-div"></div>
                <cloudlabs-resource *ngIf="tabView === 'resources'" [labDetail]="labDetail" [mode]="mode"
                    [attendeLabDetails]="guideData" [voucherCode]="voucherCode"
                    [splitKey]="splitKey"></cloudlabs-resource>
            </div>
        </div>
        <teams-button [ariaLabel]="'scroll Top'" [classList]="'scrollTop'" (buttonClick)="scrollToTop()"><i class='fa fa-angle-up'></i></teams-button>
    </div>
    <cloudlabs-pagination-controls [ngClass]="{ 'd-none': tabView !== 'guide'}" [responsive]="true"
        [previousLabel]="'PREVIOUS' | translate" [nextLabel]="'NEXT' | translate" (pageChange)="p = $event; getcalue()">
    </cloudlabs-pagination-controls>
</div>
<!-- </div> -->


<cloudlabs-trial-environment *ngIf="!loading && appType === 'trial'" [minGuide]="minGuide"
    [labExpiryDuration]="labExpiryDuration" [mode]="mode" [labDetail]="labDetail" [type]="type" [guideData]="guideData"
    [voucherCode]="voucherCode"></cloudlabs-trial-environment>