import {
  Component,
  Input,
  OnInit,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'teams-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  /**
   *   Holds the current value of the input
   */
  @Input() value: string | boolean = '';
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() inputClass = 'ts-checkbox-fluent';
  @Input() labelClass = '';
  @Input() label = '';
  @Input() title = '';
  @Input() ngClassLabel = '';
  @Input() ngClassInput = '';
  @Input() isInnerHtml = false;
  randomValue: string;
  @Input() ariaLabel: string;
  @Input() tabindex = 0;
  public isPracticeTest = false;

  constructor(
    private route: Router
  ) {}

  ngOnInit(): void {
    //Added grant request url with PT URLs to load PT specific checkbox
    if (this.route.url.includes('practice-tests') || this.route.url.includes('practice-test') || this.route.url.includes('tests') || this.route.url.includes('grant-consent')) {
      this.isPracticeTest = true;
    } else {
      this.isPracticeTest = false;
    }
    this.randomValue = this.randomString();
  }

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges(checked: boolean) {
    this.checked = checked;
    this.onChange(this.checked);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: string | boolean): void {
    this.value = value;
    if(typeof value === 'boolean'){
      this.checked = value;
    }
    this.updateChanges(this.checked);
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
   * Generating random string for unique Id's
   */
  randomString() {
    const length = 6;
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
