import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from './.././../../services/event.services';

@Component({
  selector: 'cloudlabs-environment-deallocate-dialog',
  templateUrl: './environment-deallocate-dialog.component.html',
  styleUrls: ['./environment-deallocate-dialog.component.scss']
})
export class EnvironmentDeallocateDialogComponent implements OnInit {

  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
  ) { }

  ngOnInit(): void {
  }

  async onConfirm() {
    this.modalRef.hide();
    this.eventSrv.deallocateODL();
  }

  onCancel() {
    this.modalRef.hide();
  }

}
