import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloudlabs-util-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() companyName: string;
  @Input() contactLink: string;
  @Input() termsLink: string;
  @Input() privacyLink: string;
  @Input() cloudLink: string;

  @Input() isMicrosoftFooter = false;

  currentYear: string;

  ngOnInit(): void {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear().toString();
  }

}
