import { Component, Input, OnInit } from '@angular/core';
import { EventService } from '../../../../app/services/event.services';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-toggle-header',
  templateUrl: './toggle-header.component.html',
  styleUrls: ['./toggle-header.component.scss']
})
export class ToggleHeaderComponent implements OnInit {
  @Input() labTitle: any;

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateSrv:TranslateService,
    ) { }

  ngOnInit(): void {
  }

  hideShow(){
    this.eventService.emmitToggleHeader();
  }
  
  copyToClipBoard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.success(this.translateSrv.instant('MESSAGE_COPIED'), null);
  }
}
