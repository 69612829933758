<div class="navbar navbar-expand-lg py-0 headerBg" role="banner">

    <!-- As discussed with Alwin, menu is not being used so commenting menu code as of now -->
    <!-- <teams-button *ngIf="menu && IsEdge" [classList]="'hamburgerButton'"
        [style]="'background: none; border: none; width: 50px'" data-title="Hamburger Menu"
        [titleText]="'Hamburger Menu'" [ariaLabelledby]="'sideBarDescription'" (buttonClick)="openSideNav()">
        <i class="fa fa-bars menus" aria-hidden="true"></i>
    </teams-button>
    <teams-button *ngIf="menu && !IsEdge" [classList]="'hamburgerButton'"
        [style]="'background: none; border: none; width: 50px'" data-title="Hamburger Menu"
        [titleText]="'Hamburger Menu'" [ariaLabelledby]="'sideBarDescription'" (buttonClick)="openSideNav()">
        <i class="fa fa-bars menus" aria-hidden="true"></i>
    </teams-button> -->

    <div class="logo-m mr-2" *ngIf="environment.appType === appType.LAB && !lab?.EnableCustomLabDetailsPage">
        <a *ngIf="customLogo && customLogo.includes('CloudLabs')" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="CloudLabs Logo" tabindex="-1">
            <img alt="CloudLabs Logo" class="mr-1" [src]="customLogo"
                style="height: 24px; width: auto; background: transparent" />
        </a>
        <a *ngIf="customLogo && !customLogo.includes('CloudLabs')" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="Microsoft Logo" tabindex="-1">
            <img alt="Microsoft Logo" class="mr-1" [src]="customLogo"
                style="height: 20px; width: auto; background: transparent" />
        </a>
        <a *ngIf="!customLogo" class="navbar-brand" href="javascript:void(0)" style="margin-left: 4px"
            aria-label="CloudLabs logo" tabindex="-1">
            <img alt="CloudLabs logo" class="mr-1" src="/assets/images/CloudLabs_Fullwhite.png"
                style="height: 24px; width: auto; background: transparent" />
        </a>
    </div>
    <span class="ml-2 separator" *ngIf="environment.appType === appType.LAB && showTitle && !lab?.EnableCustomLabDetailsPage">|</span>
    <div class="title-d" *ngIf="showTitle" [ngClass]="{'pl-4':(environment.appType === appType.LAB && !lab?.EnableCustomLabDetailsPage)}">
        <ng-container *ngIf="environment.appType === appType.ATTENDEE">
            <span role="button" aria-label="go to back" tabindex="0" class="back-opt back-arrow cursor-pointer mr-3"
                (click)="goBack()"><i class="fas fa-arrow-left"></i></span>
        </ng-container>
        {{lab?.DisplayName ? lab?.DisplayName : lab?.Title}}
    </div>
    <div class="right-block ml-auto mb-1">
        <div class="end-date pr-3" *ngIf="endDateDetails && endDateDetails.endDate && timerInSeconds > 0"
            [title]="('MESSAGE_ONDEMAND_LAB_END_DATE' | translate) +' '+ (endDateDetails?.endDate | date: 'dd MMM y, hh:mm a') +' '+ (endDateDetails?.timeZone)" role="button" data-toggle="tooltip" placement="bottom-left"
            trigger="hover">
            <i class="fa fa-hourglass-end" style="margin-right: 0.25em;" aria-hidden="true"></i>
            {{endDateDetails?.endDate | date: 'dd MMM y, hh:mm a'}} {{endDateDetails?.timeZone}}
        </div>
        <div class="action-items pr-3" *ngIf="timerInSeconds > 0">
            <i class="fa fa-clock-o" style="margin-right: 0.25em;" aria-hidden="true"></i>
            <span style="font-size:small">{{countDown | async | formatTime}}</span>
        </div>
        <span class="separator" *ngIf="timerInSeconds > 0">|</span>
        <div class="action-items pr-2 pl-3" *ngIf="updatedActionList.length > 0">
            <ng-container *ngFor="let action of updatedActionList ; let i = index">
                <i [class]="action.icon" [title]="action.name | translate" aria-hidden="true"
                    (click)="actionClick(action.action)"></i> &nbsp;
            </ng-container>
        </div>

        <div class="dropdown lang text-right mt-4 mr-2" *ngIf="environment.appType !== appType.ATTENDEE && !lab?.EnableCustomLabDetailsPage" [ngClass]="{'pl-3':updatedActionList.length <= 0}">
            <!-- Language dropdown-->
            <teams-button [classList]="'dropdown-toggle'" [style]="'font-size: small'"
                attr.aria-label="change Language {{ languageAriaLabel }} selected" data-toggle="dropdown"
                aria-haspopup="listbox">
                <i role="option" class="pr-1 fa fa-globe" aria-hidden="true"></i>{{ showLanguage }}
            </teams-button>
            <div id="langDropdown" tabindex="-1" class="dropdown-menu" role="listbox">
                <a role="option" *ngFor="let language of languages" style="text-decoration: none" class="dropdown-item"
                    attr.aria-label="{{ language.Name }}" href="javascript:void(0)"
                    (click)="switchLanguage(language.Code)">{{ language.Description }}</a>
            </div>
        </div>

    </div>
</div>
<div class="header-pad"></div>