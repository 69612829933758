import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LessionComponent } from './components/lession/lession.component';
import { LabResolver } from './resolvers/lab-resolver';
import { CalculateDurationPipe } from './pipes/calculate-duration.pipe';

import { TranslateModule } from '@ngx-translate/core';


import { ClipboardModule } from 'ngx-clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {NgxPaginationModule} from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { HighlightPipe } from './components/auto-complete/highlight.pipe';
import { CountdownModule } from 'ngx-countdown';
import { PascalCasePipe } from './pipes/pascal-case.pipe';
import { PaginationModule } from './components/pagination/pagination.module';
import { HtmlSanitizerPipe } from './pipes/sanitize-html.pipe';
import { FocusInvalidInputDirective } from './directives/focus-invalid-input.directive';
import { RemoveHttpPipe } from './pipes/remove-http.pipe';
import { DebounceClickDirective } from './directives/debouce-click.directive';
import { TrapFocusDirective } from './directives/trap-focus.directive';
import { RemoveFocusOnPressDirective } from './directives/remove-focus-on-press.directive';
import { AutoFocusModalDirective } from './directives/auto-focus-modal.directive';
import { NotificationService } from '../services/notification.service';
import { UtilityService } from '../services/utility.service';
import { NotesComponent } from './components/notes/notes.component';
import { NoteComponent } from './components/notes/note/note.component';
import { NotesService } from '../services/notes.service';
import { ProgressTimeDirective } from './directives/progress-time.directive';
import { RestartDialogDirective } from './directives/restart-dialog.directive';
import { LabFocusDirective } from './directives/lab-focus.directive';
import { TimeTransformPipe } from './pipes/time-transform.pipe';
import { RemoveFocusOkButtonDirective } from './directives/remove-focus-ok-button.directive';
import { RoleDialogDirective } from './directives/role-dialog.directive';
import { ArrowKeysAccessibleDirective } from './directives/arrow-keys-accessible.directive';
import { VmRoleDirective } from './directives/vm-role.directive';
import { RefocusPreviousElDirective } from './directives/refocus-previous-el.directive';
import { DialogRolePipe } from './pipes/dialog-role.pipe';
import { RetryLabDialogComponent } from '../lab-cluster/lab-cluster-list/retry-lab-dialog/retry-lab-dialog.component';
import { ToggleHeaderComponent } from './components/toggle-header/toggle-header.component';
import { SurveyFormComponent } from './components/survey-form/survey-form.component';
import { SurveyDialogComponent } from './components/survey-form/survey-dialog/survey-dialog.component';
import { SurveyThankyouDialogComponent } from './components/survey-form/survey-thankyou-dialog/survey-thankyou-dialog.component';
import { WhiteSpaceValidatorDirective } from './directives/white-space-validator.directive';


export function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'auto',
    container: 'body',
    triggers: 'hover focus'
  });
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LessionComponent,
    CalculateDurationPipe,  
    AutoCompleteComponent,
    HighlightPipe,
    AutoCompleteComponent,
    PascalCasePipe,
    HtmlSanitizerPipe,
    FocusInvalidInputDirective,
    DebounceClickDirective,
    RemoveHttpPipe,
    TrapFocusDirective,
    RemoveFocusOnPressDirective,
    AutoFocusModalDirective, 
    NotesComponent,
    NoteComponent,
    ProgressTimeDirective,
    RestartDialogDirective,
    LabFocusDirective,
    TimeTransformPipe,
    RemoveFocusOkButtonDirective,
    RoleDialogDirective,
    ArrowKeysAccessibleDirective,
    VmRoleDirective,
    RefocusPreviousElDirective,
    DialogRolePipe,
    ToggleHeaderComponent,
    SurveyFormComponent,
    SurveyDialogComponent,
    SurveyThankyouDialogComponent,
    WhiteSpaceValidatorDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ClipboardModule,
    FormsModule,
    PaginationModule,
    ModalModule.forRoot(),
    CountdownModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    LessionComponent,  
    CalculateDurationPipe,
    AutoCompleteComponent,
    HtmlSanitizerPipe,
    FocusInvalidInputDirective,
    DebounceClickDirective,
    PaginationModule,
    TrapFocusDirective,
    AutoFocusModalDirective,
    NotesComponent,
    ProgressTimeDirective,
    RestartDialogDirective,
    LabFocusDirective,
    TimeTransformPipe,
    RemoveFocusOkButtonDirective,
    RoleDialogDirective,
    ArrowKeysAccessibleDirective,
    VmRoleDirective,
    RefocusPreviousElDirective,
    DialogRolePipe,
    ToggleHeaderComponent,
    SurveyFormComponent,
    WhiteSpaceValidatorDirective,
    TranslateModule
  ],
  entryComponents: [
    RetryLabDialogComponent,
    SurveyDialogComponent,
    SurveyThankyouDialogComponent,
  ],
  providers: [
    LabResolver,
    NotificationService,
    UtilityService,
    NotesService,
    [{ provide: TooltipConfig, useFactory: getTooltipConfig }],
  ],
})
export class SharedModule {}
