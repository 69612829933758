<div class="home-header px-3 w-100" [ngClass]=" showHeaderDesign ? 'position-fixed' : 'position-sticky'" [class.bg-white]="isTrialHeader">
    <div class="row">
        <div [ngClass]="hrefURL ? 'col-7' : 'col-4'">
            <div class="d-flex bd-highlight justify-content-between d-sm-flex" [class.height-40px]="isMicrosoftPartner">
              <ng-container *ngIf="isMicrosoftPartner; else defaultLogoDesign">
                <div class="cloud-logo mr-auto bd-highlight ml-2">
                  <img class="microsoft-logo" src="{{companyLogo}}" alt={{companyName}} />
                </div>
              </ng-container>
              <ng-template #defaultLogoDesign>
                <div class="cloud-logo mr-auto bd-highlight">
                  <a [href]="hrefURL" target="_blank" *ngIf="hrefURL !== ''">
                    <img src="{{companyLogo}}" alt={{companyName}} />
                  </a>
                  <ng-container *ngIf="hrefURL === ''">
                    <img src="{{companyLogo}}" alt={{companyName}} />
                  </ng-container>
                </div>
              </ng-template>
            </div>
        </div>
        <div class="d-flex justify-content-end" [ngClass]="" *ngIf="showHeaderDesign" [ngClass]="{'blue-background' : isBackgroundBlue === true, 'col-5': hrefURL !== '', 'col-8': hrefURL === ''}">
            <div class="bd-highlight px-3" *ngIf="showLang">
                <teams-single-select [selectedValue]="selectedLanguage" [optionArray]="languages"
                    [outerDivClass]="'compressed-selector dropdown-div'" [classList]="'transparent-selector'"
                    (selectedValueEmit)="translateLanguage($event)" [listItemClass]="'light-primary'">
                </teams-single-select>
            </div>
            <div class="d-flex align-items-center p-2 bd-highlight">
                <span *ngIf="supportLink" (click)="navigateToSupport(supportLink)" class="support-link">
                    {{'SUPPORT' | translate}}
                </span>
                <span *ngIf="contactLink" (click)="navigateToSupport(contactLink)" class="support-link" [class.link-text-color]="">
                    {{ (isTrialHeader ? "CONTACT" : "CONTACT_LINK" )| translate}}
                </span>
            </div>
        </div>
    </div>
</div>
